import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';

/**
 * The different clients
 */
import { ClientWastemanagementModule } from './clients/wastemanagement/client-wastemanagement.module';
import { ClientPaepaeModule } from './clients/paepae/client-paepae.module';
import { ClientWaikatotainuiModule } from './clients/waikatotainui/client-waikatotainui.module';
import { ClientTahaaroaModule } from './clients/tahaaroa/client-tahaaroa.module';
import { ClientDefaultModule } from './clients/default/client-default.module';

import { ClientResolverMainLayoutComponent } from './resolvers/main-layout-resolver.component';
import { ClientResolverFilterDrawerLayoutComponent } from './resolvers/filter-drawer-layout-resolver.component';
import { ClientResolverTaiaoDashboardLayoutComponent } from './resolvers/taiao-dashboard-layout-resolver.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    /**
     * Client modules
     */
    ClientDefaultModule,
    ClientPaepaeModule,
    ClientWastemanagementModule,
    ClientWaikatotainuiModule,
    ClientTahaaroaModule,
  ],
  declarations: [
    ClientResolverMainLayoutComponent,
    ClientResolverFilterDrawerLayoutComponent,
    ClientResolverTaiaoDashboardLayoutComponent,
  ],
})
export class ClientModule {}
