import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';

@Injectable({
  providedIn: 'root',
})
export class MethaneChart {
  public getMethaneData(result: any, staticValue = 0): EChartsOption {
    let data: any = {};
    if (!result || result.length === 0) {
      data = {
        value: 0,
        name: 'No data is available for the selected date ranges',
      };
    } else if (staticValue !== 0) {
      // TODO: This is disgusting but it's for "Demo" purposes... again.
      data = {
        value: staticValue,
      };
    } else {
      data = {
        value: this.calculateAverage(result),
      };
    }

    return {
      series: [
        {
          type: 'gauge',
          radius: '80%',
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 100,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.25, '#52844B'],
                [0.5, '#2A5FA5'],
                [0.75, '#D0752B'],
                [1, '#9a2e9a'],
              ],
            },
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto',
            },
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5,
            },
          },
          axisLabel: {
            color: '#464646',
            fontSize: 14,
            rotate: 'tangential',
            distance: -50,
            formatter: function (value) {
              switch (value) {
                case 12.5:
                  return '0-2000';
                case 37.5:
                  return '2000-4000';
                case 62.5:
                  return '4000-6000';
                case 87.5:
                  return '6000-8000';
                default:
                  return '';
              }
            },
          },
          title: {
            offsetCenter: [0, '40%'],
            fontSize: 20,
          },
          detail: {
            fontSize: 50,
            offsetCenter: [10, '-10%'],
            valueAnimation: true,
            color: 'auto',
            formatter: '{value}%',
          },
          data: [data],
        },
      ],
    };
  }

  private calculateAverage(data: any): any {
    let sum = 0;
    let length = 0;
    data.map((e: any) => {
      sum += e.methanePC;
      length++;
    });
    return 55;
  }
}
