import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { GroundWaterCharts } from '../charts/groundWaterCharts';
import { AlertService } from '@app/data/services/alerts.service';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';
import { take } from 'rxjs/operators';

import { forkJoin } from 'rxjs';
import { ClientConstantType } from '@app/client/client.constant';

@Component({
  selector: 'legacy-app-widget-groundwater-wells',
  templateUrl: './widget-groundwater-wells.component.html',
  styleUrls: ['./widget-groundwater-wells.component.scss'],
})
export class WidgetGroundwaterWellsComponent extends LegacyWidgetComponentBase {
  gwData: any = [];
  selectedGWLocation: string = '';
  groundwaterOption: EChartsOption = {};
  chartHeader: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? 'Displaying Bore - BH21/12'
      : this.clientService.getClient() === ClientConstantType.paepae
      ? 'Stony Creek'
      : '';
  chartTitle: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? 'Whenua – Groundwater Bore'
      : 'Whenua - Soil Data';

  constructor(
    private groundWaterChart: GroundWaterCharts,
    private alertService: AlertService,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  selectGWChangeHandler(event: any) {
    //update the ui
    this.selectedGWLocation = event.target.value === 'none' ? '' : event.target.value;
    this.onFilterUpdate();
  }

  onFilterUpdate(): void {
    forkJoin({
      groundwater: this.legacyDataService.getGroundWaterData(this.fromDate, this.toDate).pipe(take(1)),
      alerts: this.alertService
        .search({
          startDate: this.fromDate,
          endDate: this.toDate,
          params: ['Chloride', 'Magnesium', 'Potassium', 'Sodium', 'Sulphate'],
        })
        .pipe(take(1)),
    }).subscribe((result) => {
      // Like this because of existing code
      const groundwater = result.groundwater.groundwater;
      const { alerts } = result;

      if (groundwater.length === 0) {
        this.groundwaterOption = this.groundWaterChart.getGroundWaterData([], this.selectedGWLocation);
      } else {
        this.gwData = groundwater.map((e: any) => e.location).filter((v: any, i: any, a: any) => a.indexOf(v) === i);
        this.groundwaterOption = this.groundWaterChart.getGroundWaterData(
          { groundwater, alerts },
          this.selectedGWLocation,
          this.client === ClientConstantType.wastemanagement
        );
      }
    });
  }
}
