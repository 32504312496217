import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-widget-status-badge',
  templateUrl: './widget-status-badge.component.html',
  styleUrls: ['./widget-status-badge.component.scss'],
})
export class WidgetStatusBadgeComponent implements OnInit {
  @Input() status: string = '';
  statusClass: string = '';

  // Names of css classes
  classMapper: object = {
    None: 'none',
    Pending: 'pending',
    Active: 'active',
    'In Review': 'reviewing',
    Resolved: 'resolved',
    Closed: 'closed',
    Archived: 'archived',
  };

  ngOnInit(): void {
    this.validateStatus();
    this.statusClass = this.classMapper[this.status];
  }

  validateStatus(): void {
    // Default to status of None if undefined/null/etc.
    if (!this.status) {
      this.status = 'None';
    }
  }
}
