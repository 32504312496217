import { Directive, OnInit, ViewChild, OnDestroy, Component } from '@angular/core';
import { DateRangeService } from '@shared/date-range-presets/date-range.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { auditTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  FilterService,
  FilterSetupCalendarType,
  FilterSetupMatauranga,
  FilterSetupMonitoring,
  FilterSetupObservation,
  FilterSetupType,
} from '@app/data/services/filterService';
import { DrawerService, IDrawerDataChange } from '@app/data/services/drawerService';
import { EChartsOption } from 'echarts';
import { Site, SitesService } from '@app/data/services/sitesService';
import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { ClientService } from '@app/client/client.service';
import { ClientConstantType } from '@app/client/client.constant';
import { MapService } from '@app/data/services/map.service';
import { DateRangePresetsComponent } from '@app/@shared/date-range-presets/date-range-presets.component';
import moment from 'moment';
import { MapLayersApi } from '@app/data/services/types/MapLayersApi';
import { MatDateRangePicker } from '@angular/material/datepicker';

interface FilterDrawerLayoutOption<T = string> {
  key: T;
  value: string;
}

export type FilterDrawerLayoutOptions<T = string> = FilterDrawerLayoutOption<T>[];
export type FilterDrawerLayoutFilterTypes =
  | 'type'
  | 'domain'
  | 'timescale'
  | 'textfilter'
  | 'csvDownload'
  | 'surfaceSites'
  | 'mapFeatures'
  | 'baseMap'
  | 'dataLayers'
  | 'matauranga'
  | 'monitoring'
  | 'observations'
  | 'calendarView';

@Directive()
export abstract class FilterDrawerLayoutComponentBase extends UnsubscribeManager implements OnInit, OnDestroy {
  @ViewChild('drawerView') protected drawerView?: MatDrawer;
  @ViewChild('picker') dateRangePicker!: MatDateRangePicker<Date>;
  DateRangePresetsComponent = DateRangePresetsComponent;

  type: FilterSetupType = 'taiao';

  filterForm: FormGroup = {} as FormGroup;
  typeOptions: FilterDrawerLayoutOptions<FilterSetupType> = this.compileOptions<FilterSetupType>('type', [
    { key: 'taiao', value: 'Tiro Whānui - Overview' },
    { key: 'maramataka', value: 'Maramataka Calendar' },
    { key: 'mapview', value: 'Mapi – Map View' },
  ]);
  defaultTypeOptions: FilterDrawerLayoutOptions<FilterSetupType> = this.compileOptions<FilterSetupType>('type', [
    { key: 'taiao', value: 'StreamDASH' },
    { key: 'maramataka', value: 'Tūhono' },
    { key: 'mapview', value: 'StreamMAP' },
  ]);

  defaultMapFeaturesOptions: FilterDrawerLayoutOptions<string> = this.compileOptions<string>('mapFeatures', [
    { key: 'monitor', value: 'Monitoring Stations' },
    { key: 'observation', value: 'Observations' },
  ]);

  mapFeaturesOptions: FilterDrawerLayoutOptions<string> = this.compileOptions<string>('mapFeatures', [
    { key: 'monitor', value: 'Monitoring Stations' },
    { key: 'observation', value: 'Observations' },
  ]);

  mataurangaOptions: FilterDrawerLayoutOptions<FilterSetupMatauranga> = this.compileOptions<FilterSetupMatauranga>(
    'matauranga',
    [
      { key: 'hau', value: 'Hau' },
      { key: 'wai', value: 'Wai' },
      { key: 'moana', value: 'Moana' },
      { key: 'whenua', value: 'Whenua' },
    ]
  );

  monitoringOptions: FilterDrawerLayoutOptions<FilterSetupMonitoring> =
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? this.compileOptions<FilterSetupMonitoring>('monitoring', [
          { key: 'hau', value: 'Hau' },
          { key: 'wai', value: 'Wai' },
          { key: 'moana', value: 'Moana' },
          { key: 'whenua', value: 'Whenua' },
          { key: 'waste', value: 'Waste Management' },
        ])
      : this.compileOptions<FilterSetupMonitoring>('monitoring', [
          { key: 'hau', value: 'Hau' },
          { key: 'wai', value: 'Wai' },
          { key: 'moana', value: 'Moana' },
          { key: 'whenua', value: 'Whenua' },
        ]);

  observationOptions: FilterDrawerLayoutOptions<FilterSetupObservation> = this.compileOptions<FilterSetupObservation>(
    'observations',
    [
      { key: 'text', value: 'Text' },
      { key: 'multimedia', value: 'Multimedia' },
    ]
  );

  calendarTypeOptions: FilterDrawerLayoutOptions<FilterSetupCalendarType> =
    this.compileOptions<FilterSetupCalendarType>('calendarView', [
      { key: 'day', value: 'Day' },
      { key: 'week', value: 'Week' },
      { key: 'month', value: 'Month' },
    ]);

  /**
   * Below options are not compatible with `compileOptions`, so ... yeah
   */
  calendarTypeYearOptions: number[] = new Array(4)
    .fill(moment().year())
    .reduce((accumulator, val, index) => [...accumulator, val - index], [])
    .reverse();
  calendarTypeMonthOptions: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  calendarTypeWeekOptions: number[] = new Array(5).fill('').map((_v, k) => k + 1);
  calendarTypeDayOptions: number[] = new Array(30).fill('').map((_v, k) => k + 1);

  filtersInUse: Record<FilterDrawerLayoutFilterTypes, FilterSetupType[]> = {
    type: ['taiao', 'sitehealth', 'mapview', 'maramataka'],
    domain: ['taiao', 'sitehealth'],
    timescale: ['taiao', 'mapview'],
    textfilter: ['mapview'],
    csvDownload: ['mapview'],
    surfaceSites: ['mapview'],
    mapFeatures: ['mapview'],
    baseMap: ['mapview'],
    dataLayers: ['mapview'],
    matauranga: ['maramataka'],
    monitoring: ['maramataka'],
    observations: ['maramataka'],
    calendarView: ['maramataka'],
  };

  events: any[] = [];
  selectedEventId: string | null = null;
  selectedEventIndex: number = 0;
  selectedEvent: any | null = null;
  drawerGranularity?: string | null = null;

  chart: EChartsOption = {};
  contentClass: any = {};
  drawerIsOpen: boolean = false;
  panelOpenState: boolean = false;
  client: ClientConstantType = this.clientService.getClient();
  site: Site | null = null;
  hasStreamBranding = false;
  filterBrandFile = '';
  myFilterService: FilterService;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private filterService: FilterService,
    private drawerService: DrawerService,
    private clientService: ClientService,
    private siteService: SitesService,
    private mapService: MapService,
    private dateRangeService: DateRangeService
  ) {
    super();
    this.myFilterService = filterService;

    // Check api for additional map feature options
    this.siteService
      .getSelectedSite()
      .pipe(
        switchMap((site: Site | null) => {
          this.site = site;
          this.drawerView?.close();
          if (site) {
            return this.mapService.getMapLayers(site.id);
          }
          return of([]);
        })
      )
      .subscribe({
        next: (layers) => {
          if (layers.length > 0) {
            this.populateMapFeaturesOptionsFromApi(layers);
            this.initFilterForm();
          }
        },
        error: (err) => {
          console.error('Error fetching site or layers', err);
        },
      });

    this.subscribe(this.drawerService, this.setDrawerData.bind(this));

    this.type = this.getTypeFromRoute();

    /**
     * Set the type when changes pages
     */
    this.subscribeOnObservable(this.router.events, () => {
      this.type = this.getTypeFromRoute();

      this.filterForm.controls.type.setValue(this.type);
      this.contentClass = { [this.type]: true };
      this.getFilterBrandFile();

      if (this.drawerView?.opened) {
        this.drawerView?.close();
      }
      this.events = [];
      this.selectedEvent = null;
      this.selectedEventId = null;
      this.selectedEventIndex = 0;
    });

    this.contentClass = { [this.type]: true };

    this.initFilterForm();
  }

  ngOnInit(): void {
    this.getFilterBrandFile();
    this.initFilterForm();
    this.dateRangeService.getRangeObservable().subscribe({
      next: (range) => {
        this.filterForm.controls.fromDate.setValue(range.start);
        this.filterForm.controls.toDate.setValue(range.end);
        this.dateRangePicker.close();
      },
    });
  }

  getFilterBrandFile(): void {
    if (!this.hasStreamBranding) {
      return;
    }
    const route = this.router.url.split('/')[1];
    const routeImgs: { [route: string]: string } = {
      mapview: 'clients/paepae/mapview-logo.svg',
      maramatakacalendar: 'clients/paepae/maramataka-logo.svg',
      taiao: 'clients/paepae/taiao-logo.svg',
      default: 'the-stream-logo.png',
    };
    this.filterBrandFile = routeImgs[route] ?? routeImgs.default;
  }

  private getTypeFromRoute(): FilterSetupType {
    let type: FilterSetupType = 'taiao';

    if (this.router.url.includes('maramatakacalendar')) {
      type = 'maramataka';
    } else if (this.router.url.includes('sitehealth')) {
      type = 'sitehealth';
    } else if (this.router.url.includes('mapview')) {
      type = 'mapview';
    }

    return type;
  }

  private getRouteFromType(): string {
    let route: string = '/taiao';

    if (this.type === 'maramataka') {
      route = '/maramatakacalendar';
    } else if (this.type === 'sitehealth') {
      route = '/sitehealth';
    } else if (this.type === 'mapview') {
      route = '/mapview';
    }

    return route;
  }

  protected abstract getCheckboxFields(): string[];
  protected abstract getMultipleSelectFields(): string[];

  private buildCheckboxGroup(options: FilterDrawerLayoutOptions, selectedOptions: string[] | null = null): FormGroup {
    return this.fb.group(
      options.reduce((acc, x) => ({ ...acc, [x.key]: (selectedOptions || []).includes(x.key) }), {})
    );
  }

  /**
   * Compile (manipulate) options
   *
   * Method is a hook function to manipulate any FilterDrawerLayoutOptions options
   *
   * @access protected
   * @param {FilterDrawerLayoutFilterTypes} type The options/filter type
   * @param {FilterDrawerLayoutOptions<T>} baseOptions The base options to work off
   * @returns {FilterDrawerLayoutOptions<T>} The filtered/manipulated/compiled options
   */
  protected compileOptions<T>(
    _type: FilterDrawerLayoutFilterTypes,
    baseOptions: FilterDrawerLayoutOptions<T>
  ): FilterDrawerLayoutOptions<T> {
    return baseOptions;
  }

  canDisplaySection(section: FilterDrawerLayoutFilterTypes): boolean {
    return this.filtersInUse[section].includes(this.type);
  }

  getCalendarType(): FilterSetupCalendarType {
    return this.filterForm.value.calendarType;
  }

  handleTypeChange(): void {
    this.type = this.filterForm.controls.type.value;

    this.router.navigate([this.getRouteFromType()]);
  }

  setDrawerData(data: IDrawerDataChange): void {
    this.events = data.data;
    this.drawerGranularity = data.granularity;
    // This is a gross, to be redone.
    if (this.drawerGranularity === 'day') this.drawerGranularity = 'dai';

    if (this.events.length === 1) {
      this.setSelectedEvent(this.events[0]);
    } else {
      this.unsetSelectedEvent();
    }

    if (!this.drawerView?.opened) {
      this.drawerView?.open();
    }
  }

  setSelectedEvent(event: any): void {
    this.selectedEventIndex = this.events.findIndex((x) => event.id === x.id);

    if (this.selectedEventIndex < 0) {
      throw new Error('Cannot find selected event');
    }

    this.selectedEvent = event;
    this.selectedEventId = event.id;
  }

  unsetSelectedEvent(): void {
    this.selectedEventId = null;
    this.selectedEvent = null;
  }

  setNextEvent(): void {
    const index = this.events.findIndex((x) => x.id === this.selectedEventId);

    if (index === -1 || index >= this.events.length) {
      return;
    }

    this.setSelectedEvent(this.events[index + 1]);
  }

  setPreviousEvent(): void {
    const index = this.events.findIndex((x) => x.id === this.selectedEventId);

    if (index <= 0) {
      return;
    }

    this.setSelectedEvent(this.events[index - 1]);
  }

  toggleDrawer(): Promise<MatDrawerToggleResult> {
    return (this.drawerView as MatDrawer).toggle();
  }

  gotoDashboard(): void {
    this.drawerView?.close();
    this.router.navigate(['/taiao']);
  }

  getLatLngDisplayString(lat: any, lng: any) {
    /**
     * Previous code. Have a look when there is time
     */
    let tmpLat = lat;
    let tmpLng = lng;

    let retString = '' + Math.floor(Math.abs(lat) % 100) + '°';
    tmpLat = (Math.abs(lat) - Math.floor(Math.abs(lat) % 100)) * 100;

    retString += ('' + Math.floor(Math.abs(tmpLat) % 100)).padStart(2, '0') + "'";
    tmpLat = (((tmpLat / 100) * 100) / 100) * 100;
    tmpLat = (Math.abs(tmpLat) - Math.floor(Math.abs(tmpLat) % 100)) * 100;

    retString += Math.floor(Math.abs(tmpLat) % 100);

    tmpLat = (Math.abs(tmpLat) - Math.floor(Math.abs(tmpLat) % 100)) * 100;
    tmpLat = Math.round((((tmpLat / 100) * 100) / 100) * 10);

    retString += '.' + tmpLat + '"';

    if (lat > 0) {
      retString += 'N ';
    } else if (lat < 0) {
      retString += 'S ';
    } else {
      retString += ' ';
    }

    retString += Math.floor(Math.abs(lng) % 1000) + '°';
    tmpLng = (Math.abs(lng) - Math.floor(Math.abs(lng) % 1000)) * 100;

    retString += ('' + Math.floor(Math.abs(tmpLng) % 1000)).padStart(2, '0') + "'";
    tmpLng = (((tmpLng / 1000) * 1000) / 1000) * 1000;

    tmpLng = (Math.abs(tmpLng) - Math.floor(Math.abs(tmpLng) % 100)) * 100;

    retString += Math.floor(Math.abs(tmpLng) % 100);

    tmpLng = (Math.abs(tmpLng) - Math.floor(Math.abs(tmpLng) % 100)) * 100;

    tmpLng = Math.round((((tmpLng / 100) * 100) / 100) * 10);

    retString += '.' + tmpLng + '"';

    if (lng > 0) {
      retString += 'E ';
    } else if (lng < 0) {
      retString += 'W ';
    } else {
      retString += ' ';
    }

    return retString;
  }

  formatDate(date: string): string {
    return moment(date).format('Do MMMM YYYY, h:mma');
  }

  private populateMapFeaturesOptionsFromApi(layers: MapLayersApi[]): void {
    // Calculate the new ones
    const newMapFeaturesOptions: FilterDrawerLayoutOptions<any> = [];
    const alwaysFirst = /^(rohe|boundar|observations)/i;
    this.defaultMapFeaturesOptions.forEach((option) => newMapFeaturesOptions.push({ ...option }));
    layers.forEach((layer) => newMapFeaturesOptions.push({ key: `map-layer-${layer.id}`, value: layer.layer_name }));

    // Sort and set
    newMapFeaturesOptions.sort((a, b) => (alwaysFirst.test(a.value) ? -1 : a.value > b.value ? 1 : -1));
    this.mapFeaturesOptions = this.compileOptions<string>('mapFeatures', newMapFeaturesOptions);
    const newMapFilters = [...this.filterService.defaultMapFilters];

    // Default Layers
    const defaultLayerNames = /^(rohe|boundary|boundaries)/i;
    layers
      .filter((layer) => defaultLayerNames.test(layer.layer_name))
      .forEach((layer) => newMapFilters.push(`map-layer-${layer.id}`));

    // Update the filters
    this.filterService.setFilters({ mapFeatures: newMapFilters });
  }

  private getCheckboxGroupValues = (field: string) =>
    Object.entries(this.filterForm.value[field]).reduce((accumulator, [key, val]) => {
      if (val) {
        accumulator.push(key);
      }

      return accumulator;
    }, [] as string[]);

  private initFilterForm(): void {
    const currentFilters = this.filterService.getFilters();

    const filterGroup = {
      type: this.type,
      domain: currentFilters.domain,
      fromDate: currentFilters.fromDate,
      toDate: currentFilters.toDate,
      searchTerm: currentFilters.searchTerm,
      sensor: currentFilters.sensor,
      mapFeatures: currentFilters.mapFeatures,
      baseMap: currentFilters.baseMap,
      dataLayer: currentFilters.dataLayer,
      matauranga: currentFilters.matauranga,
      monitoring: currentFilters.monitoring,
      observation: currentFilters.observation,
      calendarType: currentFilters.calendarType,
      calendarTypeYear: currentFilters.calendarTypeYear,
      calendarTypeMonth: currentFilters.calendarTypeMonth,
      calendarTypeWeek: currentFilters.calendarTypeWeek,
      calendarTypeDay: currentFilters.calendarTypeDay,
      ...this.getCheckboxFields().reduce(
        (accumulator, field: string) => ({
          ...accumulator,
          [field]: this.buildCheckboxGroup(
            this[`${field}Options` as keyof FilterDrawerLayoutComponentBase],
            currentFilters[field]
          ),
        }),
        {} as any
      ),
      ...this.getMultipleSelectFields().reduce(
        (accumulator, field: string) => ({ ...accumulator, [field]: [currentFilters[field]] }),
        {} as any
      ),
    };

    this.filterForm = this.fb.group(filterGroup);

    if (this.filterForm.controls.type.value !== this.type) {
      this.filterForm.controls.type.setValue(this.type);
    }

    /**
     * Need auditTime to 'debounce' and get the last event emitted as the material date range emits
     * multiple events
     */
    this.filterForm.valueChanges.pipe(auditTime(0)).subscribe({
      next: () => {
        const { type, ...filterValues } = this.filterForm.value;

        this.contentClass = { [type]: true };

        this.filterService.setFilters({
          ...filterValues,
          ...this.getCheckboxFields().reduce(
            (accumulator, field: string) => ({ ...accumulator, [field]: this.getCheckboxGroupValues(field) }),
            {} as any
          ),
        });
      },
    });
  }
}
