<app-widget-card-wrapper [title]="chartTitle" class="wai">
  <h5 class="card-title-light" style="color: #2a5fa5">
    {{ chartHeader }}
  </h5>
  <div>
    <h2><span>All values are Lab Totals. Click each analyte to turn data on and off.</span></h2>
  </div>
  <div class="card-stretch">
    <div echarts [options]="surfaceWaterGrabOption"></div>
  </div>
</app-widget-card-wrapper>
