import { Directive, OnInit } from '@angular/core';
import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { FilterService, FilterSetupSubscribe } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { ClientConstantType } from '@app/client/client.constant';
import moment from 'moment';

@Directive()
export abstract class LegacyWidgetComponentBase extends UnsubscribeManager implements OnInit {
  client: ClientConstantType;
  fromDate: string = '';
  toDate: string = '';

  constructor(
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super();

    this.client = this.clientService.getClient();

    this.subscribe(this.filterService, this.handleFilters.bind(this), ['fromDate', 'toDate']);
  }

  ngOnInit(): void {
    const filters = this.filterService.getFilters();

    this.handleFilters({ filters, previousFilters: filters });

    this.onAfterInit();
  }

  private handleFilters({ filters }: FilterSetupSubscribe): void {
    const getDateFormat = (date: any) => (date ? moment(date).format('YYYY-MM-DD') : '');

    if (this.fromDate === getDateFormat(filters.fromDate) && this.toDate === getDateFormat(filters.toDate)) {
      return;
    }

    this.fromDate = getDateFormat(filters.fromDate);
    this.toDate = getDateFormat(filters.toDate);

    if (filters.toDate && filters.fromDate) {
      this.onFilterUpdate();
    }
  }

  onAfterInit(): void {}
  onFilterUpdate(): void {}
}
