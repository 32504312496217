import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '@app/auth';
import { AdminAuthenticationGuard } from '@app/admin/admin.guard';
import { ClientResolverMainLayoutComponent } from '@app/client/resolvers/main-layout-resolver.component';

const routes: Routes = [
  {
    path: 'admin',
    component: ClientResolverMainLayoutComponent,
    loadChildren: () => import('@app/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AdminAuthenticationGuard],
    data: { title: 'Admin' },
  },
  {
    path: '',
    component: ClientResolverMainLayoutComponent,
    loadChildren: () => import('@app/pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthenticationGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
