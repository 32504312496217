import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { SurfaceWaterChart } from '../charts/surfaceWaterChart';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { AlertService } from '@app/data/services/alerts.service';
import { EChartsOption } from 'echarts';
import { take } from 'rxjs/operators';

import { forkJoin } from 'rxjs';

@Component({
  selector: 'legacy-app-widget-swa',
  templateUrl: './widget-swa.component.html',
  styleUrls: ['./widget-swa.component.scss'],
})
export class WidgetSWAComponent extends LegacyWidgetComponentBase {
  surfaceWaterGrabOption: EChartsOption = {};

  constructor(
    private surfaceWaterChart: SurfaceWaterChart,
    protected filterService: FilterService,
    protected legacyService: LegacyDataService,
    protected clientService: ClientService,
    private alertService: AlertService
  ) {
    super(filterService, legacyService, clientService);
  }

  onFilterUpdate(): void {
    forkJoin({
      surfacewater: this.legacyService.getSurfaceWaterData(this.fromDate, this.toDate).pipe(take(1)),
      alerts: this.alertService
        .search({
          startDate: this.fromDate,
          endDate: this.toDate,
          params: ['Boron', 'Zinc', 'Nitrate Nitrogen', 'Ammoniacal Nitrogen'],
        })
        .pipe(take(1)),
    }).subscribe((result) => {
      const { surfacewater, alerts } = result;
      // Filter the alerts for non-SWD
      const filteredAlerts = alerts.filter((a: any) => !a.name.includes('SWD'));
      const filteredResult = { surfacewater, alerts: filteredAlerts };

      // check if no data returned from selected dates
      if (surfacewater.length === 0) {
        // set echart options for grab data by location
        this.surfaceWaterGrabOption = this.surfaceWaterChart.getSurfaceWaterData([]).A;
      } else {
        // set echart options for grab data by location
        this.surfaceWaterGrabOption = this.surfaceWaterChart.getSurfaceWaterData(filteredResult).A;
      }
    });
  }
}
