import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

export interface ConfirmDialogData {
  title: string;
  message: string;
  okLabel?: string;
  cancelLabel?: string;
  alertMode?: boolean;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  title: string = '';
  message: string = '';
  okLabel: string = 'Ok';
  cancelLabel: string = 'Cancel';
  alertMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {
    this.title = data.title;
    this.message = data.message;
    this.okLabel = data.okLabel || this.okLabel;
    this.cancelLabel = data.cancelLabel || this.cancelLabel;
    this.alertMode = data.alertMode || this.alertMode;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
