import { Component, OnInit } from '@angular/core';
import { DateRangeService } from './date-range.service';

type Range = { key: string; title: string; start: Date; end: Date };

@Component({
  selector: 'app-date-range-presets',
  template: `
    <div class="date-range-presets">
      <button *ngFor="let option of rangeOptions" mat-button (click)="selectRange(option.key)">
        {{ option.title }}
      </button>
    </div>
  `,
  styles: [
    `
      .date-range-presets {
        display: flex;
        flex-wrap: wrap;
        padding: 0.5em;
        gap: 0.5em;
        border-bottom: 1px solid #ddd;
        margin-bottom: 0.8em;
      }
    `,
  ],
})
export class DateRangePresetsComponent implements OnInit {
  constructor(private dateRangeService: DateRangeService) {}
  private today = new Date();
  public rangeOptions: Range[] = [
    {
      title: 'This Month',
      key: 'thisMonth',
      start: new Date(this.today.getFullYear(), this.today.getMonth(), 1),
      end: new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0),
    },
    {
      key: 'lastMonth',
      title: 'Last Month',
      start: new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1),
      end: new Date(this.today.getFullYear(), this.today.getMonth(), 0),
    },
  ];

  ngOnInit() {
    const thisYear = this.today.getFullYear();
    for (let i = thisYear; i > thisYear - 4; i--) {
      this.rangeOptions.push({
        title: i.toString(),
        start: new Date(i, 0, 1),
        end: new Date(i, 11, 31),
        key: i.toString(),
      });
    }
  }

  selectRange(preset: string): void {
    const option: Range | undefined = this.rangeOptions.find((x) => x.key === preset);
    if (option) {
      this.dateRangeService.updateRange(option.start, option.end);
    }
  }
}
