import { Component, Input, OnInit } from '@angular/core';
import { BasicCartesianChartConfig } from '@app/data/interface/widget-config';
import { EChartsOption } from 'echarts';
import { FilterService } from '@app/data/services/filterService';
import { FilterSetup } from '@app/data/services/filterService';

import moment from 'moment';

const eChartsOptionBase: EChartsOption = {
  color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
  legend: {
    right: '25%',
  },
  tooltip: {
    trigger: 'axis',
    position: function (pt: any) {
      return [pt[0], '10%'];
    },
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985',
      },
    },
    textStyle: {
      color: '#fff',
    },
  },
  toolbox: {
    feature: {
      dataView: { show: true, readOnly: false },
      saveAsImage: { show: true },
    },
  },
  grid: {
    top: '25%',
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
  },
  yAxis: [],
  series: [],
};

@Component({
  selector: 'app-drawer-graphs',
  templateUrl: './drawer-graphs.component.html',
})
export class DrawerGraphsComponent implements OnInit {
  // Input
  @Input() environmentalDataSource!: any;
  @Input() datapoints!: any[];
  @Input() date!: string;
  @Input() stacked: boolean = false;
  @Input() graphType: 'line' | 'scatter' = 'line';

  // Output
  graphs: BasicCartesianChartConfig[] = [];
  fromDate?: string;
  toDate?: string;

  // Internals
  filters: FilterSetup;

  constructor(filterService: FilterService) {
    this.filters = filterService.getFilters();
  }

  private chunkArray(array: any[], size: number) {
    const chunked_arr = [];
    let copied = [...array];
    const numOfChild = Math.ceil(copied.length / size);
    for (let i = 0; i < numOfChild; i++) {
      chunked_arr.push(copied.splice(0, size));
    }
    return chunked_arr;
  }

  ngOnInit(): void {
    let granularity = 'Daily';
    // Figure out what we're doing with dates if it's the maramataka.
    if (window.location.href.includes('maramataka')) {
      switch (this.filters.calendarType) {
        case 'day': {
          granularity = 'Hourly';
          this.fromDate = this.date;
          this.toDate = this.date;
          break;
        }
        case 'week': {
          granularity = 'Daily';
          this.fromDate = moment(this.date).startOf('week').add(-1, 'day').format('YYYY-MM-DD');
          this.toDate = moment(this.date).endOf('week').add(1, 'day').format('YYYY-MM-DD');
          break;
        }
        default: {
          granularity = 'Daily';
          this.fromDate = moment(this.date).startOf('month').format('YYYY-MM-DD');
          this.toDate = moment(this.date).endOf('month').format('YYYY-MM-DD');
          break;
        }
      }
    } else {
      // Display a week around that date
      // Dashboards are for long term display!
      granularity = 'Daily';
      this.fromDate = moment(this.date).startOf('week').add(-1, 'day').format('YYYY-MM-DD');
      this.toDate = moment(this.date).endOf('week').add(1, 'day').format('YYYY-MM-DD');
    }

    // This is not particularly painful - not many analytes per source.
    const graphsByUnit = this.datapoints.reduce(
      (accumulator, datapoint) => ({
        ...accumulator,
        [datapoint.analyte.unit]: {
          ...(accumulator[datapoint.analyte.unit] || {}),
          [datapoint.analyte.id]: {
            ...datapoint.analyte,
            value: datapoint.avg,
          },
        },
      }),
      {}
    );

    // OK so we want graph(s), however what we want is not tooo many on each graph.
    this.graphs = [];

    // We have to do it this way because of type safeness
    for (const unit in graphsByUnit) {
      let analyteGroups: any[] = [Object.values(graphsByUnit[unit])];
      if (Object.values(graphsByUnit[unit]).length > 5) {
        analyteGroups = Object.values(graphsByUnit[unit]);
        // OK so we are going to use the value to get an approx order of magnitude.
        // So our graphs look cool.
        analyteGroups.sort((a, b) => b.value - a.value);
        analyteGroups = this.chunkArray(analyteGroups, 4);
      }
      analyteGroups.forEach((analyteGroup) =>
        this.graphs.push({
          echartsConfig: {
            ...eChartsOptionBase,
            series: analyteGroup.map((analyte: any) => ({
              type: this.graphType === 'line' ? 'line' : 'scatter',
              stack: this.stacked ? 'Total' : '',
              smooth: true,
              areaStyle: {
                opacity: 0.8,
              },
              yAxisIndex: 0,
              name: analyte.short_name,
              symbolSize: 13,
              showSymbol: !this.stacked,
              emphasis: {
                focus: 'series',
              },
            })),
            yAxis: [
              {
                type: 'value',
                name: unit,
              },
            ],
          },
          dataConfig: {
            title: `${granularity} Data`,
            widgetId: 1,
            domain: 'other',
            addPadding: true,
            dataSources: analyteGroup.map((analyte: any) => ({
              environmentalDataSourceIdentifier: this.environmentalDataSource,
              analyteIdentifier: analyte.unique_id,
              valueField: 'avg',
              label: analyte.short_name,
            })),
          },
        })
      );
    }
  }
}
