import { Component, OnInit, Input } from '@angular/core';
import { LegacyWidgetComponentBase } from '..//widget.class';

import { EChartsOption } from 'echarts';

@Component({
  selector: 'legacy-app-widget-overview',
  templateUrl: './widget-overview.component.html',
  styleUrls: ['./widget-overview.component.scss'],
})
export class WidgetOverviewComponent extends LegacyWidgetComponentBase implements OnInit {
  @Input() title: string = 'Farm Performance Summary';
  overviewChartOption: EChartsOption = {};

  onFilterUpdate(): void {
    this.legacyDataService.getTaiaoOverview(this.fromDate, this.toDate, this.client).subscribe((data) => {
      const maxVal = Math.max(...data.map((i: any) => parseInt(i.value, 10)));
      const radius = [0, `${80 * (maxVal / 10)}%`];
      this.overviewChartOption = {
        color: data.map((v: any) => v.color),
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            return params.name;
          },
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: 'Radius Mode',
            type: 'pie',
            center: ['50%', '50%'],
            radius: [0, '80%'],
            roseType: 'area',
            itemStyle: {
              borderWidth: 2,
              borderColor: '#006163',
              color: '#fff',
            },
            emphasis: {
              disabled: true,
            },
            z: 0,
            data: [{ value: 10 }],
          },
          {
            name: 'Overview Chart',
            type: 'pie',
            center: ['50%', '50%'],
            radius,
            roseType: 'area',
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              overflow: 'break',
            },
            data,
          },
        ],
      };
    });
  }
}
