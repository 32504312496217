import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartService } from '@app/data/services/chartService';
import { connect, getInstanceByDom } from 'echarts';
import LinearGradient from 'zrender/lib/graphic/LinearGradient';
declare const require: any; // DEMO IGNORE

@Component({
  selector: 'app-bar-charts',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit {
  count: any = [];
  weeks: any = [];
  options: any;
  constructor(private chartService: ChartService) {}
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.setOptions();
      const chartElement1 = document.getElementById('chart1');
      //const chartElement2 = document.getElementById('chart2');
      if (chartElement1 != null) {
        const chart1 = getInstanceByDom(chartElement1);
        //const chart2 = getInstanceByDom(chartElement2);
        if (chart1) {
          connect([chart1]);
        }
      }
    });
  }

  setOptions = () => {};
}
