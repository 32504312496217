import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';

@Injectable({
  providedIn: 'root',
})
export class WatertakeChart {
  public getWatertake(result: any, average: boolean, primaryColor: string, secondaryColor: string): EChartsOption {
    if (result.watertake.length === 0) {
      return {
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false,
              itemStyle: {
                borderWidth: 1,
                borderColor: '#1360ad',
              },
            },
            axisLine: {
              lineStyle: {
                width: 40,
              },
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
              distance: 50,
            },
            data: [
              {
                value: 0,
                name: 'No data available for the selected dates',
                title: {
                  offsetCenter: ['0%', '-30%'],
                },
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%'],
                },
              },
            ],
            title: {
              fontSize: 14,
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 20,
              color: 'auto',
              formatter: '{value}%',
            },
          },
        ],
      };
    } else {
      const { percentageFlow, totalFlow } = this.calculateUsage(result.watertake, average);
      const flowSeries = [
        {
          type: 'gauge',
          name: `${average === true ? 'Average' : 'Latest'} Daily Flow\n${percentageFlow}% of ${totalFlow}m³`,
          startAngle: 90,
          endAngle: -269,
          zlevel: -1,
          min: 0,
          max: 100,
          itemStyle: {
            color: '#FFAB91',
          },
          progress: {
            show: true,
            width: 40,
            overlap: true,
            roundCap: average !== true || !result.alerts.length,
            clip: false,
          },
          pointer: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              width: 40,
              color: [[1, secondaryColor]],
            },
          },
          splitLine: {
            show: false,
          },
          anchor: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
            distance: 100,
          },
          data: [
            {
              value: percentageFlow,
              title: {
                offsetCenter: ['0%', '-30%'],
              },
              itemStyle: {
                color: primaryColor,
                borderWidth: 0,
              },
              detail: {
                valueAnimation: true,
                offsetCenter: ['0%', '0%'],
                width: 50,
                height: 14,
                fontSize: 20,
                color: primaryColor,
                borderWidth: 0,
                formatter: `${
                  average === true ? 'Average' : 'Latest'
                } Daily Flow\n${percentageFlow}% of ${totalFlow}m³`,
              },
            },
          ],
        },
      ];

      const series =
        average === false
          ? flowSeries
          : [
              ...flowSeries,
              ...result.alerts.map((alert: any) => {
                const startAngle =
                  alert.relationalOperator === '>=' || alert.relationalOperator === '>'
                    ? 90 - (((alert.value / 1000) * 86400) / totalFlow / 100) * 360
                    : 90;
                const value =
                  alert.relationalOperator === '<=' || alert.relationalOperator === '<'
                    ? ((alert.value / 1000) * 86400) / totalFlow
                    : 100;

                return {
                  type: 'gauge',
                  name: `${alert.name} ${alert.relationalOperator} ${alert.value} litres/sec`,
                  startAngle,
                  endAngle: -269,
                  min: 0,
                  max: 100,
                  z: alert.type.severity,
                  itemStyle: {
                    color: alert.colour,
                  },
                  progress: {
                    show: true,
                    width: 8,
                    overlap: true,
                    roundCap: average !== true || !result.alerts.length,
                    clip: false,
                  },
                  pointer: {
                    show: false,
                  },
                  axisLine: {
                    show: false,
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: false,
                  },
                  axisLabel: {
                    show: false,
                  },
                  detail: {
                    show: false,
                  },
                  data: [
                    {
                      value,
                    },
                  ],
                };
              }),
            ];

      return {
        tooltip: {
          trigger: 'item',
          formatter: (value: any) => value.seriesName,
        },
        series,
      };
    }
  }

  private calculatePeakDailyFlow(data: any): any {
    if (!data || !data.length) return 0;

    const dailyFlowMaximum = 86.4;
    const maxDailyFlow = Math.max(...data.map((i: any) => i.dailyFlow));
    const maxAvgFlow = Math.max(...data.map((i: any) => i.averageFlow));

    let percentageFlow = dailyFlowMaximum;
    let totalFlow = 0;

    if (maxDailyFlow > 0) {
      if (maxAvgFlow > 0) {
        percentageFlow = maxDailyFlow / 1000 / (maxAvgFlow * dailyFlowMaximum);
        totalFlow = maxDailyFlow / 1000;
      } else {
        percentageFlow = maxDailyFlow / 1000 / (5 * dailyFlowMaximum);
        totalFlow = maxDailyFlow / 1000;
      }
    }

    return {
      percentageFlow: Math.round(percentageFlow * 100),
      totalFlow: Math.round(totalFlow),
    };
  }

  private calculateAverageDailyFlow(data: any): any {
    if (!data || !data.length) return 0;

    const dailyFlowCountWithValue = data.filter((i: any) => i.dailyFlow > 0).length;
    const maxDailyFlow = Math.max(...data.map((i: any) => i.dailyFlow));
    const minDailyFlow = Math.min(...data.map((i: any) => i.dailyFlow));

    return {
      percentageFlow: Math.round((maxDailyFlow - minDailyFlow) / 1000 / ((data.length - dailyFlowCountWithValue) / 96)),
      totalFlow: Math.round((maxDailyFlow - minDailyFlow) / 1000),
    };
  }

  private calculateUsage(data: any, average: boolean): any {
    return average === true ? this.calculateAverageDailyFlow(data) : this.calculatePeakDailyFlow(data);
  }
}
