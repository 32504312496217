import { BasicCartesianChartConfig } from '@app/data/interface/widget-config';
export const IslandBlockRoadTotalNutrients: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'TKN\ng/m³',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'P\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'Total Kjeldahl Nitrogen',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Phosphorus',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Island Block Nutrients',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'total-kjeldahl-nitrogen',
        valueField: 'avg',
        label: 'Total Kjeldahl Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'total-phosphorus',
        valueField: 'avg',
        label: 'Total Phosphorus',
      },
    ],
  },
};
export const IslandBlockRoadWaterChemistry: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: '°C',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'DO\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
      {
        type: 'value',
        name: 'NNN\ng/m³',
        position: 'right',
        offset: 40,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#52844B',
          },
        },
        axisLabel: {
          color: '#52844B',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'Water Temperature',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + '°C',
        },
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Dissolved Oxygen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
      {
        type: 'line',
        yAxisIndex: 2,
        name: 'Nitrate Nitrite Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Island Block Road Water Chemistry',
    widgetId: 2,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'water-temperature',
        valueField: 'avg',
        label: 'Water Temperature',
      },
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'dissolved-oxygen-mg-per-cubic-metre',
        valueField: 'avg',
        label: 'Dissolved Oxygen',
      },
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'nitrate-+-nitrite-nitrogen-grams-per-cubic-metre',
        valueField: 'avg',
        label: 'Nitrate Nitrite Nitrogen',
      },
    ],
  },
};
export const ControlStructureRainfall: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'mm/hr',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'mm',
        position: 'right',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'mm/hr (avg)',
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'mm (total)',
      },
    ],
  },
  dataConfig: {
    title: 'Control Structure Rainfall',
    widgetId: 3,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1293/6',
        analyteIdentifier: 'precipitation',
        valueField: 'avg',
        label: 'Precipitation',
      },
      {
        environmentalDataSourceIdentifier: '1293/6',
        analyteIdentifier: 'precipitation',
        valueField: 'sum',
        label: 'Precipitation',
      },
    ],
  },
};
export const WaterLevels: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'Metres',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Falls Road',
      },
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Ropeway',
      },
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Mercer',
      },
    ],
  },
  dataConfig: {
    title: 'Water Levels',
    widgetId: 3,
    domain: 'wai',

    dataSources: [
      {
        environmentalDataSourceIdentifier: '1293/11',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Falls Road',
      },
      {
        environmentalDataSourceIdentifier: '1293/15',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Ropeway',
      },
      {
        environmentalDataSourceIdentifier: '1131/91',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Mercer',
      },
    ],
  },
};
export const IslandBlockRoadDOM: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'cm',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Absorbance @ 340 nm filtered',
        tooltip: {
          valueFormatter: (value) => value + ' cm',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Absorbance @ 440 nm filtered',
        tooltip: {
          valueFormatter: (value) => value + 'cm',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Island Block - Dissolved Organic Matter',
    widgetId: 5,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'absorbance-@-340-nm-filtered',
        valueField: 'avg',
        label: 'Absorbance @ 340 nm filtered',
      },
      {
        environmentalDataSourceIdentifier: '1293/7',
        analyteIdentifier: 'absorbance-@-440-nm-filtered',
        valueField: 'avg',
        label: 'Absorbance @ 440 nm filtered',
      },
    ],
  },
};
