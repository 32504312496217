import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment';

import { URLConstants } from '@app/core/constants/URLConstants';
import { APIWithLoaderHelper } from '../helpers/apiWithLoaderHelper';
import { LocationParamsService } from './locationParamsService';
import { Site } from '@app/data/services/sitesService';
import { switchMap, take } from 'rxjs/operators';
import { MapLayersApi } from './types/MapLayersApi';
import { EnvironmentalDataSource } from './types/EnvironmentalDataSource';
import { GeoJsonObject } from 'geojson';
import { ObservationApi } from './types/ObservationApi';

export type MapConfig = {
  center: number[];
  zoom: number;
};
@Injectable({
  providedIn: 'root',
})
export class MapService {
  constructor(
    private httpService: HttpClient,
    private apiWithLoaderHelper: APIWithLoaderHelper,
    private locationParamsService: LocationParamsService
  ) {}

  public getMapLayers(siteId: string): Observable<MapLayersApi[]> {
    return this.apiWithLoaderHelper.get<MapLayersApi[]>(URLConstants.getSiteMapLayers(siteId), {}, false);
  }

  public getMapEnvironmentDataSources(siteId: string): Observable<EnvironmentalDataSource[]> {
    return this.apiWithLoaderHelper.get<EnvironmentalDataSource[]>(
      URLConstants.getEnvironmentalDataSources(siteId),
      {},
      false
    );
  }

  public getMapLayerJSON(layerId: string, siteId?: string): Observable<MapLayersApi & { data: GeoJsonObject }> {
    return new Observable<MapLayersApi & { data: GeoJsonObject }>((observer) => {
      this.apiWithLoaderHelper
        .get<MapLayersApi>(URLConstants.getSiteLayerFile(siteId as string, layerId), {}, true)
        .pipe(take(1))
        .subscribe({
          next: (fullLayer: MapLayersApi) => {
            this.httpService
              .get<GeoJsonObject>(fullLayer.dataUrl)
              .pipe(take(1))
              .toPromise()
              .then((data) => {
                observer.next({ data, ...fullLayer });
                observer.complete();
              });
          },
          error: (err: Error) => console.error(err),
        });
    });
  }
}
