import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/data/services/authService';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getLoggedInUser().pipe(
      map(({ user }) => {
        if (!user || !user.isAdmin) {
          this.router.navigate([!!user ? '/' : '/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        }

        return !!user && user.isAdmin;
      })
    );
  }
}
