import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import * as Leaflet from 'leaflet';
import Papa from 'papaparse';
import moment from 'moment';
import { ClientConstantType } from '@app/client/client.constant';
import { ClientService } from '@app/client/client.service';
import { ChartService } from '@app/data/services/chartService';
import { environment } from '@env/environment';
import 'leaflet-textpath';

import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { DrawerService } from '@app/data/services/drawerService';
import { FilterService, FilterSetupSubscribe } from '@app/data/services/filterService';
import { LoaderService } from '@app/data/services/loader.services';
import { MapService } from '@app/data/services/map.service';
import { Site, SitesService } from '@app/data/services/sitesService';
import { pipe, Subscription } from 'rxjs';
import { EnvironmentalDataSource } from '@app/data/services/types/EnvironmentalDataSource';
import { take } from 'rxjs/operators';
import { CommonConstants } from '@app/core/constants/commonConstants';
import { MapLayersApi } from '@app/data/services/types/MapLayersApi';

const iconUrl = './../assets/img/map-icons/map-marker-blue-active@2x.png';
const shadowUrl = './assets/marker-shadow.png';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.scss'],
})
export class MapviewComponent extends UnsubscribeManager implements AfterViewInit, OnDestroy, OnInit {
  client: ClientConstantType;

  static csvData: any;
  mapOptions: any;
  map: any;
  layerGroup: any;
  apiLayers: { [key: string]: Leaflet.GeoJSON } = {}; // Cache for api retrieved layers.
  timer: undefined | ReturnType<typeof setTimeout>;
  apiLayerIds: string[] = [];

  markerArray: Leaflet.Marker[] = [];
  observationMarkersArray: any = [];
  private environmentalDataSourceCache: { data: EnvironmentalDataSource[]; timestamp: number } | null = null;

  site: Site | null = null;
  siteSubscription: Subscription | null = null;

  iconDefault = Leaflet.icon({
    iconUrl,
    shadowUrl,
    iconSize: [24, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  observationIconMarker = Leaflet.icon({
    iconUrl: '../../assets/img/map-icons/observation-icon.png',
    iconSize: [24, 47],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  activeMarker: any;

  constructor(
    private filterService: FilterService,
    private drawerService: DrawerService,
    private chartService: ChartService,
    protected clientService: ClientService,
    private mapService: MapService,
    private sitesService: SitesService,
    private loaderService: LoaderService
  ) {
    super();
    this.client = this.clientService.getClient();
  }

  ngOnInit(): void {
    this.filterService.subscribe(this.handleFilterUpdate.bind(this), [
      'toDate',
      'fromDate',
      'searchTerm',
      'sensor',
      'mapFeatures',
      'baseMap',
      'dataLayer',
    ]);

    this.siteSubscription = this.sitesService.getSelectedSite().subscribe((site: Site | null) => {
      this.site = site;
      this.environmentalDataSourceCache = null;
      this.initMap();
    });

    this.handleFilterUpdate({
      filters: this.filterService.getFilters(),
      previousFilters: this.filterService.getFilters(),
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  private handleFilterUpdate({ filters, previousFilters }: FilterSetupSubscribe): void {
    const affectedMapFeatures: string[] = filters.mapFeatures
      .filter((x) => !previousFilters.mapFeatures.includes(x))
      .concat(previousFilters.mapFeatures.filter((x) => !filters.mapFeatures.includes(x)));

    /**
     * Because we're also listening on to/from date changes, we need to see if we have any used
     * map features that rely on the date range. If our date range has changed then basically
     * reload them (remove and add the back with the new date range)
     */
    if (
      filters.fromDate &&
      filters.toDate &&
      (filters.fromDate !== previousFilters.fromDate || filters.toDate !== previousFilters.toDate)
    ) {
      const affectedMapFeaturesByDate: string[] = ['observation'];

      affectedMapFeaturesByDate.forEach((x) => {
        if (filters.mapFeatures.includes(x) && !affectedMapFeatures.includes(x)) {
          affectedMapFeatures.push(x);
        }
      });
    }

    // Set delay on searching by text, otherwise you get a race condition bug
    // Delay set to 1 second after last key pressed for slow browsers
    if (filters.searchTerm !== previousFilters.searchTerm) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.removeObservationMarkersLayer();
        this.addObservationMarkerslayer();
      }, 1000);
    }

    this.updateAllMonitoringMarkers(affectedMapFeatures);
    this.updateAllMapLayers(affectedMapFeatures);

    if (affectedMapFeatures.includes('observation')) {
      this.removeObservationMarkersLayer();

      if (filters.mapFeatures.includes('observation')) {
        this.addObservationMarkerslayer();
      }
    }
  }

  ngAfterViewInit() {
    this.initMap();

    // Preload api map layers. Currently for rohe/boundary
    const mapFeatureLayerIds = this.filterService
      .getFilters()
      .mapFeatures.filter((feature) => feature.startsWith('map-layer'))
      ?.map((feature) => feature.split('-')[2]);
    if (mapFeatureLayerIds) {
      this.addApiMapLayers(mapFeatureLayerIds);
    }
    // Preload environmental data sources
    if (this.filterService.getFilters().mapFeatures.includes('monitor')) {
      this.updateAllMonitoringMarkers(['monitor']);
    }
  }

  private zoomLevels: { [key: number]: number } = {
    1000: 12,
    5000: 11,
    7000: 10,
    10000: 9,
  };

  private initMap(): void {
    // clear map
    if (this.map) {
      this.removeObservationMarkersLayer();
      this.map.off();
      this.map.remove();
    }
    // Typing makes me do this the old way
    let zoomLevel = 15;
    for (const greaterThan in this.zoomLevels) {
      if (this.site?.siteArea && this.site.siteArea > parseInt(greaterThan)) {
        zoomLevel = this.zoomLevels[greaterThan];
      }
    }
    // set map config
    this.mapOptions = {
      center: [this.site?.latitude, this.site?.longitude],
      zoom: zoomLevel,
    };
    this.map = Leaflet.map('map', this.mapOptions);

    var layer = Leaflet.tileLayer(
      'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
      {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: 'mapbox/satellite-v9',
        tileSize: 512,
        zoomOffset: -1,
        accessToken: environment.mapbox.accessToken,
      }
    ).addTo(this.map);

    this.layerGroup = Leaflet.featureGroup();
    this.layerGroup.setStyle({ color: 'blue', opacity: 0.5 });
    this.layerGroup.addLayer(layer);
    this.layerGroup.addTo(this.map);

    this.addObservationMarkerslayer();
  }

  private loadApiLayer(layerId: string, layerData: any): void {
    const domainColors: any = {
      Hau: '#e4f527e6',
      Moana: '#d9dfff',
      Wai: '#00a0ba',
      Whenua: '#dd7000e6',
    };
    const domainMarkers: any = {
      Hau: 'marker-hau.png',
      Moana: 'marker-blue.png',
      Wai: 'marker-blue.png',
      Whenua: 'marker-green.png',
    };

    this.apiLayers[layerId] = Leaflet.geoJson(layerData.data, {
      onEachFeature: function (feature: any, layer: any) {
        if (feature.properties?.OBJECTID) {
          layer.bindPopup(feature.properties.OBJECTID);
        }
        layer.myTag = `apiLayer-${layerId}-GeoJSON`;
      },
      pointToLayer: function (feature: any, latlng: any) {
        return Leaflet.marker(latlng, {
          icon: Leaflet.icon({ iconUrl: `/assets/icons/${domainMarkers[layerData.domain]}` }),
        });
      },
      style: {
        weight: 2,
        color: domainColors[layerData.domain],
        dashArray: '2, 2',
      },
    });
    this.layerGroup.addLayer(this.apiLayers[layerId]).addTo(this.map);
  }

  addApiMapLayers(layerIds: string[], showLoader = true): void {
    layerIds.forEach((layerId) => {
      // Check if data is already cached.
      this.apiLayerIds.push(layerId);
      if (layerId in this.apiLayers) {
        this.loaderService.show();
        this.layerGroup.addLayer(this.apiLayers[layerId]).addTo(this.map);
        this.loaderService.hide();
      } else {
        // Fetch data from API.
        if (this.site) {
          this.mapService.getMapLayerJSON(layerId, this.site?.id).subscribe(
            (response) => {
              if (!response) return;
              if (showLoader) {
                this.loaderService.show();
              }
              this.loadApiLayer(layerId, response);
              if (showLoader) {
                this.loaderService.hide();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    });
  }

  removeApiMapLayer(layerId: string): void {
    if (this.apiLayers[layerId]) {
      this.layerGroup.removeLayer(this.apiLayers[layerId]);
      this.apiLayerIds = this.apiLayerIds.filter((id) => id !== layerId);
    }
  }

  addObservationMarkerslayer = () => {
    const { fromDate, toDate, searchTerm } = this.filterService.getFilters();

    this.chartService
      .getObservationData(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
      .subscribe(
        (response) => {
          if (!response) return;

          const observations = response.filter(
            (observation: any) =>
              observation.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
              observation.items?.some((item: any) => item.value.toLowerCase().includes(searchTerm.toLowerCase()))
          );

          const observationsByCoordinates = observations.reduce(
            (acc: any, observation: any) => ({
              ...acc,
              [`${observation.lat}${observation.lng}`]: [
                ...(acc[`${observation.lat}${observation.lng}`] || []),
                observation,
              ],
            }),
            {}
          );

          // Add markers to map
          observations.forEach((observation: any) => {
            const observationMarker = Leaflet.marker([observation.lat, observation.lng], {
              icon: this.observationIconMarker,
            });
            observationMarker.bindPopup(`<b>${observation.location}</b>`);
            observationMarker.on('mouseover', () => {
              observationMarker.openPopup();
            });
            observationMarker.on('mouseout', () => {
              observationMarker.closePopup();
            });
            observationMarker.on('click', () => {
              this.showObservations(observationsByCoordinates[`${observation.lat}${observation.lng}`]);
            });

            this.observationMarkersArray.push(observationMarker);
            this.layerGroup.addLayer(observationMarker);
          });

          this.setCSVObservationData(observations);
        },
        (error: Error) => {
          console.log(error);
        }
      );
  };
  removeObservationMarkersLayer = () => {
    this.observationMarkersArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
  };

  private createMonitoringStationMarker = (source: EnvironmentalDataSource): void => {
    const iconFile = CommonConstants.MARKER_ICONS[source.domain as keyof typeof CommonConstants.MARKER_ICONS];
    const leafletIcon = Leaflet.icon({
      ...this.iconDefault,
      iconUrl: `${CommonConstants.ICON_DIR}${iconFile}`,
    });
    let marker: Leaflet.Marker = Leaflet.marker([source.latitude, source.longitude], {
      icon: leafletIcon,
      alt: source.source_identifier,
    });
    marker.bindPopup(`<b>${source.human_readable_name}</b>`);
    marker.on('mouseover', () => {
      marker.openPopup();
    });
    marker.on('mouseout', () => {
      marker.closePopup();
    });
    marker.on('click', () => {
      this.showEnvironmentalDataSourceDataInDrawer(source.source_identifier);
    });
    this.markerArray.push(marker);
    this.layerGroup.addLayer(marker);
  };

  // each station selected individually (sidebar version)
  updateEnvironmentalSourceMarkers = (affectedMapFeatures: string[]): void => {
    const markerMapFeatures = affectedMapFeatures.filter((x) => !x.startsWith('map-layer'));
    if (markerMapFeatures.length === 0) return;
    this.loaderService.show();
    this.markerArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
    this.markerArray = [];
    const features: string[] = this.filterService.getFilters().mapFeatures;
    if (!this.site) return;

    const processResponse = (response: EnvironmentalDataSource[]) => {
      features.forEach((feature) => {
        const source = response.find((x) => x.source_identifier === feature);
        if (!source) return;
        this.createMonitoringStationMarker(source);
      });
      this.loaderService.hide();
    };

    const isEnvSourcesRecent = () => {
      if (!this.environmentalDataSourceCache) return false;
      const now = Date.now();
      const cacheAge = now - this.environmentalDataSourceCache.timestamp;
      return cacheAge < 60000;
    };

    if (isEnvSourcesRecent()) {
      // Cache sources for 1 minute to avoid spamming the API
      // for multiple checkbox changes
      processResponse(this.environmentalDataSourceCache?.data ?? []);
    } else {
      this.mapService
        .getMapEnvironmentDataSources(this.site.id)
        .pipe(take(1))
        .subscribe((response: EnvironmentalDataSource[]) => {
          this.environmentalDataSourceCache = { data: response, timestamp: Date.now() };
          processResponse(response);
        });
    }
  };

  showEnvironmentalDataSourceDataInDrawer(environmentalDataSourceIdentifier: string): void {
    const { fromDate, toDate } = this.filterService.getFilters();
    // Get the environmental data for the source
    this.chartService
      .getEnvironmentalDataForSourceByDate(
        environmentalDataSourceIdentifier,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD')
      )
      .pipe(take(1))
      .subscribe((response: any) => {
        // Now format it for the drawer
        const environmentalDataSourcesById = (response.environmentalDataSources || []).reduce(
          (accumulator: any, source: any) => ({
            ...accumulator,
            [source.id]: source,
          }),
          {}
        );
        const analytesById = (response.analytes || []).reduce(
          (accumulator: any, analyte: any) => ({
            ...accumulator,
            [analyte.id]: analyte,
          }),
          {}
        );
        const precisionMap = {
          raw: 'YYYY-MM-DD HH:mm',
          hour: 'YYYY-MM-DD HH:mm',
          day: 'YYYY-MM-DD',
          week: 'YYYY-MM-DD',
          month: 'YYYY-MM',
        };
        const displayPrecision = {
          raw: 'dd/MM/yyyy hh:mma',
          hour: 'dd/MM/yyyy hh:mma',
          day: 'dd/MM/yyyy',
          week: 'dd/MM/yyyy',
          month: 'dd/MM/yyyy',
        };
        const endDateMap = {
          raw: { hours: 0 },
          hour: { hours: 0 },
          day: { days: 0 },
          week: { days: 6 },
          month: { months: 1, days: -1 },
        };
        const precision = precisionMap[response.granularity as keyof typeof precisionMap];
        const drawerData = Object.values(
          (response.data || [])
            .map((dataPoint: any) => ({
              ...dataPoint,
              displayDate: moment(dataPoint.date_time).format(precision),
              startDate: moment(dataPoint.date_time).format('YYYY-MM-DD'),
              endDate: moment(dataPoint.date_time)
                .add(endDateMap[response.granularity as keyof typeof endDateMap])
                .format('YYYY-MM-DD'), // TODO - cahnge
              orderField: `${moment(dataPoint.date_time).format(precision)}-${
                environmentalDataSourcesById[dataPoint.environmental_data_source_id].human_readable_name
              }`,
            }))
            .reduce(
              (accumulator: any, dataPoint: any) => ({
                ...accumulator,
                [dataPoint.orderField]: {
                  ...dataPoint,
                  ...(accumulator[dataPoint.orderField] || {}),
                  analyte_id: undefined,
                  analyte: undefined,
                  environmentalDataSource: environmentalDataSourcesById[dataPoint.environmental_data_source_id],
                  // Roll up the readings
                  data: [
                    ...(accumulator[dataPoint.orderField]?.data || []),
                    {
                      avg: dataPoint.avg,
                      min: dataPoint.min,
                      max: dataPoint.max,
                      sum: dataPoint.sum,
                      stddev: dataPoint.stddev,
                      stddev_pop: dataPoint.stddev_pop,
                      stddev_samp: dataPoint.stddev_samp,
                      variance: dataPoint.variance,
                      var_pop: dataPoint.var_pop,
                      var_samp: dataPoint.var_samp,
                      percentile_25: dataPoint.percentile_25,
                      percentile_50: dataPoint.percentile_50,
                      percentile_75: dataPoint.percentile_75,
                      percentile_90: dataPoint.percentile_90,
                      percentile_95: dataPoint.percentile_95,
                      percentile_99: dataPoint.percentile_99,
                      mode: dataPoint.mode,
                      count: dataPoint.count,
                      analyte: analytesById[dataPoint.analyte_id],
                    },
                  ],
                },
              }),
              {}
            )
        );
        drawerData.sort((a: any, b: any) => (a.orderField < b.orderField ? -1 : 1));
        this.drawerService.setDrawerData({
          granularity: response.granularity,
          data: drawerData.map((timeEntry: any) => ({
            title: timeEntry.environmentalDataSource.human_readable_name,
            domain: timeEntry.environmentalDataSource.domain,
            date: timeEntry.displayDate,
            date_format: displayPrecision[response.granularity as keyof typeof displayPrecision],
            source: 'environmental_data_source',
            source_data: timeEntry,
          })),
        });
      });
  }
  // all stations (non-sidebar version)
  updateAllMonitoringMarkers(affectedMapFeatures: string[]): void {
    if (!affectedMapFeatures.includes('monitor')) {
      return;
    }
    this.markerArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
    this.markerArray = [];
    if (!this.site) {
      return;
    }
    if (!this.filterService.getFilters().mapFeatures.includes('monitor')) {
      return;
    }
    this.loaderService.show();
    this.mapService
      .getMapEnvironmentDataSources(this.site.id)
      .pipe(take(1))
      .subscribe((response: EnvironmentalDataSource[]) => {
        response.forEach((source) => {
          this.createMonitoringStationMarker(source);
        });
        this.loaderService.hide();
      });
  }

  updateAllMapLayers(affectedMapFeatures: string[]): void {
    if (!affectedMapFeatures.find((feature) => /^map-layer-\d{1,}/.test(feature))) {
      return;
    }
    if (!this.site) {
      return;
    }
    // Get the new layer ids
    const currentMapFilters = this.filterService.getFilters().mapFeatures;
    const newLayerIds = currentMapFilters
      .filter((feature) => /^map-layer-\d{1,}/.test(feature))
      .map((feature) => feature.split('-')[2]);

    // Get rid of the old ones
    const oldLayerIds = this.apiLayerIds.filter((id) => !newLayerIds.includes(id));
    oldLayerIds.forEach((layerId) => this.removeApiMapLayer(layerId));

    // Add the new ones
    const toAddLayerIds = newLayerIds.filter((id) => !this.apiLayerIds.includes(id));
    this.addApiMapLayers(toAddLayerIds, false);
  }

  showObservations(observations: any[]): void {
    this.drawerService.setDrawerData({
      granularity: 'na',
      data: observations.map((observation) => ({
        title: observation.location,
        domain: 'Observation',
        date: observation.date,
        source: 'observation',
        source_data: {
          ...observation,
          latitude: observation.lat,
          longitude: observation.lng,
        },
      })),
    });
  }

  setCSVObservationData(observations: Array<any>): void {
    let cleansedObs: any[] = [];
    if (observations && observations.length) {
      // We have standard headers that should always be shown, and item headers that should match the title of each item
      const standardHeaders = [
        'ID',
        'Name',
        'Observed Date',
        'Revisit Date',
        'Latitude',
        'Longitude',
        'Status',
        'Site',
        'Location',
      ];
      const itemHeaders = [].concat.apply(
        [],
        observations.map((s: any) => s.items.map((i: any) => i.title))
      );
      const headers = [...standardHeaders, ...new Set(itemHeaders)];

      // Cleanse each observation before converting to CSV
      cleansedObs = observations.map((s: any) => {
        const cleansedItem: { [key: string]: any } = {};
        headers.forEach((h) => {
          switch (h as string) {
            case 'ID':
              cleansedItem[h] = s.id;
              break;
            case 'Name':
              cleansedItem[h] = s.location;
              break;
            case 'Observed Date':
              cleansedItem[h] = s.date;
              break;
            case 'Revisit Date':
              cleansedItem[h] = s.revisitDate ?? '';
              break;
            case 'Latitude':
              cleansedItem[h] = s.lat;
              break;
            case 'Longitude':
              cleansedItem[h] = s.lng;
              break;
            case 'Status':
              cleansedItem[h] = s.status?.name;
              break;
            case 'Site':
              cleansedItem[h] = this.site?.name ?? '';
              break;
            case 'Location':
              cleansedItem[h] = s.location;
              break;
            default:
              cleansedItem[h] = s.items.find((i: any) => i.title === h)?.value ?? '';
              break;
          }
        });

        return cleansedItem;
      });
    }

    // Convert to CSV string
    MapviewComponent.csvData = Papa.unparse(cleansedObs);
  }
}
