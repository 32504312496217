import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { API } from 'aws-amplify';

import { LoaderService } from '@app/data/services/loader.services';

type apiMethods = 'get' | 'post' | 'put' | 'patch' | 'del';

@Injectable({
  providedIn: 'root',
})
export class APIWithLoaderHelper {
  constructor(public loaderService: LoaderService) {}

  private doRequest<T = any>(path: string, method: apiMethods, params = {}, showLoader: boolean = true): Observable<T> {
    if (showLoader) this.loaderService.show();

    return from<Promise<T>>(
      API[method]('api', `${path}`, params).then((response: T) => {
        this.loaderService.hide();
        return response;
      })
    );
  }

  get<T>(path: string, params: object = {}, showLoader: boolean = true): Observable<T> {
    return this.doRequest(path, 'get', params, showLoader);
  }

  post<T>(path: string, body: object, params: object = {}, showLoader: boolean = true): Observable<T> {
    return this.doRequest(path, 'post', { ...params, body }, showLoader);
  }

  patch<T>(path: string, body: object, params: object = {}, showLoader: boolean = true): Observable<T> {
    return this.doRequest(path, 'patch', { ...params, body }, showLoader);
  }

  put<T>(path: string, body: object, params: object = {}, showLoader: boolean = true): Observable<T> {
    return this.doRequest(path, 'put', { ...params, body }, showLoader);
  }

  del<T>(path: string, params: object = {}, showLoader: boolean = true): Observable<T> {
    return this.doRequest(path, 'del', params, showLoader);
  }
}
