<app-widget-card-wrapper title="Wai – Monitoring Station" class="wai">
  <div class="card-stretch">
    <h5 class="card-title-light" style="color: #2a5fa5">pH</h5>
    <div echarts [options]="surfaceWaterSensorOption"></div>
    <h2 *ngIf="showFilter"><span>Filter by location:</span></h2>
    <select *ngIf="showFilter" (change)="selectSWSensorChangeHandler($event)">
      <option value="MS3">MS3</option>
      <option value="MS4">MS4</option>
    </select>
  </div>
</app-widget-card-wrapper>
