import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { SurfaceWaterChart } from '../charts/surfaceWaterChart';
import { AlertService } from '@app/data/services/alerts.service';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';

import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientConstantType } from '@app/client/client.constant';

@Component({
  selector: 'legacy-app-widget-water-monitoring-conductivity',
  templateUrl: './widget-water-monitoring-conductivity.component.html',
  styleUrls: ['./widget-water-monitoring.component.scss'],
})
export class WidgetWaterConductivityMonitoringComponent extends LegacyWidgetComponentBase {
  surfaceWaterSensorOption: EChartsOption = {};
  selectedSWSensorLocation: string = 'MS3';
  showFilter: boolean = this.clientService.getClient() === ClientConstantType.wastemanagement;

  constructor(
    private surfaceWaterChart: SurfaceWaterChart,
    private alertService: AlertService,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  selectSWSensorChangeHandler(event: any) {
    this.selectedSWSensorLocation = event.target.value === 'none' ? '' : event.target.value;
    this.onFilterUpdate();
  }

  onFilterUpdate(): void {
    forkJoin({
      monitoringData: this.legacyDataService
        .getWaiStationMonitoringData(this.fromDate, this.toDate, this.selectedSWSensorLocation)
        .pipe(take(1)),
      alerts: this.alertService
        .search({
          startDate: this.fromDate,
          endDate: this.toDate,
          params: ['Conductivity'],
        })
        .pipe(take(1)),
    }).subscribe((result) => {
      // check if no data returned from selected dates
      if (result.monitoringData.length === 0) {
        // set echart options for sensor data
        this.surfaceWaterSensorOption = this.surfaceWaterChart.getSurfaceWaterConductivitySensorData(
          [],
          this.selectedSWSensorLocation
        );
      } else {
        // set echart options for sensor data
        this.surfaceWaterSensorOption = this.surfaceWaterChart.getSurfaceWaterConductivitySensorData(
          result,
          this.selectedSWSensorLocation
        );
      }
    });
  }
}
