import { Component, Input, OnInit } from '@angular/core';
import { ChartService } from '@app/data/services/chartService';
import { BasicScatterChartConfig } from '@app/data/interface/widget-config';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { EChartsOption, SeriesOption } from 'echarts';
import { FilterService } from '@app/data/services/filterService';
import { SitesService } from '@app/data/services/sitesService';

/**
 * I've really tried to use types in this file but the inheritence is making it difficult.
 */

@Component({
  selector: 'app-basic-scatter-chart',
  templateUrl: './basic-scatter-chart.component.html',
  styleUrls: ['./basic-scatter-chart.component.scss'],
})
export class BasicScatterChartComponent implements OnInit {
  @Input() config!: BasicScatterChartConfig;
  @Input() startDateOverride?: string;
  @Input() endDateOverride?: string;
  chartOptions: EChartsOption | null = null;
  id: string = 'basic-Scatter-chart-x';
  chartTitle: string = '';
  chartSubtitle: string = '';
  domain: string = '';
  startDate: string = '';
  endDate: string = '';

  constructor(
    private chartService: ChartService,
    private filterService: FilterService,
    private sitesService: SitesService
  ) {}
  ngOnInit() {
    this.id = `basic-scatter-chart-${this.config.dataConfig.widgetId}`;
    this.chartTitle = this.config.dataConfig.title || '';
    this.chartSubtitle = this.config.dataConfig.subtitle || '';
    this.domain = this.config.dataConfig.domain || '';

    // Filtering
    const filters = this.filterService.getFilters();
    this.startDate = this.startDateOverride ?? moment(filters.fromDate).format('YYYY-MM-DD');
    this.endDate = this.endDateOverride ?? moment(filters.toDate).format('YYYY-MM-DD');
    this.filterService.subscribe(this.handleFilterUpdate);
    this.sitesService.subscribe(this.handleSiteUpdate);

    // First load.
    this.setData();
  }

  handleSiteUpdate = ({ site }: any) => {
    if (site) {
      this.setData();
    }
  };

  handleFilterUpdate = ({ filters }: any) => {
    if (!filters.toDate || !filters.fromDate) return;
    if (this.startDateOverride) return;
    const newStartDate = moment(filters.fromDate).format('YYYY-MM-DD');
    const newEndDate = moment(filters.toDate).format('YYYY-MM-DD');
    if (newStartDate !== this.startDate || newEndDate !== this.endDate) {
      this.startDate = newStartDate;
      this.endDate = newEndDate;
      this.setData();
    }
  };

  setData() {
    const newChartOptions: EChartsOption = this.config.echartsConfig;
    this.chartOptions = null;

    // Get the data
    forkJoin([
      this.chartService
        .getEnvironmentalDataForSourceAndAnalyteByDate(
          this.config.dataConfig.xDataSource.environmentalDataSourceIdentifier,
          this.config.dataConfig.xDataSource.analyteIdentifier,
          moment(this.startDate).format('YYYY-MM-DD'),
          moment(this.endDate).format('YYYY-MM-DD'),
          this.startDate === this.endDate ? 'hour' : 'day'
        )
        .pipe(take(1)),
      this.chartService
        .getEnvironmentalDataForSourceAndAnalyteByDate(
          this.config.dataConfig.yDataSource.environmentalDataSourceIdentifier,
          this.config.dataConfig.yDataSource.analyteIdentifier,
          moment(this.startDate).format('YYYY-MM-DD'),
          moment(this.endDate).format('YYYY-MM-DD'),
          this.startDate === this.endDate ? 'hour' : 'day'
        )
        .pipe(take(1)),
    ]).subscribe({
      next: (queryResponses: any) => {
        // only combine where we have the same date_time for the data point
        const dataMap: { [key: string]: { x?: any; y?: any } } = {};
        queryResponses[0]?.data?.forEach(
          (dataPoint: any) =>
            (dataMap[dataPoint.date_time] = { x: dataPoint[this.config.dataConfig.xDataSource.valueField], y: null })
        );
        queryResponses[1]?.data?.forEach(
          (dataPoint: any) =>
            (dataMap[dataPoint.date_time] = {
              ...dataMap[dataPoint.date_time],
              y: dataPoint[this.config.dataConfig.yDataSource.valueField],
            })
        );

        const scatterData = Object.values(dataMap)
          .filter((dataPoint: any) => !!dataPoint.x && !!dataPoint.y)
          .map((dataPoint: any) => [dataPoint.x, dataPoint.y]);

        if (scatterData.length < 2) {
          // No data
          this.chartOptions = {
            color: ['#6AB6B0'],
            title: {
              text: 'Not enough data is available for the selected date range.',
            },
          };
        } else {
          // Set the data.
          (newChartOptions.series as SeriesOption[])[0].data = scatterData;
          this.chartOptions = newChartOptions;
        }
      },
      error: (err: any) => console.error(err),
    });
  }
}
