import { Component, OnInit } from '@angular/core';
import { default_FilterDrawerLayoutComponent } from '../../default/filter-drawer-layout/filter-drawer-layout.component';
import moment from 'moment';

@Component({
  selector: 'app-client-filter-drawer-layout',
  templateUrl: '../../default/filter-drawer-layout/filter-drawer-layout.component.html',
  styleUrls: ['../../default/filter-drawer-layout/filter-drawer-layout.component.scss'],
})
export class paepae_FilterDrawerLayoutComponent extends default_FilterDrawerLayoutComponent implements OnInit {
  drawerGraphsStacked: boolean = true;
  hasStreamBranding: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
    this.myFilterService.setFilters({
      fromDate: moment('2022-01-01').toDate(),
      toDate: moment('2022-03-31').toDate(),
      calendarTypeYear: 2022,
      calendarTypeMonth: 2,
      calendarTypeDay: 29,
      calendarTypeWeek: 4,
    });
  }
}
