import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { WidgetsModule } from '@app/widgets/widget.module';

/**
 * Widget components
 */

import { WidgetOverviewComponent } from '../legacy-widgets/overview/widget-overview.component';
import { WidgetWaterTakeComponent } from './water-take/widget-water-take.component';
import { WidgetWaterConductivityMonitoringComponent } from './water-monitoring/widget-water-monitoring.component';
import { WidgetWaterPhMonitoringComponent } from './water-monitoring/widget-water-monitoring-ph.component';
import { WidgetSWAComponent } from './swa/widget-swa.component';
import { WidgetSWBComponent } from './swb/widget-swb.component';
import { WidgetSWCComponent } from './swc/widget-swc.component';
import { WidgetSWDComponent } from './swd/widget-swd.component';
import { WidgetGroundwaterProtectionComponent } from './groundwater-protection/widget-groundwater-protection.component';
import { WidgetGroundwaterWellsComponent } from './groundwater-wells/widget-groundwater-wells.component';
import { WidgetMethaneComponent } from './methane/widget-methane.component';
import { WidgetOxygenComponent } from './oxygen/widget-oxygen.component';
import { WidgetIndicatorsComponent } from './indicators/widget-indicators.component';

@NgModule({
  imports: [
    CommonModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    WidgetsModule,
  ],
  declarations: [
    WidgetOverviewComponent,
    WidgetWaterTakeComponent,
    WidgetWaterConductivityMonitoringComponent,
    WidgetWaterPhMonitoringComponent,
    WidgetSWAComponent,
    WidgetSWBComponent,
    WidgetSWCComponent,
    WidgetSWDComponent,
    WidgetGroundwaterProtectionComponent,
    WidgetGroundwaterWellsComponent,
    WidgetMethaneComponent,
    WidgetOxygenComponent,
    WidgetIndicatorsComponent,
  ],
  exports: [
    WidgetOverviewComponent,
    WidgetWaterTakeComponent,
    WidgetWaterConductivityMonitoringComponent,
    WidgetWaterPhMonitoringComponent,
    WidgetSWAComponent,
    WidgetSWBComponent,
    WidgetSWCComponent,
    WidgetSWDComponent,
    WidgetGroundwaterProtectionComponent,
    WidgetGroundwaterWellsComponent,
    WidgetMethaneComponent,
    WidgetOxygenComponent,
    WidgetIndicatorsComponent,
  ],
})
export class LegacyWidgetsModule {}
