import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { GroundWaterCharts } from '../charts/groundWaterCharts';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'legacy-app-widget-groundwater-protection',
  templateUrl: './widget-groundwater-protection.component.html',
  styleUrls: ['./widget-groundwater-protection.component.scss'],
})
export class WidgetGroundwaterProtectionComponent extends LegacyWidgetComponentBase {
  groundwaterNitrogenOption: EChartsOption = {};

  constructor(
    private groundWaterChart: GroundWaterCharts,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  onFilterUpdate(): void {
    this.legacyDataService.getGroundWaterData(this.fromDate, this.toDate).subscribe((result) => {
      if (result.groundwater.length === 0) {
        this.groundwaterNitrogenOption = this.groundWaterChart.getGroundWaterNitrogenData([]);
      } else {
        this.groundwaterNitrogenOption = this.groundWaterChart.getGroundWaterNitrogenData(result);
      }
    });
  }
}
