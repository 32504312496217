import { Directive, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MenuService } from '@app/data/services/menuService';
import { ToastrService } from 'ngx-toastr';
import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { AuthService } from '@app/data/services/authService';
import { FilterSetupType } from '@app/data/services/filterService';
import { SitesService } from '@app/data/services/sitesService';

@Directive()
export abstract class MainLayoutComponentBase extends UnsubscribeManager implements OnInit {
  menuHidden = true;
  menuAnimate = true;
  isAuthenticated = false;
  currentUser: any = undefined;
  currentUserName: string = '';
  menuJSON: any = [];
  menu_landfillManager_JSON: any = [];
  menu_IwiManager_JSON: any = [];
  role = '';
  clientClass = {};
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  menu: any;
  status = true;
  type: FilterSetupType = 'taiao';
  logoSrc = '';

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private menuService: MenuService,
    protected authService: AuthService,
    protected sitesService: SitesService
  ) {
    super();

    /**
     * Set the type when changes pages
     */
    this.subscribeOnObservable(this.router.events, this.setTypeFromRoute.bind(this));
  }

  setClickedRow(i: number, j: number): void {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent(): void {
    this.status = true;
  }

  ngOnInit() {
    this.role = 'public';
    this.setTypeFromRoute();

    // get user.
    this.authService.getLoggedInUser().subscribe(({ user }) => {
      if (user) {
        console.log('user', user);
        this.currentUser = user;
        this.currentUserName = user?.firstName;
        this.isAuthenticated = true;
      } else {
        this.currentUser = null;
        this.isAuthenticated = false;
      }
    });

    this.loadMenuItemsByUser();
  }

  private setTypeFromRoute(): void {
    this.type = 'taiao';

    if (this.router.url.includes('maramatakacalendar')) {
      this.type = 'maramataka';
    } else if (this.router.url.includes('sitehealth')) {
      this.type = 'sitehealth';
    } else if (this.router.url.includes('mapview')) {
      this.type = 'mapview';
    }

    this.onTypeChange();
  }

  public abstract onTypeChange(): void;

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
    this.menuAnimate = !this.menuAnimate;
  }

  private routeToAuthCheck(route: string, isAdmin: boolean = false): void {
    if (this.isAuthenticated && (!isAdmin || this.currentUser.isAdmin)) {
      this.router.navigate([route]);
    } else {
      this.router.navigate([isAdmin ? '/' : '/login']);
    }
  }

  openMapView = () => this.routeToAuthCheck('/mapview');
  openDashboard = () => this.routeToAuthCheck('/taiao');
  openMaramataka = () => this.routeToAuthCheck('/maramatakacalendar');
  openAdmin = () => this.routeToAuthCheck('/admin', true);

  menuClick = (menuURL: string) => {
    this.router.navigate([menuURL]);
  };

  handleNotify(menuitem?: any, childitem?: any) {
    this.router.navigate([menuitem]);
  }

  logout() {
    this.isAuthenticated = false;
    this.authService.logout().subscribe(() => {
      this.toastr.success('Logout Successfull!', 'Success!');
      this.router.navigate(['/login']);
    });
  }

  scrollToTop(): void {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  protected onLoadMenuItems(): void {}

  loadMenuItemsByUser = () => {
    this.menuService.getMenuItems(this.role).subscribe((result) => {
      this.menuJSON = result;

      this.onLoadMenuItems();
    });
  };

  onLogoClick = () => {
    if (this.isAuthenticated) {
      this.router.navigate(['/taiao']);
    } else {
      this.router.navigate(['/home']);
    }
  };
}
