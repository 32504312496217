/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { Amplify, Auth } from 'aws-amplify';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

Amplify.configure({
  Auth: {
    ...environment.amplifyAuthConfig,
    oauth: {
      ...environment.amplifyAuthConfig.oauth,
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
    },
  },
  API: {
    endpoints: [
      {
        name: 'api',
        endpoint: environment.apiBaseURL,
        region: 'ap-southeast-2',
        custom_header: async () => {
          let currentSession;
          try {
            currentSession = await Auth.currentSession();
          } catch (e) {
            // do nothing - not logged in
          }
          if (!currentSession) {
            window.location.assign('/login');
            return false;
          }
          return {
            Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
          };
        },
      },
    ],
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
