import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '@shared';
import { MaterialModule } from '@app/material.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';

/**
 * Old code. Needs a looking at
 */
import { BarChartComponent } from './charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './charts/line-chart/line-chart.component';
import { PolarBarChartComponent } from './charts/polar-bar-chart/polar-bar-chart.component';
import { SignPostChartComponent } from './charts/sign-post-chart/sign-post-chart.component';
import { EnergyPhasesChartComponent } from './charts/energy-phases-chart/energy-phases-chart.component';

import { WidgetCardWrapperComponent } from './widget-card-wrapper/widget-card-wrapper.component';

/**
 * Widget components
 */
import { WidgetMaramatakaPhaseComponent } from './maramataka-phase/widget-maramataka-phase.component';
import { WidgetMonitoringInformationComponent } from './monitoring-information/widget-monitoring-information.component';
import { WidgetTaiaoScorecardComponent } from '../legacy-widgets/taiao-scorecard/widget-taiao-scorecard.component';
import { WidgetHistoricatWeatherComponent } from './historicat-weather/widget-historicat-weather.component';
import { WidgetForcastedWeatherComponent } from './forcasted-weather/widget-forcasted-weather.component';
import { WidgetKokopuComponent } from './kokopu/widget-kokopu.component';
import { WidgetStatusBadgeComponent } from './status-badge/widget-status-badge.component';
import { BasicCartesianChartComponent } from './basic-cartesian-chart/basic-cartesian-chart.component';
import { BasicScatterChartComponent } from './basic-scatter-chart/basic-scatter-chart.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    LeafletModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    MaterialModule,
  ],
  declarations: [
    BarChartComponent,
    LineChartComponent,
    PolarBarChartComponent,
    SignPostChartComponent,
    EnergyPhasesChartComponent,
    WidgetCardWrapperComponent,
    WidgetMaramatakaPhaseComponent,
    WidgetMonitoringInformationComponent,
    WidgetTaiaoScorecardComponent,
    WidgetHistoricatWeatherComponent,
    WidgetForcastedWeatherComponent,
    WidgetKokopuComponent,
    WidgetStatusBadgeComponent,
    BasicCartesianChartComponent,
    BasicScatterChartComponent,
  ],
  exports: [
    WidgetMaramatakaPhaseComponent,
    WidgetMonitoringInformationComponent,
    WidgetTaiaoScorecardComponent,
    WidgetHistoricatWeatherComponent,
    WidgetForcastedWeatherComponent,
    WidgetKokopuComponent,
    WidgetStatusBadgeComponent,
    BasicCartesianChartComponent,
    WidgetCardWrapperComponent,
    BasicScatterChartComponent,
  ],
})
export class WidgetsModule {}
