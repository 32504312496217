import { Component } from '@angular/core';
import { TaiaoDashboardLayoutComponentBase } from '../../../classes/taiao-dashboard-layout-component.class';

@Component({
  selector: 'app-client-taiao-dashboard-layout',
  templateUrl: './taiao-dashboard-layout.component.html',
  styleUrls: ['./taiao-dashboard-layout.component.scss'],
})
export class default_TaiaoDashboardLayoutComponent extends TaiaoDashboardLayoutComponentBase {
  public performanceSummaryTitle: string | null = 'Performance Summary';
}
