<div class="container-fluid contentPage">
  <div class="row">
    <div class="col-md-12 bannerImage"></div>
  </div>
  <div class="container">
    <div class="row headingCont">
      <div class="col-md-12 animate__animated animate__fadeIn animate__delay-1s animate__duration-2s">
        <h2>No Access</h2>
        <h4>You do not have the permission to view this page. Please contact your administrator for access.</h4>
        <p class="mt-5">
          <button class="btn btn-info" type="button" (click)="backToLogin()">Back to Login</button>
        </p>
      </div>
    </div>
  </div>
</div>
