import { Component } from '@angular/core';
import { ClientResolverBase } from '../classes/client-resolver-base.class';

@Component({
  selector: 'app-resolver-client-filter-drawer-layout',
  template: `
    <div>
      <ng-template #layoutView></ng-template>
    </div>
  `,
})
export class ClientResolverFilterDrawerLayoutComponent extends ClientResolverBase {
  protected getLayoutType(): 'filter-drawer' {
    return 'filter-drawer';
  }
}
