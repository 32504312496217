import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class WeatherChart {
  public getHistoricalWeatherChart(result: any): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
      },
      color: ['#0B3CA4', '#07add6'],
      toolbox: {
        feature: {
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ['Precipitation', 'Wind Speed'],
      },
      xAxis: [
        {
          type: 'category',
          data: result.historical.map((h: any) => moment(h.date).format('DD/MM/YYYY')),
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Precipitation',
          min: 0,
          interval: 1,
          axisLabel: {
            formatter: '{value} mm',
          },
        },
        {
          type: 'value',
          name: 'Wind Speed',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value} m/sec',
          },
        },
      ],
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + ' mm';
            },
          },
          data: result.historical.map((h: any) => h.rain),
        },
        {
          name: 'Wind Speed',
          type: 'line',
          lineStyle: {
            width: 3,
          },
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' m/sec';
            },
          },
          data: result.historical.map((h: any) => h.wind),
        },
      ],
    };
  }

  public getForecastedWeatherChart(result: any): EChartsOption {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999',
          },
        },
      },
      color: ['#0B3CA4', '#07add6'],
      toolbox: {
        feature: {
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ['Precipitation', 'Wind Speed'],
      },
      xAxis: [
        {
          type: 'category',
          data: result.forecast.map((h: any) => moment(h.date).format('DD/MM/YYYY')),
          axisPointer: {
            type: 'shadow',
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Precipitation',
          min: 0,
          interval: 1,
          axisLabel: {
            formatter: '{value} mm',
          },
        },
        {
          type: 'value',
          name: 'Wind Speed',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value} m/sec',
          },
        },
      ],
      series: [
        {
          name: 'Precipitation',
          type: 'bar',
          tooltip: {
            valueFormatter: function (value) {
              return value + ' mm';
            },
          },
          data: result.forecast.map((h: any) => h.rain),
        },
        {
          name: 'Wind Speed',
          type: 'line',
          lineStyle: {
            width: 3,
          },
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function (value) {
              return value + ' m/sec';
            },
          },
          data: result.forecast.map((h: any) => h.wind),
        },
      ],
    };
  }
}
