import { Component, Inject, OnInit } from '@angular/core';
import { DateRangeService } from './date-range.service';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';

type Range = { key: string; title: string; start: Date; end: Date; last?: boolean };

const seasons: { [key: string]: number } = {
  winter: 5,
  spring: 8,
  summer: 11,
  autumn: 2,
};

const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());
};

@Component({
  selector: 'app-date-range-presets',
  template: `
    <div class="container">
      <h4>Quick Dates</h4>
      <div class="date-range-presets">
        <ng-container *ngFor="let option of rangeOptions">
          <button (click)="selectRange(option.key)" class="range-preset-button">
            {{ option.title }}
          </button>
          <div *ngIf="option.last" class="divider"></div>
        </ng-container>
      </div>
      <h4>Custom Range</h4>
      <div class="calendar-mv-buttons">
        <button (click)="previousClicked('year')" class="calendar-mv">Y-</button>
        <button (click)="previousClicked('month')" class="calendar-mv">M-</button>
        <div class="calendar-datelabel">{{ dateLabel }}</div>
        <button (click)="nextClicked('month')" class="calendar-mv">M+</button>
        <button (click)="nextClicked('year')" class="calendar-mv">Y+</button>
      </div>
    </div>
  `,
  styles: [
    `
      .date-range-presets {
        display: flex;
        flex-wrap: wrap;
        gap: 0.75em 1em;
        margin-bottom: 0.8em;
      }
      .range-preset-button {
        cursor: pointer;
        background-color: #3f51b5;
        background-color: rgba(163, 177, 233, 0);
        color: rgba(0, 0, 0, 0.75);
        border: none;
        border-radius: 0.3em;
        cursor: pointer;
        text-align: left;
        padding: 0;
      }
      .range-preset-button:hover {
        text-decoration: underline;
        color: rgb(0, 0, 0);
      }
      .calendar-mv {
        padding: 0.25em 0.8em;
        line-height: 1;
        font-weight: 900;
        border-radius: 0.3em;
        background-color: rgba(163, 177, 233, 0.5);
        border: none;
        flex-grow: 0;
        font-size: 0.75em;
        cursor: pointer;
      }
      .calendar-mv:hover {
        background-color: rgba(163, 177, 233, 0.7);
        color: black;
      }
      ::ng-deep .mat-datepicker-content .mat-calendar-content {
        min-height: 281px;
      }
      .calendar-datelabel {
        flex-grow: 1;
        text-align: center;
      }
      .calendar-mv-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.25em;
        width: 100%;
        justify-content: center;
        margin-bottom: 0.8em;
      }
      h4 {
        background-color: rgba(163, 177, 233, 0.5);
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.8em;
        flex-grow: 1;
        padding: 0.6em 1.2em;
        margin: 0 -1.2em 0.8em;
      }
      .container {
        color: rgba(0, 0, 0, 0.75);
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: rgba(163, 177, 233, 0.3);
      }
    `,
  ],
})
export class DateRangePresetsComponent<DT> implements OnInit {
  constructor(
    private dateRangeService: DateRangeService,
    private matCalendar: MatCalendar<DT>,
    private dateAdapter: DateAdapter<DT>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats
  ) {}
  private today = new Date();
  public rangeOptions: Range[] = [
    {
      title: 'Last 24 Hours',
      key: 'last24Hours',
      start: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1),
      end: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()),
    },
    {
      title: 'Last 7 Days',
      key: 'last7Days',
      start: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 7),
      end: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()),
    },
    {
      title: 'Last 4 Weeks',
      key: 'last4Weeks',
      start: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 28),
      end: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate()),
      last: true,
    },
    {
      key: 'lastMonth',
      title: 'Previous Month',
      start: new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1),
      end: new Date(this.today.getFullYear(), this.today.getMonth(), 0),
    },
    {
      title: 'This Month',
      key: 'thisMonth',
      start: new Date(this.today.getFullYear(), this.today.getMonth(), 1),
      end: new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0),
      last: true,
    },
    ...['winter', 'spring', 'summer', 'autumn']
      .map((season) => {
        const seasonYear = this.seasonYear(season);
        return {
          key: season,
          title: `${toTitleCase(season)} ${seasonYear.toString()}`,
          start: new Date(seasonYear, seasons[season], 1),
          end: new Date(season === 'summer' ? seasonYear + 1 : seasonYear, (seasons[season] + 3) % 12, 1),
        };
      })
      .sort((a, b) => (a.start > b.start ? 1 : -1)),
  ];

  ngOnInit() {
    const thisYear = this.today.getFullYear();
    const showYears = 4;
    this.rangeOptions[this.rangeOptions.length - 1].last = true;
    for (let i = thisYear - showYears + 1; i < thisYear + 1; i++) {
      this.rangeOptions.push({
        title: i.toString(),
        start: new Date(i, 0, 1),
        end: new Date(i, 11, 31),
        key: i.toString(),
      });
    }
  }

  seasonYear(season: string): number {
    const isLater = seasons[season] > this.today.getMonth();
    return isLater ? this.today.getFullYear() - 1 : this.today.getFullYear();
  }

  get dateLabel(): string {
    return this.dateAdapter
      .format(this.matCalendar.activeDate, this.dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year'): void {
    this.changeDate(mode, -1);
  }

  nextClicked(mode: 'month' | 'year'): void {
    this.changeDate(mode, 1);
  }

  private changeDate(mode: 'month' | 'year', amount: -1 | 1): void {
    this.matCalendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.matCalendar.activeDate, amount)
        : this.dateAdapter.addCalendarYears(this.matCalendar.activeDate, amount);
  }

  selectRange(preset: string): void {
    const option: Range | undefined = this.rangeOptions.find((x) => x.key === preset);
    if (option) {
      this.dateRangeService.updateRange(option.start, option.end);
    }
  }
}
