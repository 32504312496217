import { Component } from '@angular/core';
import { default_TaiaoDashboardLayoutComponent } from '../../default/taiao-dashboard-layout/taiao-dashboard-layout.component';
@Component({
  selector: 'app-client-taiao-dashboard-layout',
  templateUrl: '../../default/taiao-dashboard-layout/taiao-dashboard-layout.component.html',
  styleUrls: ['./taiao-dashboard-layout.component.scss'],
})
export class paepae_TaiaoDashboardLayoutComponent extends default_TaiaoDashboardLayoutComponent {
  public performanceSummaryTitle: string | null = null;
}
