import moment from 'moment';

interface AlertSeriesOverride {
  yAxisIndex: number;
  type: string;
  showSymbol: boolean;
}

type AlertSeriesOverrideFunc = (alert: any, overrides: AlertSeriesOverride) => void;
type AlertSeriesDateLabelFunc = (e: any) => string;

export const getAlertSeries = (
  alerts: any[],
  chartData: any[],
  dateField: string | AlertSeriesDateLabelFunc,
  overrideFunc?: AlertSeriesOverrideFunc
): any[] => {
  const getDateLabel: AlertSeriesDateLabelFunc =
    typeof dateField === 'string' ? (e: any) => e[dateField] as string : dateField;
  const getOverrides: AlertSeriesOverrideFunc =
    overrideFunc || (() => ({ yAxisIndex: 0, type: 'line', showSymbol: false }));

  let prevDate: string = '';
  const datePosMap: Record<string, number> = chartData.reduce(
    (accumulator: Record<string, number>, e: any, index: number) => {
      const eventDate = getDateLabel(e);

      if (!accumulator[eventDate]) {
        accumulator[eventDate] = 1;

        prevDate = eventDate;
      } else {
        accumulator[eventDate]++;
      }

      return accumulator;
    },
    {}
  );

  return alerts.reduce((accumulator: any, alert: any) => {
    const overrides: AlertSeriesOverride = {
      yAxisIndex: 0,
      type: 'line',
      showSymbol: false,
    };

    getOverrides(alert, overrides);

    return [
      ...accumulator,
      {
        name: `${alert.name} (${alert.relationalOperator} ${alert.value}${alert.parameter.unit})`,
        yAxisIndex: overrides.yAxisIndex,
        data: Object.entries(datePosMap).reduce(
          (accumulator: number[], [date, amount]) => [
            ...accumulator,
            ...new Array(amount).fill(
              moment(date).isBetween(alert.startDate, alert.endDate, undefined, '[]') ? alert.value : null
            ),
          ],
          []
        ),
        type: overrides.type,
        color: `#${alert.colour}`,
        showSymbol: overrides.showSymbol,
      },
    ];
  }, []);
};
