import { BasicCartesianChartConfig, BasicScatterChartConfig } from '@app/data/interface/widget-config';

export const HarihariNutrients: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'TKN\ng/m³',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'P\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'Total Kjeldahl Nitrogen',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Phosphorus',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Harihari Nutrients',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'total-kjeldahl-nitrogen',
        valueField: 'avg',
        label: 'Total Kjeldahl Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'total-phosphorus',
        valueField: 'avg',
        label: 'Total Phosphorus',
      },
    ],
  },
};

export const HarihariWaterChemistry: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'pH',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'TAN\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
      {
        type: 'value',
        name: 'NNN\ng/m³',
        position: 'right',
        offset: 40,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#52844B',
          },
        },
        axisLabel: {
          color: '#52844B',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'pH',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Ammoniacal Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
      {
        type: 'line',
        yAxisIndex: 2,
        name: 'Nitrate Nitrite Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Harihari Water Chemistry',
    widgetId: 2,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'ph-in-lab',
        valueField: 'avg',
        label: 'pH',
      },
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'total-ammoniacal-nitrogen',
        valueField: 'avg',
        label: 'Total Ammoniacal Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'nitrate-+-nitrite-nitrogen-grams-per-cubic-metre',
        valueField: 'avg',
        label: 'Nitrate Nitrite Nitrogen',
      },
    ],
  },
};

export const HarihariWaterClarity: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '5%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'Turbidity\nNTU',
        position: 'left',
        offset: 40,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'TSS\ng/m³',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Depth\nm',
        position: 'right',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Turbidity',
        tooltip: {
          valueFormatter: (value) => value + 'NTU',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total Suspended Solids',
        tooltip: {
          valueFormatter: (value) => value + 'g/m³',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 2,
        name: 'Secchi Depth',
        tooltip: {
          valueFormatter: (value) => value + 'm',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Harihari Water Clarity',
    widgetId: 5,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'turbidity',
        valueField: 'avg',
        label: 'Turbidity',
      },
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'total-suspended-solids',
        valueField: 'avg',
        label: 'Total Suspended Solids',
      },
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'setchi-disk',
        valueField: 'avg',
        label: 'Secchi Depth',
      },
    ],
  },
};

export const HarihariContaminants: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '7%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'cfu/100ml',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'E. coli',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Harihari Contaminants',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'e.-coli',
        valueField: 'avg',
        label: 'E. coli',
      },
    ],
  },
};

export const HarihariTempChlorophyll: BasicScatterChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '10%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      name: 'Temp\n°C',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Chlor. a\nμg/m³',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 10,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Harihari Chlorophyll a Temperature Plot',
    widgetId: 5,
    domain: 'wai',
    xDataSource: {
      environmentalDataSourceIdentifier: '1994/1',
      analyteIdentifier: 'water-temperature',
      valueField: 'avg',
      label: 'Water Temperature',
    },
    yDataSource: {
      environmentalDataSourceIdentifier: '1994/1',
      analyteIdentifier: 'chlorophyll-a',
      valueField: 'avg',
      label: 'Chlorophyll a',
    },
  },
};

export const NumitiNutrients: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'TKN\ng/m³',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'P\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'Total Kjeldahl Nitrogen',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Phosphorus',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Numiti Nutrients',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'total-kjeldahl-nitrogen',
        valueField: 'avg',
        label: 'Total Kjeldahl Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'total-phosphorus',
        valueField: 'avg',
        label: 'Total Phosphorus',
      },
    ],
  },
};

export const NumitiWaterChemistry: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'pH',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'TAN\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
      {
        type: 'value',
        name: 'NNN\ng/m³',
        position: 'right',
        offset: 40,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#52844B',
          },
        },
        axisLabel: {
          color: '#52844B',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'pH',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Ammoniacal Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
      {
        type: 'line',
        yAxisIndex: 2,
        name: 'Nitrate Nitrite Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Numiti Water Chemistry',
    widgetId: 2,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'ph-in-lab',
        valueField: 'avg',
        label: 'pH',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'total-ammoniacal-nitrogen',
        valueField: 'avg',
        label: 'Total Ammoniacal Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'nitrate-+-nitrite-nitrogen-grams-per-cubic-metre',
        valueField: 'avg',
        label: 'Nitrate Nitrite Nitrogen',
      },
    ],
  },
};

export const NumitiWaterClarity: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '5%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'Turbidity\nNTU',
        position: 'left',
        offset: 40,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'TSS\ng/m³',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Depth\nm',
        position: 'right',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Turbidity',
        tooltip: {
          valueFormatter: (value) => value + 'NTU',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total Suspended Solids',
        tooltip: {
          valueFormatter: (value) => value + 'g/m³',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 2,
        name: 'Secchi Depth',
        tooltip: {
          valueFormatter: (value) => value + 'm',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Numiti Water Clarity',
    widgetId: 5,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'turbidity',
        valueField: 'avg',
        label: 'Turbidity',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'total-suspended-solids',
        valueField: 'avg',
        label: 'Total Suspended Solids',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'setchi-disk',
        valueField: 'avg',
        label: 'Secchi Depth',
      },
    ],
  },
};

export const NumitiContaminants: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '7%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'cfu/100ml',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'E. coli',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Numiti Contaminants',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'e.-coli',
        valueField: 'avg',
        label: 'E. coli',
      },
    ],
  },
};

export const NumitiTempChlorophyll: BasicScatterChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '10%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      name: 'Temp\n°C',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Chlor. a\nμg/m³',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 10,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Numiti Chlorophyll a Temperature Plot',
    widgetId: 5,
    domain: 'wai',
    xDataSource: {
      environmentalDataSourceIdentifier: '3108/1',
      analyteIdentifier: 'water-temperature',
      valueField: 'avg',
      label: 'Water Temperature',
    },
    yDataSource: {
      environmentalDataSourceIdentifier: '3108/1',
      analyteIdentifier: 'chlorophyll-a',
      valueField: 'avg',
      label: 'Chlorophyll a',
    },
  },
};

export const RotoroaNutrients: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '7%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'TKN\ng/m³',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'P\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'Total Kjeldahl Nitrogen',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Phosphorus',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Rotoroa Nutrients',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'total-kjeldahl-nitrogen',
        valueField: 'avg',
        label: 'Total Kjeldahl Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'total-phosphorus',
        valueField: 'avg',
        label: 'Total Phosphorus',
      },
    ],
  },
};

export const RotoroaWaterChemistry: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'pH',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#6AB6B0',
          },
        },
        axisLabel: {
          color: '#6AB6B0',
        },
      },
      {
        type: 'value',
        name: 'TAN\ng/m³',
        position: 'right',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
      {
        type: 'value',
        name: 'NNN\ng/m³',
        position: 'right',
        offset: 40,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#52844B',
          },
        },
        axisLabel: {
          color: '#52844B',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'pH',
        symbolSize: 13,
      },
      {
        type: 'line',
        yAxisIndex: 1,
        name: 'Total Ammoniacal Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
      {
        type: 'line',
        yAxisIndex: 2,
        name: 'Nitrate Nitrite Nitrogen',
        symbolSize: 13,
        tooltip: {
          valueFormatter: (value) => value + ' g/m³',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Rotoroa Water Chemistry',
    widgetId: 2,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'ph-in-lab',
        valueField: 'avg',
        label: 'pH',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'total-ammoniacal-nitrogen',
        valueField: 'avg',
        label: 'Total Ammoniacal Nitrogen',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'nitrate-+-nitrite-nitrogen-grams-per-cubic-metre',
        valueField: 'avg',
        label: 'Nitrate Nitrite Nitrogen',
      },
    ],
  },
};

export const RotoroaWaterClarity: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '5%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'Turbidity\nNTU',
        position: 'left',
        offset: 40,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'TSS\ng/m³',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
      {
        type: 'value',
        name: 'Depth\nm',
        position: 'right',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Turbidity',
        tooltip: {
          valueFormatter: (value) => value + 'NTU',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 1,
        name: 'Total Suspended Solids',
        tooltip: {
          valueFormatter: (value) => value + 'g/m³',
        },
      },
      {
        type: 'bar',
        yAxisIndex: 2,
        name: 'Secchi Depth',
        tooltip: {
          valueFormatter: (value) => value + 'm',
        },
      },
    ],
  },
  dataConfig: {
    title: 'Lake Rotoroa Water Clarity',
    widgetId: 5,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'turbidity',
        valueField: 'avg',
        label: 'Turbidity',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'total-suspended-solids',
        valueField: 'avg',
        label: 'Total Suspended Solids',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'setchi-disk',
        valueField: 'avg',
        label: 'Secchi Depth',
      },
    ],
  },
};

export const RotoroaContaminants: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '7%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'cfu/100ml',
        position: 'left',
        offset: 0,
        boundaryGap: [0, '100%'],
        alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            color: '#2A5FA5',
          },
        },
        axisLabel: {
          color: '#2A5FA5',
        },
      },
    ],
    series: [
      {
        type: 'line',
        yAxisIndex: 0,
        name: 'E. coli',
        symbolSize: 13,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Rotoroa Contaminants',
    widgetId: 1,
    domain: 'wai',
    dataSources: [
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'e.-coli',
        valueField: 'avg',
        label: 'E. coli',
      },
    ],
  },
};

export const RotoroaTempChlorophyll: BasicScatterChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '10%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      name: 'Temp\n°C',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      name: 'Chlor. a\nμg/m³',
      offset: 0,
      alignTicks: true,
      axisLine: {
        show: true,
      },
    },
    series: [
      {
        type: 'scatter',
        symbolSize: 10,
      },
    ],
  },
  dataConfig: {
    title: 'Lake Rotoroa Chlorophyll a Temperature Plot',
    widgetId: 5,
    domain: 'wai',
    xDataSource: {
      environmentalDataSourceIdentifier: '3109/1',
      analyteIdentifier: 'water-temperature',
      valueField: 'avg',
      label: 'Water Temperature',
    },
    yDataSource: {
      environmentalDataSourceIdentifier: '3109/1',
      analyteIdentifier: 'chlorophyll-a',
      valueField: 'avg',
      label: 'Chlorophyll a',
    },
  },
};

export const WaterLevels: BasicCartesianChartConfig = {
  echartsConfig: {
    color: ['#6AB6B0', '#2A5FA5', '#52844B'],
    legend: {},
    tooltip: {
      trigger: 'axis',
      position: function (pt: any) {
        return [pt[0], '10%'];
      },
      textStyle: {
        color: '#fff',
      },
    },
    toolbox: {
      feature: {
        dataView: { show: true, readOnly: false },
        saveAsImage: { show: true },
      },
    },
    grid: {
      top: '25%',
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: [],
    },
    yAxis: [
      {
        type: 'value',
        name: 'Metres',
        position: 'left',
        offset: 0,
        alignTicks: true,
        axisLine: {
          show: true,
        },
      },
    ],
    series: [
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Lake Harihari',
      },
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Lake Numiti',
      },
      {
        type: 'bar',
        yAxisIndex: 0,
        name: 'Lake Rotoroa',
      },
    ],
  },
  dataConfig: {
    title: 'Water Levels',
    widgetId: 3,
    domain: 'wai',

    dataSources: [
      {
        environmentalDataSourceIdentifier: '1994/1',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Lake Harihari',
      },
      {
        environmentalDataSourceIdentifier: '3108/1',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Lake Numiti',
      },
      {
        environmentalDataSourceIdentifier: '3109/1',
        analyteIdentifier: 'relative-water-level',
        valueField: 'avg',
        label: 'Lake Rotoroa',
      },
    ],
  },
};
