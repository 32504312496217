import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';

@Injectable({
  providedIn: 'root',
})
export class MethaneGenerationChart {
  public getMethaneData(result: any): EChartsOption {
    let data: any = {};
    if (!result || result.homes === 0) {
      data = {
        value: 0,
        name: 'No data is available for the selected date ranges',
      };
    } else {
      data = {
        value: result.homes,
        name: 'Equivalent homes powered by landfill biogas',
      };
    }

    return {
      series: [
        {
          type: 'gauge',
          radius: '100%',
          center: ['50%', '70%'],
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 6000,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.25, '#52844B'],
                [0.5, '#2A5FA5'],
                [0.75, '#D0752B'],
                [1, '#9a2e9a'],
              ],
            },
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto',
            },
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2,
            },
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5,
            },
          },
          axisLabel: {
            fontSize: 20,
            distance: -50,
            rotate: 'tangential',
            formatter: function (value) {
              switch (value) {
                case 0:
                  return '0';
                case 1500:
                  return '1500';
                case 3000:
                  return '3000';
                case 4500:
                  return '4500';
                case 6000:
                  return '6000';
                default:
                  return '';
              }
            },
          },
          title: {
            offsetCenter: [0, '40%'],
            fontSize: 20,
          },
          detail: {
            fontSize: 38,
            offsetCenter: [10, '-10%'],
            valueAnimation: true,
            color: 'auto',
            formatter: function (value) {
              return `${value} homes`;
            },
          },
          data: [data],
        },
      ],
    };
  }
}
