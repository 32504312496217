import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { MethaneChart } from '../charts/methaneChart';
import { MethaneGenerationChart } from '../charts/methaneChartGeneration';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';
import { ClientConstantType } from '@app/client/client.constant';

@Component({
  selector: 'legacy-app-widget-methane',
  templateUrl: './widget-methane.component.html',
  styleUrls: ['./widget-methane.component.scss'],
})
export class WidgetMethaneComponent extends LegacyWidgetComponentBase {
  methaneCDChartOption: EChartsOption = {};
  chartTitle: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? 'Hau - Energy Capacity from Landfill Biogas'
      : 'Water Use';
  showHeader: boolean =
    this.clientService.getClient() === ClientConstantType.paepae ||
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? true
      : false;

  constructor(
    private methaneChart: MethaneChart,
    private methaneGenChart: MethaneGenerationChart,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  onFilterUpdate(): void {
    if (this.client === ClientConstantType.wastemanagement) {
      this.legacyDataService.getMethaneGenData(this.fromDate, this.toDate).subscribe((result) => {
        this.methaneCDChartOption = this.methaneGenChart.getMethaneData(result);
      });
    } else {
      this.legacyDataService.getMethaneData(this.fromDate, this.toDate).subscribe((result) => {
        this.methaneCDChartOption = this.methaneChart.getMethaneData(result.gas, 55);
      });
    }
  }
}
