import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';

import { LoaderComponent } from './loader/loader.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { SiteSelectorComponent } from './site-selector/site-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { DrawerGraphsComponent } from './drawer-graphs/drawer-graphs.component';
import { WidgetsModule } from '@app/widgets/widget.module';
import { DateRangePresetsComponent } from './date-range-presets/date-range-presets.component';
import { RongoObservationSummaryComponent } from '@app/client/clients/default/rongo-observation-summary/rongo-observation-summary.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    ReactiveFormsModule,
    WidgetsModule,
  ],
  declarations: [
    LoaderComponent,
    ConfirmDialogComponent,
    SiteSelectorComponent,
    DrawerGraphsComponent,
    DateRangePresetsComponent,
    RongoObservationSummaryComponent,
  ],
  exports: [
    LoaderComponent,
    ConfirmDialogComponent,
    SiteSelectorComponent,
    DrawerGraphsComponent,
    DateRangePresetsComponent,
    RongoObservationSummaryComponent,
  ],
})
export class SharedModule {}
