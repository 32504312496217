<div nz-row>
  <div nz-col nzSpan="12">
    <!-- <h5> id=chart1 </h5> -->
    <!-- <div id="chart1" echarts [options]="options" theme="macarons" class="demo-chart"></div> -->
    <!-- {{options | json}} -->

    <!-- <hr/> -->

    <div
      id="chart1"
      echarts
      [options]="options"
      theme="macarons"
      class="demo-chart"
      style="max-height: 250px; margin-top: -30px"
    ></div>
  </div>
  <div nz-col nzSpan="12">
    <!-- <h5> id=chart2 </h5> -->
    <!-- <div id="chart2" echarts [options]="options" theme="macarons" class="demo-chart"></div> -->
  </div>
</div>

<!-- <code-block
[html]="html"
[component]="component">
</code-block> -->
