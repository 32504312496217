<table cellspacing="0" cellpadding="0" width="100%" height="100%">
  <tr>
    <td width="20%">
      <table cellspacing="0" cellpadding="0">
        <tr>
          <td width="30%;" valign="top">
            <h4>Current State</h4>
            <h2>
              <div [innerHTML]="currentState"></div>
            </h2>
            <br />
            <h4>Phase Start</h4>
            <h2>{{ energyPhaseDates.length > 0 ? energyPhaseDates[0] : '' }}</h2>
            <br />
            <h4>Phase End</h4>
            <h2>{{ energyPhaseDates.length > 0 ? energyPhaseDates[energyPhaseDates.length - 1] : '' }}</h2>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <div nz-row>
        <div nz-col nzSpan="12">
          <div id="container" echarts [options]="options" theme="macarons"></div>
        </div>
      </div>
    </td>
  </tr>
</table>
