// import { analyzeAndValidateNgModules } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { getAlertSeries } from './chartUtil';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class SurfaceWaterChart {
  public getSurfaceWaterData(
    result: any,
    showLegend = true
  ): { A: EChartsOption; B: EChartsOption; C: EChartsOption; D: EChartsOption } {
    if (result.length === 0) {
      return {
        A: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          title: {
            text: 'No data is available for the selected date range.',
          },
          legend: {
            data: ['bar', 'bar2'],
          },
          toolbox: {
            // y: 'bottom',
            feature: {
              magicType: {
                type: ['stack'],
              },
            },
          },
          tooltip: {},
          xAxis: {
            data: [],
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            name: 'g/m³',
          },
          series: [],
          animationEasing: 'elasticOut',
          animationDelayUpdate: function (idx) {
            return idx * 5;
          },
        },
        B: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          title: {
            text: 'No data is available for the selected date range.',
          },
          legend: {
            data: ['bar', 'bar2'],
          },
          toolbox: {
            // y: 'bottom',
            feature: {
              magicType: {
                type: ['stack'],
              },
            },
          },
          tooltip: {},
          xAxis: {
            data: [],
            splitLine: {
              show: false,
            },
          },
          yAxis: {},
          series: [],
          animationEasing: 'elasticOut',
          animationDelayUpdate: function (idx) {
            return idx * 5;
          },
        },
        C: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          title: {
            text: 'No data is available for the selected date range.',
          },
          legend: {
            data: ['bar', 'bar2'],
          },
          toolbox: {
            // y: 'bottom',
            feature: {
              magicType: {
                type: ['stack'],
              },
            },
          },
          tooltip: {},
          xAxis: {
            name: 'g/m³',
            data: [],
            splitLine: {
              show: false,
            },
          },
          yAxis: {},
          series: [],
          animationEasing: 'elasticOut',
          animationDelayUpdate: function (idx) {
            return idx * 5;
          },
        },
        D: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          title: {
            text: 'No data is available for the selected date range.',
          },
          legend: {
            data: ['bar', 'bar2'],
          },
          toolbox: {
            // y: 'bottom',
            feature: {
              magicType: {
                type: ['stack'],
              },
            },
          },
          tooltip: {},
          xAxis: {
            data: [],
            splitLine: {
              show: false,
            },
          },
          yAxis: {},
          series: [],
          animationEasing: 'elasticOut',
          animationDelayUpdate: function (idx) {
            return idx * 5;
          },
        },
      };
    } else {
      const alertSeries = getAlertSeries(result.alerts, result.surfacewater.surfacewater, 'date');

      return {
        A: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            show: showLegend,
            data: ['boron', 'zinc', 'nitrate nitrogen', 'ammoniacal nitrogen'],
            selected: {
              boron: true,
              zinc: false,
              'nitrate nitrogen': false,
              'ammoniacal nitrogen': false,
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data:
                result.surfacewater.surfacewater.filter((e: any) => e.location === 'SWA').length === 1
                  ? transformSingleDateIntoSeries(
                      result.surfacewater.surfacewater
                        .filter((e: any) => e.location === 'SWA')
                        .map((e: any) => moment(e.date).format('DD/MM/YYYY'))
                    )
                  : result.surfacewater.surfacewater
                      .filter((e: any) => e.location === 'SWA')
                      .map((e: any) => moment(e.date).format('DD/MM/YYYY')),
            },
          ],
          yAxis: [
            {
              name: 'g/m³',
              type: 'value',
            },
          ],
          series: [...this.getDataSeries(result.surfacewater, 'SWA'), ...alertSeries],
        },
        B: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            show: showLegend,
            data: ['boron', 'zinc', 'nitrate nitrogen', 'ammoniacal nitrogen'],
            selected: {
              boron: true,
              zinc: false,
              'nitrate nitrogen': false,
              'ammoniacal nitrogen': false,
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data:
                result.surfacewater.surfacewater.filter((e: any) => e.location === 'SWB').length === 1
                  ? transformSingleDateIntoSeries(
                      result.surfacewater.surfacewater
                        .filter((e: any) => e.location === 'SWB')
                        .map((e: any) => moment(e.date).format('DD/MM/YYYY'))
                    )
                  : result.surfacewater.surfacewater
                      .filter((e: any) => e.location === 'SWB')
                      .map((e: any) => moment(e.date).format('DD/MM/YYYY')),
            },
          ],
          yAxis: [
            {
              name: 'g/m³',
              type: 'value',
            },
          ],
          series: [...this.getDataSeries(result.surfacewater, 'SWB'), ...alertSeries],
        },
        C: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            show: showLegend,
            data: ['boron', 'zinc', 'nitrate nitrogen', 'ammoniacal nitrogen'],
            selected: {
              boron: true,
              zinc: false,
              'nitrate nitrogen': false,
              'ammoniacal nitrogen': false,
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data:
                result.surfacewater.surfacewater.filter((e: any) => e.location === 'SWC').length === 1
                  ? transformSingleDateIntoSeries(
                      result.surfacewater.surfacewater
                        .filter((e: any) => e.location === 'SWC')
                        .map((e: any) => moment(e.date).format('DD/MM/YYYY'))
                    )
                  : result.surfacewater.surfacewater
                      .filter((e: any) => e.location === 'SWC')
                      .map((e: any) => moment(e.date).format('DD/MM/YYYY')),
            },
          ],
          yAxis: [
            {
              name: 'g/m³',
              type: 'value',
            },
          ],
          series: [...this.getDataSeries(result.surfacewater, 'SWC'), ...alertSeries],
        },
        D: {
          color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985',
              },
            },
          },
          legend: {
            show: showLegend,
            data: ['boron', 'zinc', 'nitrate nitrogen', 'ammoniacal nitrogen'],
            selected: {
              boron: true,
              zinc: false,
              'nitrate nitrogen': false,
              'ammoniacal nitrogen': false,
            },
          },
          toolbox: {
            feature: {
              saveAsImage: {},
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data:
                result.surfacewater.surfacewater.filter((e: any) => e.location === 'SWD').length === 1
                  ? transformSingleDateIntoSeries(
                      result.surfacewater.surfacewater
                        .filter((e: any) => e.location === 'SWD')
                        .map((e: any) => moment(e.date).format('DD/MM/YYYY'))
                    )
                  : result.surfacewater.surfacewater
                      .filter((e: any) => e.location === 'SWD')
                      .map((e: any) => moment(e.date).format('DD/MM/YYYY')),
            },
          ],
          yAxis: [
            {
              name: 'g/m³',
              type: 'value',
            },
          ],
          series: [...this.getDataSeries(result.surfacewater, 'SWD'), ...alertSeries],
        },
      };
    }
  }

  public getSurfaceWaterPhSensorData(result: any, selectedSWSensorLocation: any): EChartsOption {
    if (result.length === 0) {
      return {
        color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
        title: {
          text: 'No data is available for the selected date range.',
        },
        tooltip: {},
        xAxis: {
          data: [],
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            name: 'pH',
          },
        ],
        series: [],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        },
      };
    } else {
      const alertSeries = getAlertSeries(result.alerts, result.monitoringData, 'date');

      return {
        toolbox: {
          // y: 'bottom',
          feature: {
            dataView: {},
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        xAxis: {
          data: result.monitoringData.map((e: any) =>
            selectedSWSensorLocation !== ''
              ? e.location === selectedSWSensorLocation
                ? moment(e.timestamp).format('DD/MM/YYYY')
                : 0
              : moment(e.timestamp).format('DD/MM/YYYY')
          ),
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            name: 'pH',
            type: 'value',
            min: 0,
            max: 14,
            interval: 2,
          },
        ],
        // color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
        series: [
          {
            name: 'pH Levels',
            type: 'line',
            data: result.monitoringData.map((e: any) =>
              selectedSWSensorLocation !== '' ? (e.location === selectedSWSensorLocation ? e.ph : 0) : e.ph
            ),
            emphasis: {
              focus: 'series',
            },
            animationDelay: function (idx) {
              return idx * 10 + 100;
            },
            color: '#2A5FA5',
          },
          ...alertSeries,
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        },
      };
    }
  }

  public getSurfaceWaterConductivitySensorData(result: any, selectedSWSensorLocation: any): EChartsOption {
    if (result.length === 0) {
      return {
        color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
        title: {
          text: 'No data is available for the selected date range.',
        },
        legend: {
          data: ['bar', 'bar2'],
        },
        tooltip: {},
        xAxis: {
          data: [],
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            name: 'mS/cm',
          },
        ],
        series: [],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        },
      };
    } else {
      const alertSeries = getAlertSeries(result.alerts, result.monitoringData, 'date');

      return {
        toolbox: {
          // y: 'bottom',
          feature: {
            dataView: {},
            saveAsImage: {
              pixelRatio: 2,
            },
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        xAxis: {
          data: result.monitoringData.map((e: any) =>
            selectedSWSensorLocation !== ''
              ? e.location === selectedSWSensorLocation
                ? moment(e.timestamp).format('DD/MM/YYYY')
                : 0
              : moment(e.timestamp).format('DD/MM/YYYY')
          ),
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            name: 'mS/cm',
            type: 'value',
          },
        ],
        // color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#56d4ec', '#D0752B', '#B97AC4', '#82C2ED'],
        series: [
          {
            name: 'Conductivity',
            type: 'line',
            data: result.monitoringData.map((e: any) =>
              selectedSWSensorLocation !== ''
                ? e.location === selectedSWSensorLocation
                  ? e.microSiemensPerCm / 1000
                  : 0
                : e.microSiemensPerCm / 1000
            ),
            emphasis: {
              focus: 'series',
            },
            animationDelay: function (idx) {
              return idx * 10;
            },
            color: '#6AB6B0',
          },
          ...alertSeries,
        ],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        },
      };
    }
  }

  private getDataSeries(result: any, location: string): Array<any> {
    return [
      {
        name: 'boron',
        type: 'scatter',
        symbolSize: 13,
        stack: 'Total',
        connectNulls: false,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: 'series',
        },
        data:
          result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalBoron).length ===
          1
            ? transformSingleValueIntoSeries(
                result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalBoron)
              )
            : result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalBoron),
      },
      {
        name: 'zinc',
        type: 'scatter',
        symbolSize: 13,
        stack: 'Total',
        connectNulls: false,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: 'series',
        },
        data:
          result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalZinc).length === 1
            ? transformSingleValueIntoSeries(
                result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalZinc)
              )
            : result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labTotalZinc),
      },
      {
        name: 'nitrate nitrogen',
        type: 'scatter',
        symbolSize: 13,
        stack: 'Total',
        connectNulls: false,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: 'series',
        },
        data:
          result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labNitrateNitrogen)
            .length === 1
            ? transformSingleValueIntoSeries(
                result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labNitrateNitrogen)
              )
            : result.surfacewater.filter((sw: any) => sw.location === location).map((e: any) => e.labNitrateNitrogen),
      },
      {
        name: 'ammoniacal nitrogen',
        type: 'scatter',
        symbolSize: 13,
        stack: 'Total',
        connectNulls: false,
        smooth: true,
        lineStyle: {
          width: 0,
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
        },
        emphasis: {
          focus: 'series',
        },
        data:
          result.surfacewater
            .filter((sw: any) => sw.location === location)
            .map((e: any) => e.labTotalAmmoniacalNitrogen).length === 1
            ? transformSingleValueIntoSeries(
                result.surfacewater
                  .filter((sw: any) => sw.location === location)
                  .map((e: any) => e.labTotalAmmoniacalNitrogen)
              )
            : result.surfacewater
                .filter((sw: any) => sw.location === location)
                .map((e: any) => e.labTotalAmmoniacalNitrogen),
      },
    ];
  }
}

const transformSingleDateIntoSeries = (date: Array<any>) => [
  moment(date[0]).add(-1, 'day').format('DD/MM/YYYY'),
  ...date,
  moment(date[0]).add(1, 'day').format('DD/MM/YYYY'),
];
const transformSingleValueIntoSeries = (val: Array<any>) => [null, ...val, null];
