export class CommonConstants {
  public static LANDFILL_MANAGER = 'landfill_manager';
  public static IWI_MANAGER = 'iwi_manager';
  public static PUBLIC_USER = 'public';
  public static MENU_LANDFILL_MANAGER = 'menu_landfill_manager.json';
  public static MENU_IWI_MANAGER = 'menu_iwi_manager.json';
  public static MENU_PUBLIC_MANAGER = 'menu_public.json';
  public static ENERGY_LEVEL_COLORS = [
    { level: 'low', color: '#870086' },
    { level: 'medium', color: '#9a2e9a' },
    { level: 'high', color: '#c375c9' },
    { level: 'veryhigh', color: '#ad53aa' },
    { level: 'very high', color: '#ad53aa' },
  ];
  public static RING1INFO = ['media', 'nomedia'];
  public static RING2INFO = [1, 2, 3, 4, 5];
  public static RING3INFO = [1, 2];
  public static ANGLESTEPS = 30;
  public static ANGLEMOVESTEPS = 90;
  public static MARMATAKA_BLOCK1_COLOR = 'orange';
  public static MARMATAKA_BLOCK2_COLOR = 'green';
  public static MARMATAKA_BLOCK3_COLOR = 'red';
  public static MARMATAKA_BLOCK4_COLOR = 'gray';

  public static ICON_DIR = './assets/icons/';
  public static MARKER_ICONS = {
    Hau: 'marker-skyblue.png',
    Wai: 'marker-blue.png',
    Whenua: 'marker-green.png',
    Moana: 'marker-darkblue.png',
    Landfill: 'marker-orange.png',
  };

  public static ICONS_DATA = {
    ring1Info: [
      {
        id: 1,
        value: 20,
        observationType: 'media',
        path: 'image://./assets/icons/ring1-marker1.svg',
        size: [24, 28],
        name: 'Marker1',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [-4, -20],
        barWidth: '1px',
        color: '#000000',
        caption: 'Kaitiaki Multi', // RECHECK
      },
      {
        id: 2,
        value: 20,
        observationType: 'nomedia',
        path: 'image://./assets/icons/ring1-marker2.svg',
        size: [24, 28],
        name: 'Marker2',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [-4, -20],
        color: '#000000',
        caption: 'Kaitiaki Text', // RECHECK
      },
    ],

    ring2Info: [
      {
        id: 1,
        value: 120,
        path: 'image://./assets/icons/marker-skyblue.svg',
        size: [16, 28],
        name: 'Symbol1',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [4, -20],
        color: '#9bd6cc',
        caption: 'Hau',
      },
      {
        id: 2,
        value: 100,
        path: 'image://./assets/icons/marker-mediumblue.svg',
        size: [16, 28],
        name: 'Symbol3',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [7, -20],
        color: '#0d82bf',
        caption: 'Wai',
      },
      {
        id: 3,
        value: 80,
        path: 'image://./assets/icons/marker-darkblue.svg',
        size: [16, 28],
        name: 'Symbol4',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [10, -20],
        color: '#64a163',
        caption: 'Moana',
      },
      {
        id: 4,
        value: 60,
        path: 'image://./assets/icons/marker-green.svg',
        size: [16, 28],
        name: 'Symbol4',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 50,
        symbolOffset: [12, -20],
        color: '#9bd6cc',
        caption: 'Whenua',
      },
      {
        id: 5,
        value: 40,
        path: 'image://./assets/icons/marker-orange.svg',
        size: [16, 28],
        name: 'Symbol5',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: 40,
        symbolOffset: [-18, '-100%'],
        color: '#de7226',
        caption: 'Landfill',
      },
    ],
    ring3Info: [
      {
        id: 2,
        value: 150,
        path: 'image://./assets/icons/eel-recent-events.svg',
        size: [30, 24],
        name: 'eel',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: [22, 22],
        symbolOffset: [-11, -22],
        barWidth: '1px',
        color: '#000000',
        caption: 'Tuna',
      },
      {
        id: 1,
        value: 150,
        path: 'image://./assets/icons/shellfish-recent-events.svg',
        size: [30, 24],
        name: 'shell-fish',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: [22, 22],
        symbolOffset: [-16, -22],
        color: '#000000',
        caption: 'Kākahi',
      },
    ],
  };
  // public static MARMATAKA_RINGS =
  //   {
  //     ring1: [{ type: 'media', imageURL: 'green-ring-marker-with-data.png' },
  //     { type: 'text', imageURL: 'green-ring-marker-without-data.png' },
  //     { type: 'mātauranaga', imageURL: 'green-ring-marker-with-data.png' },
  //     { type: 'mauri', imageURL: 'green-ring-marker-without-data.png' },
  //     { type: 'kaitiaki', imageURL: 'green-ring-marker-with-data.png' }],

  //     ring2: [],
  //     ring3: []
  //   };
  public static MARMATAKA_RINGS = {
    ring1: [
      { type: 'media', imageURL: 'green-ring-marker-with-data.png' },
      { type: 'text', imageURL: 'green-ring-marker-without-data.png' },
    ],

    ring2: [
      { type: 'ring2type1', imageURL: 'green-ring-marker-with-data.png' },
      { type: 'ring2type2', imageURL: 'green-ring-marker-with-data.png' },
      { type: 'ring2type3', imageURL: 'green-ring-marker-with-data.png' },
      { type: 'ring2type4', imageURL: 'green-ring-marker-with-data.png' },
      { type: 'ring2type5', imageURL: 'green-ring-marker-with-data.png' },
    ],
    ring3: [],
  };
  public static DATEVIEW = 30;
  public static degArray = [
    -6, -18, -30, -42, -54, -66, -78, -90, -102, -114, -126, -138, -150, -162, -174, -186, -198, -210, -222, -234, -246,
    -258, -270, -282, -294, -306, -318, -330, -342, -354,
  ];
}
