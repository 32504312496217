<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div mat-dialog-actions align="end">
  <button *ngIf="!alertMode" mat-button (click)="onClose()">{{ cancelLabel }}</button>
  <button mat-raised-button color="primary" (click)="onConfirm()">{{ okLabel }}</button>
</div>
