import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartService } from '@app/data/services/chartService';
import { connect, getInstanceByDom } from 'echarts';
import LinearGradient from 'zrender/lib/graphic/LinearGradient';
declare const require: any; // DEMO IGNORE

@Component({
  selector: 'app-polar-bar-charts',
  templateUrl: './polar-bar-chart.component.html',
  styleUrls: ['./polar-bar-chart.component.scss'],
})
export class PolarBarChartComponent implements OnInit {
  count: any = [];
  weeks: any = [];
  // options = {
  //   color: ['#3398DB'],
  //   xAxis: [
  //     {
  //       type: 'category',
  //       data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //       axisTick: {
  //         alignWithLabel: true,
  //       },
  //     },
  //   ],
  //   yAxis: [
  //     {
  //       type: 'value',
  //     },
  //   ],
  // };
  options: any;
  constructor(private chartService: ChartService) {}
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.setOptions();
      const chartElement1 = document.getElementById('chart1');
      //const chartElement2 = document.getElementById('chart2');
      if (chartElement1 != null) {
        const chart1 = getInstanceByDom(chartElement1);
        //const chart2 = getInstanceByDom(chartElement2);
        if (chart1) {
          connect([chart1]);
        }
      }
    });
  }

  setOptions = () => {
    //getSurfaceWaterData
    let location = 'waiotapu_riverlodge';

    // let test =[
    //   {
    //     count: 3,
    //     startDate: "2021-01-21T18: 30: 00.000Z",
    //     endDate: "2021-01-28T18: 29: 59.000Z",
    //     Week: 1
    //   },
    //   {
    //     count: 1,
    //     startDate: "2021-01-28T18: 30: 00.000Z",
    //     endDate: "2021-02-04T18: 29: 59.000Z",
    //     Week: 2
    //   },
    //   {
    //     count: 2,
    //     startDate: "2021-02-04T18: 30: 00.000Z",
    //     endDate: "2021-02-11T18: 29: 59.000Z",
    //     Week: 3
    //   },
    //   {
    //     count: 4,
    //     startDate: "2021-02-11T18: 30: 00.000Z",
    //     endDate: "2021-02-18T18: 29: 59.000Z",
    //     Week: 4
    //   },
    //   {
    //     count: 5,
    //     startDate: "2021-02-18T18: 30: 00.000Z",
    //     endDate: "2021-02-25T18: 29: 59.000Z",
    //     Week: 5
    //   }
    // ];
  };
}
