import { Component } from '@angular/core';
import { WidgetComponentBase } from '../widget.class';

@Component({
  selector: 'app-widget-monitoring-information',
  templateUrl: './widget-monitoring-information.component.html',
  styleUrls: ['./widget-monitoring-information.component.scss'],
})
export class WidgetMonitoringInformationComponent extends WidgetComponentBase {
  timeScale = 1; //Days -1, Weeks - 2
}
