import { Injectable } from '@angular/core';
import { URLConstants } from '@app/core/constants/URLConstants';
import { Observable } from 'rxjs';
import { APIWithLoaderHelper } from '../helpers/apiWithLoaderHelper';
import { switchMap } from 'rxjs/operators';
import { LocationParamsService } from './locationParamsService';

@Injectable({
  providedIn: 'root',
})
export class LegacyDataService {
  constructor(private apiWithLoaderHelper: APIWithLoaderHelper, private locationParamsService: LocationParamsService) {}

  // URL Constants
  public static getMethaneCapturedDestroyedData = '/gas';
  public static getMethaneGenData = '/gas/generation';
  public static getWatertakeData = '/watertake';
  public static getTaiaoOveview = '/taiao-overview';
  public static getTaiaoScorecard = '/taiao-scorecard';

  // TECHNICAL-DEBT  (still used by filter-drawer-layout-component)
  public getSurfaceWaterData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getSurfaceWaterData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  // TECHNICAL-DEBT  (still used by filter-drawer-layout-component)
  public getGroundWaterData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getGroundWaterData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  public getMethaneData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(LegacyDataService.getMethaneCapturedDestroyedData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  public getMethaneGenData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(LegacyDataService.getMethaneGenData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  public getWatertakeData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(LegacyDataService.getWatertakeData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  public getTaiaoOverview(startDate: string, endDate: string, client: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(LegacyDataService.getTaiaoOveview).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate, client }, {}, false);
      })
    );
  }

  public getTaiaoScorecard(startDate: string, endDate: string, client: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(LegacyDataService.getTaiaoScorecard).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate, client }, {}, false);
      })
    );
  }

  public getWaiStationMonitoringData(startDate: string, endDate: string, location: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getWaiStationMonitoringData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(
          urlWithParams,
          { start: startDate, end: endDate, location },
          {},
          false
        );
      })
    );
  }
}
