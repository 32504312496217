import { Component } from '@angular/core';
import { MainLayoutComponentBase } from '../../../classes/main-layout-component.class';

@Component({
  selector: 'app-client-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class wastemanagement_MainLayoutComponent extends MainLayoutComponentBase {
  public onTypeChange() {}
}
