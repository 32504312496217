import { Component, Input, OnInit } from '@angular/core';
import { ChartService } from '@app/data/services/chartService';
import { BasicCartesianChartConfig, BasicChartDataSource } from '@app/data/interface/widget-config';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import * as moment from 'moment-timezone';
import { EChartsOption, LineSeriesOption, SeriesOption } from 'echarts';
import { FilterService } from '@app/data/services/filterService';
import { SitesService } from '@app/data/services/sitesService';

/**
 * I've really tried to use types in this file but the inheritence is making it difficult.
 */

@Component({
  selector: 'app-basic-cartesian-chart',
  templateUrl: './basic-cartesian-chart.component.html',
  styleUrls: ['./basic-cartesian-chart.component.scss'],
})
export class BasicCartesianChartComponent implements OnInit {
  @Input() config!: BasicCartesianChartConfig;
  @Input() startDateOverride?: string;
  @Input() endDateOverride?: string;
  chartOptions: EChartsOption | null = null;
  id: string = 'basic-cartesian-chart-x';
  chartTitle: string = '';
  chartSubtitle: string = '';
  domain: string = '';
  startDate: string = '';
  endDate: string = '';

  constructor(
    private chartService: ChartService,
    private filterService: FilterService,
    private sitesService: SitesService
  ) {}
  ngOnInit() {
    this.id = `basic-cartesian-chart-${this.config.dataConfig.widgetId}`;
    this.chartTitle = this.config.dataConfig.title;
    this.chartSubtitle = this.config.dataConfig.subtitle || '';
    this.domain = this.config.dataConfig.domain || '';

    // Filtering
    const filters = this.filterService.getFilters();
    this.startDate = this.startDateOverride ?? moment(filters.fromDate).format('YYYY-MM-DD');
    this.endDate = this.endDateOverride ?? moment(filters.toDate).format('YYYY-MM-DD');
    this.filterService.subscribe(this.handleFilterUpdate);
    this.sitesService.subscribe(this.handleSiteUpdate);

    // First load.
    this.setData();
  }

  handleSiteUpdate = ({ site }: any) => {
    if (site) {
      this.setData();
    }
  };

  handleFilterUpdate = ({ filters }: any) => {
    if (!filters.toDate || !filters.fromDate) return;
    if (this.startDateOverride) return;
    const newStartDate = moment(filters.fromDate).format('YYYY-MM-DD');
    const newEndDate = moment(filters.toDate).format('YYYY-MM-DD');
    if (newStartDate !== this.startDate || newEndDate !== this.endDate) {
      this.startDate = newStartDate;
      this.endDate = newEndDate;
      this.setData();
    }
  };

  setData() {
    const dateFormat = this.startDate === this.endDate ? 'HH:00' : 'DD/MM/yyyy';
    const newChartOptions: EChartsOption = this.config.echartsConfig;
    const xAxisData: any[] = [];
    let hasData = false;
    this.chartOptions = null;

    // Get the data
    forkJoin(
      this.config.dataConfig.dataSources.map((source: any) =>
        this.chartService
          .getEnvironmentalDataForSourceAndAnalyteByDate(
            source.environmentalDataSourceIdentifier,
            source.analyteIdentifier,
            this.startDate,
            this.endDate,
            this.startDate === this.endDate ? 'hour' : 'day'
          )
          .pipe(take(1))
      )
    ).subscribe({
      next: (queryResponses: any) => {
        // Sort out the axis.
        // We can't add it to the series yet because it might have missing data points
        // Add the x axis value
        queryResponses.forEach((seriesData: any, index: number) => {
          if (seriesData.data.length > 0) {
            hasData = true;
          }
          seriesData.data.forEach((dataPoint: any) => {
            if (!xAxisData.includes(dataPoint.date_time)) {
              xAxisData.push(dataPoint.date_time);
            }
          });
        });
        // Special case - add axis data either side to show we're not just getting one point.
        if (xAxisData.length === 1) {
          if (this.startDate === this.endDate) {
            xAxisData.push(
              moment(xAxisData[xAxisData.length - 1])
                .tz('UTC')
                .add(1, 'hour')
                .format('YYYY-MM-DDTHH:00:00.000\\Z')
            );
            xAxisData.unshift(moment(xAxisData[0]).add(-1, 'hour').tz('UTC').format('YYYY-MM-DDTHH:00:00\\Z'));
          } else {
            xAxisData.push(
              moment(xAxisData[xAxisData.length - 1])
                .tz('UTC')
                .add(1, 'day')
                .format('YYYY-MM-DDT00:00:00.000\\Z')
            );
            xAxisData.unshift(moment(xAxisData[0]).add(-1, 'day').tz('UTC').format('YYYY-MM-DDT00:00:00.000\\Z'));
          }
        }
        // If padding is specified
        if (this.config.dataConfig.addPadding === true) {
          if (this.startDate === this.endDate) {
            xAxisData.push(
              moment(xAxisData[xAxisData.length - 1])
                .tz('UTC')
                .add(1, 'hour')
                .format('YYYY-MM-DDTHH:00:00.000\\Z')
            );
            xAxisData.unshift(moment(xAxisData[0]).add(-1, 'hour').tz('UTC').format('YYYY-MM-DDTHH:00:00.000\\Z'));
          } else {
            xAxisData.push(
              moment(xAxisData[xAxisData.length - 1])
                .tz('UTC')
                .add(1, 'day')
                .format('YYYY-MM-DDT00:00:00.000\\Z')
            );
            xAxisData.unshift(moment(xAxisData[0]).add(-1, 'day').tz('UTC').format('YYYY-MM-DDT00:00:00.000\\Z'));
          }
        }
        if (!hasData) {
          this.chartOptions = {
            color: ['#6AB6B0'],
            title: {
              text: 'No data is available for the selected date range.',
            },
          };
        } else {
          // Set the data.
          xAxisData.sort((a: string, b: string) => (a > b ? 1 : -1));
          queryResponses.forEach((seriesData: any, index: number) => {
            const dataSource: BasicChartDataSource = this.config.dataConfig.dataSources[index];
            // Key the data by date_time
            const keyedData = seriesData.data.reduce(
              (accumulator: any, dataPoint: any) => ({
                [dataPoint.date_time]: dataPoint[dataSource.valueField].toPrecision(
                  seriesData.analytes[0].significant_figures
                ),
                ...accumulator,
              }),
              {}
            );
            // Special case - stacked graphs with a single datapoint
            // Add the data to the series
            (newChartOptions.series as SeriesOption[])[index].data = xAxisData.map(
              (dateTimeValue: string) =>
                keyedData[dateTimeValue] ||
                // The special case
                ((newChartOptions.series as SeriesOption[])[index].type === 'line' &&
                (newChartOptions.series as LineSeriesOption[])[index].stack === 'Total' &&
                seriesData.data.length < xAxisData.length
                  ? 0
                  : null)
            );
          });

          newChartOptions.xAxis = {
            ...(newChartOptions.xAxis || {}),
            type: 'category',
            data: xAxisData.map((date: string, index: number) =>
              this.config.dataConfig.addPadding && (index === 0 || index === xAxisData.length - 1)
                ? ''
                : moment(date).format(dateFormat)
            ),
          };

          this.chartOptions = newChartOptions;
        }
      },
      error: (err: any) => console.error(err),
    });
  }
}
