<app-widget-card-wrapper [title]="chartTitle">
  <div class="card-stretch text-center">
    <div
      [id]="id"
      echarts
      [options]="chartOptions"
      *ngIf="chartOptions"
      theme="macarons"
      style="max-height: 350px"
    ></div>
  </div>
</app-widget-card-wrapper>
