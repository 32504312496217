import { Component, OnInit } from '@angular/core';
import { default_MainLayoutComponent } from '../../default/main-layout/main-layout.component';

@Component({
  selector: 'app-client-main-layout',
  templateUrl: '../../default/main-layout/main-layout.component.html',
  styleUrls: ['../../default/main-layout/main-layout.component.scss'],
})
export class paepae_MainLayoutComponent extends default_MainLayoutComponent implements OnInit {
  onTypeChange(): void {
    this.logoSrc = `/assets/clients/paepae/${this.type}-logo.svg`;
    this.leftCornerClasses = [
      'curveCorner',
      'curveLeft',
      this.type === 'maramataka' ? 'curveIsMaramataka' : 'floatLogo',
    ];
  }
}
