import { Injectable } from '@angular/core';
import { SubscribeManager } from '@app/data/class/subscribe-manager.class';
import moment from 'moment-timezone';

export type DrawerSubscribeCallback = (data: any[]) => void;
export type DrawerUnsubscribe = () => void;
export interface IDrawerDataChange {
  granularity?: 'hour' | 'day' | 'month' | 'week' | 'year' | 'raw' | 'na';
  data: IDrawerData[];
}
export interface IDrawerData {
  title: string;
  domain: string;
  date: string;
  date_format?: string;
  source: 'maramataka' | 'environmental_data_source' | 'observation' | 'matauranga';
  source_data: any;
}

@Injectable({
  providedIn: 'root',
})
export class DrawerService extends SubscribeManager<IDrawerDataChange> {
  // This is going to be directly referenced since it's only used after the data is set

  public setDrawerData(data: IDrawerDataChange): void {
    this.pushChange(data);
  }

  public formatEnvironmentalDataForDrawer(
    environmentalData: any[],
    precision: string,
    displayFormat: string,
    analytes?: any[],
    environmentalDataSources?: any[]
  ): IDrawerData[] {
    if (analytes && analytes.length > 0) {
      // This is to deal with the maramataka wheel view of the world, which is not to provide analytes
      const analytesById = analytes.reduce(
        (accumulator: any, analyte: any) => ({ ...accumulator, [analyte.id]: analyte }),
        {}
      );
      environmentalData = environmentalData.map((dataPoint: any) => ({
        ...dataPoint,
        analyte: analytesById[dataPoint.analyte_id],
      }));
    }
    if (environmentalDataSources && environmentalDataSources.length > 0) {
      // This is to deal with the maramataka wheel view of the world, which is not to provide environmentalDataSources
      const environmentalDataSourcesById = environmentalDataSources.reduce(
        (accumulator: any, environmentalDataSource: any) => ({
          ...accumulator,
          [environmentalDataSource.id]: environmentalDataSource,
        }),
        {}
      );
      environmentalData = environmentalData.map((dataPoint: any) => ({
        ...dataPoint,
        environmentalDataSource: environmentalDataSourcesById[dataPoint.environmental_data_source_id],
      }));
    }
    // We don't actually want to display the analyte in the list.
    // We want to display the sources we have data for, and the date in the list.
    // And then when you click..the actual data yay!
    const drawerData = Object.values(
      environmentalData
        .map((dataPoint: any) => ({
          ...dataPoint,
          displayDate: moment(dataPoint.date_time).format(precision),
          startDate: moment(dataPoint.date_time).format('YYYY-MM-DD'),
          endDate: moment(dataPoint.date_time).format('YYYY-MM-DD'),
          orderField: `${moment(dataPoint.date_time).format(precision)}-${
            dataPoint.environmentalDataSource.human_readable_name
          }`,
        }))
        .reduce(
          (accumulator: any, dataPoint: any) => ({
            ...accumulator,
            [dataPoint.orderField]: {
              ...dataPoint,
              ...(accumulator[dataPoint.orderField] || {}),
              analyte_id: undefined,
              analyte: undefined,
              // Roll up the readings
              data: [
                ...(accumulator[dataPoint.orderField]?.data || []),
                {
                  avg: dataPoint.avg,
                  min: dataPoint.min,
                  max: dataPoint.max,
                  sum: dataPoint.sum,
                  stddev: dataPoint.stddev,
                  stddev_pop: dataPoint.stddev_pop,
                  stddev_samp: dataPoint.stddev_samp,
                  variance: dataPoint.variance,
                  var_pop: dataPoint.var_pop,
                  var_samp: dataPoint.var_samp,
                  percentile_25: dataPoint.percentile_25,
                  percentile_50: dataPoint.percentile_50,
                  percentile_75: dataPoint.percentile_75,
                  percentile_90: dataPoint.percentile_90,
                  percentile_95: dataPoint.percentile_95,
                  percentile_99: dataPoint.percentile_99,
                  mode: dataPoint.mode,
                  count: dataPoint.count,
                  analyte: dataPoint.analyte,
                },
              ],
            },
          }),
          {}
        )
    );
    drawerData.sort((a: any, b: any) => (a.orderField < b.orderField ? -1 : 1));
    return drawerData.map((timeEntry: any) => ({
      title: timeEntry.environmentalDataSource.human_readable_name,
      domain: timeEntry.environmentalDataSource.domain,
      date: timeEntry.displayDate,
      date_format: displayFormat,
      source: 'environmental_data_source',
      source_data: timeEntry,
    }));
  }
}
