<div [ngClass]="this.clientSetup.loginStyle || 'login-container-stream'">
  <div class="login-box">
    <div class="container">
      <div class="col-md-6 mx-auto loginLogo">
        <img
          [src]="clientSetup.loginLogo || '/assets/the-stream-logo.png'"
          alt="Stream Login"
          [class]="clientSetup.loginLogoStyle || 'stream-logo'"
        />
      </div>
      <div class="card col-md-6 mx-auto">
        <div class="card-body">
          <form
            [formGroup]="loginForm"
            (ngSubmit)="login()"
            class="pt-4 px-3"
            *ngIf="!mustChangePassword && !wantsToResetPassword && !resettingPassword"
          >
            <div class="row mb-2">
              <mat-label class="col pt-2">{{ 'Sign in with The Stream' | translate }}</mat-label>
            </div>

            <div class="row">
              <div class="col-md-7">
                <div class="row mb-2">
                  <mat-form-field
                    appearance="outline"
                    class="col-md-12"
                    [ngClass]="{
                      'has-success': loginForm.controls['username'].valid
                    }"
                  >
                    <input
                      matInput
                      type="email"
                      placeholder="{{ 'Email Address' | translate }}"
                      formControlName="username"
                    />
                  </mat-form-field>
                  <div class="col-md-12 offset-3">
                    <mat-error
                      *ngIf="
                        loginForm.controls['username'].hasError('required') && loginForm.controls['username'].touched
                      "
                      class="text-danger support-text error-name"
                    >
                      {{ 'Username is required' | translate }}
                    </mat-error>
                  </div>
                </div>

                <div class="row mb-2">
                  <mat-form-field
                    appearance="outline"
                    class="col-md-12"
                    [ngClass]="{
                      'has-success':
                        (loginForm.controls['password'].valid && loginForm.controls['password'].touched) ||
                        loginForm.controls['password'].dirty
                    }"
                  >
                    <input
                      matInput
                      type="password"
                      placeholder="{{ 'Password' | translate }}"
                      formControlName="password"
                    />
                  </mat-form-field>
                  <div class="col-md-12 offset-3">
                    <mat-error
                      *ngIf="
                        loginForm.controls['password'].hasError('required') && loginForm.controls['password'].touched
                      "
                      class="text-danger support-text error-name"
                    >
                      {{ 'Password is required' | translate }}
                    </mat-error>
                  </div>
                </div>

                <div class="row mb-4">
                  <div id="remember" (click)="wantsToResetPassword = true" class="col-md-9 offset-3 text-end">
                    <a (click)="wantsToResetPassword = true" class="forgotPass">Forgotten your password?</a>
                  </div>
                </div>

                <div class="row loginBtn">
                  <div class="col-md-9 offset-3 text-end">
                    <button class="btn btn-info" type="submit" [disabled]="!loginForm.valid">
                      {{ 'Log In' | translate }} &nbsp;&nbsp;&nbsp;
                      <i class="fa fa-arrow-right"></i>
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-md-1">
                <div class="vl"></div>
              </div>

              <div class="col-md-4">
                <div class="row pt-2" (click)="federatedLogin('Microsoft')">
                  <!-- There are rules around displaying this-->
                  <!-- https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps-->
                  <img src="/assets/img/microsoft-signin.png" class="federated-button img-fluid" />
                </div>

                <div class="row pt-3" (click)="federatedLogin('Google')">
                  <!-- There are rules around displaying this-->
                  <!-- https://developers.google.com/identity/branding-guidelines-->
                  <img src="/assets/img/google-signin-light.png" class="federated-button img-fluid" />
                </div>
              </div>
            </div>
          </form>
          <form
            [formGroup]="startForgotPasswordForm"
            (ngSubmit)="startForgotPassword()"
            class="pt-4 px-3"
            *ngIf="wantsToResetPassword"
          >
            <div class="row mb-2">
              <mat-label class="col-md-3 pt-2">{{ 'Login/Username email:' | translate }}</mat-label>
              <mat-form-field
                appearance="outline"
                class="col-md-9"
                [ngClass]="{
                  'has-success': startForgotPasswordForm.controls['email'].valid
                }"
              >
                <input matInput type="email" placeholder="{{ 'Email Address' | translate }}" formControlName="email" />
              </mat-form-field>
              <div class="col-md-9 offset-3">
                <mat-error
                  *ngIf="
                    startForgotPasswordForm.controls['email'].hasError('required') &&
                    startForgotPasswordForm.controls['email'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{ 'Email is required' | translate }}
                </mat-error>
              </div>
            </div>

            <div class="row mb-4">
              <div
                id="cancelStartForgotPassword"
                (click)="wantsToResetPassword = false"
                class="col-md-9 offset-3 text-end"
              >
                <a class="forgotPass">Cancel</a>
              </div>
            </div>

            <div class="row loginBtn">
              <div class="col-md-9 offset-3 text-end">
                <button class="btn btn-info" type="submit" [disabled]="!startForgotPasswordForm.valid">
                  {{ 'Reset Password' | translate }} &nbsp;&nbsp;&nbsp;
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </form>
          <form
            [formGroup]="setNewPasswordForm"
            (ngSubmit)="setNewPassword()"
            class="pt-4 px-3"
            *ngIf="mustChangePassword || resettingPassword"
          >
            <div class="row mb-2" *ngIf="resettingPassword">
              <mat-label class="required col-md-3 pt-2">{{ 'Code' | translate }}</mat-label>
              <mat-form-field
                appearance="outline"
                class="col-md-9"
                [ngClass]="{
                  'has-success':
                    (setNewPasswordForm.controls['code'].valid && setNewPasswordForm.controls['code'].touched) ||
                    setNewPasswordForm.controls['code'].dirty
                }"
              >
                <input matInput type="code" formControlName="code" />
              </mat-form-field>
              <div class="col-md-9 offset-3">
                <mat-error
                  *ngIf="
                    resettingPassword &&
                    !setNewPasswordForm.controls['code'].value &&
                    setNewPasswordForm.controls['newPassword'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{ 'A code is required to reset your password.  Please check your email for the code.' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="resettingPassword && !setNewPasswordForm.controls['code'].valid"
                  class="text-danger support-text error-name"
                >
                  {{ 'Please enter the six numbers sent to your email address for the code.' | translate }}
                </mat-error>
              </div>
            </div>
            <div class="row mb-2">
              <mat-label class="required col-md-3 pt-2">{{ 'New password' | translate }}</mat-label>
              <mat-form-field
                appearance="outline"
                class="col-md-9"
                [ngClass]="{
                  'has-success':
                    (setNewPasswordForm.controls['newPassword'].valid &&
                      setNewPasswordForm.controls['newPassword'].touched) ||
                    setNewPasswordForm.controls['newPassword'].dirty
                }"
              >
                <input
                  matInput
                  type="password"
                  placeholder="{{ 'New password' | translate }}"
                  formControlName="newPassword"
                />
              </mat-form-field>
              <div class="col-md-9 offset-3">
                <mat-error
                  *ngIf="
                    setNewPasswordForm.controls['newPassword'].hasError('required') &&
                    setNewPasswordForm.controls['newPassword'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{ 'New password is required' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    setNewPasswordForm.controls['newPassword'].value &&
                    !passwordStrength.test(setNewPasswordForm.controls['newPassword'].value) &&
                    setNewPasswordForm.controls['repeatNewPassword'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{
                    'Password must be at least eight (8) characters long and contain numbers, uppercase and lowercase letters and at least one special character.'
                      | translate
                  }}
                </mat-error>
              </div>
            </div>
            <div class="row mb-2">
              <mat-label class="required col-md-3 pt-2">{{ 'Repeat new password' | translate }}</mat-label>
              <mat-form-field
                appearance="outline"
                class="col-md-9"
                [ngClass]="{
                  'has-success':
                    (setNewPasswordForm.controls['repeatNewPassword'].valid &&
                      setNewPasswordForm.controls['repeatNewPassword'].touched) ||
                    setNewPasswordForm.controls['repeatNewPassword'].dirty
                }"
              >
                <input
                  matInput
                  type="password"
                  placeholder="{{ 'Repeat new password' | translate }}"
                  formControlName="repeatNewPassword"
                />
              </mat-form-field>
              <div class="col-md-9 offset-3">
                <mat-error
                  *ngIf="
                    setNewPasswordForm.controls['repeatNewPassword'].hasError('required') &&
                    setNewPasswordForm.controls['repeatNewPassword'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{ 'Confirm new password' | translate }}
                </mat-error>
                <mat-error
                  *ngIf="
                    setNewPasswordForm.controls['repeatNewPassword'] &&
                    setNewPasswordForm.controls['repeatNewPassword'].value !==
                      setNewPasswordForm.controls['newPassword'].value &&
                    setNewPasswordForm.controls['repeatNewPassword'].touched
                  "
                  class="text-danger support-text error-name"
                >
                  {{ 'Passwords do not match' | translate }}
                </mat-error>
              </div>
            </div>
            <div class="row mb-4">
              <div
                id="cancelStartForgotPassword"
                (click)="mustChangePassword = false; resettingPassword = false; wantsToResetPassword = false"
                class="col-md-9 offset-3 text-end"
              >
                <a class="forgotPass">Cancel</a>
              </div>
            </div>
            <div class="row loginBtn">
              <div class="col-md-9 offset-3 text-end">
                <button
                  class="btn btn-info"
                  type="submit"
                  [disabled]="
                    setNewPasswordForm.controls['repeatNewPassword'].value !==
                      setNewPasswordForm.controls['newPassword'].value || !setNewPasswordForm.valid
                  "
                >
                  {{ 'Set New Password' | translate }} &nbsp;&nbsp;&nbsp;
                  <i class="fa fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
