import { Component, OnInit } from '@angular/core';
import { MainLayoutComponentBase } from '../../../classes/main-layout-component.class';
import { environment } from '@env/environment';

@Component({
  selector: 'app-client-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class default_MainLayoutComponent extends MainLayoutComponentBase implements OnInit {
  public leftCornerClasses: string[] = ['curveCorner', 'curveLeft', 'curveIsMaramataka'];
  public logoSrc = `/assets/clients/${environment.forceBrand || window.location.host.split(/\.|:/g)[0]}/logo.png`;

  onTypeChange(): void {}

  async onLoadMenuItems(): Promise<void> {
    const user = await this.authService.getLoggedInUser().toPromise();
    const sites = this.menuJSON.find((x: any) => x.name === 'Landfill sites') as any;
    sites.name = 'Sites';

    sites.children =
      user.sites?.map((site) => ({
        site,
        state: '',
        name: site.name,
        onClick: () => this.sitesService.setSelectedSite(site),
      })) || [];

    this.sitesService.getSelectedSite().subscribe((site) => {
      if (Array.isArray(sites.children)) {
        sites.children.forEach((x: any) => {
          x.state = x.site.id === site?.id ? 'active' : '';
        });
      }
    });

    const explore = this.menuJSON.find((x: any) => x.name === 'Explore') as any;

    explore.children = explore.children.map((x: any) => ({ ...x, url: '#' }));

    const information = this.menuJSON.find((x: any) => x.name === 'Information') as any;

    information.children = information.children.filter((x: any) => x.name !== 'Landfill Management Plan');
  }
}
