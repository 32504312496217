import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLConstants } from '@app/core/constants/URLConstants';
import * as moment from 'moment-timezone';
import { IMaramatakaDay, IMaramatakaWeek, IMaramatakaMonth } from '@app/data/interface/maramataka';
import { environment } from '@env/environment';
import { Observable, forkJoin } from 'rxjs';
import { APIWithLoaderHelper } from '../helpers/apiWithLoaderHelper';
import { mashMaramatakaDayData, mashMaramatakaWeekData, mashMaramatakaMonthData } from '@app/data/helpers/dataHelpers';
import { switchMap, take } from 'rxjs/operators';
import { LocationParamsService } from './locationParamsService';
import { LoaderService } from './loader.services';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  constructor(
    private http: HttpClient,
    private apiWithLoaderHelper: APIWithLoaderHelper,
    private locationParamsService: LocationParamsService,
    private loaderService: LoaderService
  ) {}

  public getMaramatakaDataDays(startDate: string, endDate: string, maramataka: any): Observable<IMaramatakaDay[]> {
    if (!maramataka) return new Observable<IMaramatakaDay[]>((observer) => observer.next([]));
    const utcStartDateTime = moment.tz(`${startDate} 00:00:00`, 'Pacific/Auckland').utc().toISOString();
    const utcEndDateTime = moment.tz(`${endDate} 23:59:59`, 'Pacific/Auckland').utc().toISOString();
    return new Observable<IMaramatakaDay[]>((observer) => {
      forkJoin([
        this.getMaramatakaDays(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getMatauranga(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getAstronomicalAndMeteorologicalEvents(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getEnvironmentalDataForSiteByDate(startDate, endDate, 'hour').pipe(take(1)),
        this.getObservationData(startDate, endDate).pipe(take(1)),
      ]).subscribe({
        next: ([
          maramatakaDays,
          matauranga,
          astrologicalAndMeteorologicalEvents,
          environmentalData,
          observations,
        ]: any) => {
          const data = mashMaramatakaDayData(
            maramataka,
            maramatakaDays,
            matauranga,
            astrologicalAndMeteorologicalEvents,
            environmentalData,
            observations
          );
          observer.next(data);
        },
        error: (err: any) => console.error(err),
        complete: () => {
          this.loaderService.hide();
          observer.complete();
        },
      });
    });
  }

  public getMaramataka(): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getMaramataka).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.get<any>(urlWithParams);
      })
    );
  }

  public getMaramatakaDays(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaDays, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMaramatakaWeeks(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaWeeks, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMaramatakaMonths(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaMonths, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMaramatakaStars(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaStars, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMaramatakaMonthWeeks(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaMonthWeeks, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMatauranga(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaMatauranga, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getAstronomicalAndMeteorologicalEvents(startDateTime: string, endDateTime: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getMaramatakaSiteAstronomicalMeteorologicalEvents, { startDateTime, endDateTime })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getWeatherData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getHistoricalAndForecastedData, { startDate, endDate })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get(urlWithParams);
        })
      );
  }

  public getMarmatakaDataWeek(startDate: string, endDate: string, maramataka: any): Observable<IMaramatakaWeek[]> {
    if (!maramataka) return new Observable<IMaramatakaWeek[]>((observer) => observer.next([]));
    const utcStartDateTime = moment.tz(`${startDate} 00:00:00`, 'Pacific/Auckland').utc().toISOString();
    const utcEndDateTime = moment.tz(`${endDate} 23:59:59`, 'Pacific/Auckland').utc().toISOString();
    return new Observable<IMaramatakaWeek[]>((observer) => {
      forkJoin([
        this.getMaramatakaWeeks(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getMatauranga(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getEnvironmentalDataForSiteByDate(startDate, endDate, 'day').pipe(take(1)),
        this.getObservationData(startDate, endDate).pipe(take(1)),
        this.getWeatherData(startDate, endDate).pipe(take(1)),
      ]).subscribe({
        next: ([maramatakaDays, matauranga, environmentalData, observations, weather]: any) => {
          const data = mashMaramatakaWeekData(
            maramataka,
            maramatakaDays,
            matauranga,
            environmentalData,
            observations,
            weather
          );
          observer.next(data);
        },
        error: (err: any) => console.error(err),
        complete: () => {
          this.loaderService.hide();
          observer.complete();
        },
      });
    });
  }

  public getMarmatakaDataMonth(startDate: string, endDate: string, maramataka: any): Observable<IMaramatakaMonth[]> {
    if (!maramataka) return new Observable<IMaramatakaMonth[]>((observer) => observer.next([]));
    const utcStartDateTime = moment.tz(`${startDate} 00:00:00`, 'Pacific/Auckland').utc().toISOString();
    const utcEndDateTime = moment.tz(`${endDate} 23:59:59`, 'Pacific/Auckland').utc().toISOString();
    return new Observable<IMaramatakaMonth[]>((observer) => {
      forkJoin([
        this.getMaramatakaMonths(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getMaramatakaStars(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getMaramatakaMonthWeeks(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getMatauranga(utcStartDateTime, utcEndDateTime).pipe(take(1)),
        this.getEnvironmentalDataForSiteByDate(startDate, endDate, 'day').pipe(take(1)),
        this.getObservationData(startDate, endDate).pipe(take(1)),
      ]).subscribe({
        next: ([
          maramatakaDays,
          maramatakaStars,
          maramatakaMonthWeeks,
          matauranga,
          environmentalData,
          observations,
        ]: any) => {
          const data = mashMaramatakaMonthData(
            maramataka,
            maramatakaDays,
            maramatakaStars,
            maramatakaMonthWeeks,
            matauranga,
            environmentalData,
            observations
          );
          observer.next(data);
        },
        error: (err: any) => console.error(err),
        complete: () => {
          this.loaderService.hide();
          observer.complete();
        },
      });
    });
  }

  // TECHNICAL-DEBT
  public getMarmataEventData(events: any[]): Observable<{ data: any }> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getMaramatakaEventData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<{ data: any }>(urlWithParams, { events }, {}, false);
      })
    );
  }

  public getEnvironmentalDataForSiteByDate(startDate: string, endDate: string, granularity?: string): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getEnvironmentalDataForSiteByDate, {
        environmental_data_source_id: 'all',
        analyte_id: 'all',
        granularity_override: granularity,
        startDate,
        endDate,
      })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get<any>(urlWithParams);
        })
      );
  }

  public getEnvironmentalDataForSourceByDate(
    environmentalDataSourceIdentifier: string,
    startDate: string,
    endDate: string,
    granularity?: string
  ): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getEnvironmentalDataForSiteByDate, {
        environmental_data_source_id: encodeURIComponent(environmentalDataSourceIdentifier),
        analyte_id: 'all',
        granularity_override: granularity,
        startDate,
        endDate,
      })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get<any>(urlWithParams);
        })
      );
  }
  public getEnvironmentalDataForSourceAndAnalyteByDate(
    environmentalDataSourceIdentifier: string,
    analyteIdentifier: string,
    startDate: string,
    endDate: string,
    granularity?: string
  ): Observable<any> {
    return this.locationParamsService
      .getUrlWithParams(URLConstants.getEnvironmentalDataForSiteByDate, {
        environmental_data_source_id: encodeURIComponent(environmentalDataSourceIdentifier),
        analyte_id: analyteIdentifier,
        granularity_override: granularity,
        startDate,
        endDate,
      })
      .pipe(
        switchMap((urlWithParams) => {
          return this.apiWithLoaderHelper.get<any>(urlWithParams);
        })
      );
  }

  // TECHNICAL-DEBT  (still used by filter-drawer-layout-component)
  public getSurfaceWaterData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getSurfaceWaterData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  // TECHNICAL-DEBT (still used by filter-drawer-layout-component)
  public getGroundWaterData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getGroundWaterData).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  public getObservationData(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getSiteObservations, { startDate, endDate }).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.get(urlWithParams);
      })
    );
  }

  // TECHNICAL-DEBT
  public getWeeklyOpenChartData(startDate: any): Observable<any> {
    // start date is the unix of passed param, or hardcoded for 20/05/2022
    const unixStartDate = startDate ? new Date(`${startDate} 00:00:00`).getTime() / 1000 : 1652976000;

    // end date is unix of current date
    const unixEndDate = Math.floor(Date.now() / 1000);

    return this.http.get(
      `https://history.openweathermap.org/data/2.5/history/city?lat=33.44&lon=-94.04&type=hour&start=${unixStartDate}&end=${unixEndDate}&appid=${environment.weatherAPI}`
    );
  }

  // TECHNICAL-DEBT
  public getLineChartData(): Observable<any> {
    return this.http.get(`${environment.apiBaseURL + URLConstants.getLineChartData}`);
  }

  public getMarmatakaRecentEvents(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getMarmatakaEvents).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }

  // TECHNICAL-DEBT
  public getHistoricalWeatherData(startDate: string, endDate: string, location: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getHistoricalWeather).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(
          urlWithParams,
          { start: startDate, end: endDate, location },
          {},
          false
        );
      })
    );
  }

  // TECHNICAL-DEBT
  public getForecastedWeatherData(lat: number, lon: number): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getForecastedWeather).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { lat, lon }, {}, false);
      })
    );
  }

  // TECHNICAL-DEBT
  public getMarmatakaMoonPhases(startDate: string, endDate: string): Observable<any> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getMarmatakaMoonPhases).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any>(urlWithParams, { startDate, endDate }, {}, false);
      })
    );
  }
}
