import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonConstants } from '@app/core/constants/commonConstants';
import { ChartService } from '@app/data/services/chartService';
import { DrawerService, IDrawerData } from '@app/data/services/drawerService';
import { connect, getInstanceByDom } from 'echarts';
import { of, Subject, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import moment from 'moment';

@Component({
  selector: 'app-sign-post-charts',
  templateUrl: './sign-post-chart.component.html',
  styleUrls: ['./sign-post-chart.component.scss'],
})
export class SignPostChartComponent implements OnInit, OnChanges {
  count: any = [];
  weeks: any = [];
  arrSeries: any = [];
  arrDates: any = [];
  options: any;
  chartDate: any;
  events: { date: string; id: number; type: string }[] = [];

  constructor(private chartService: ChartService, private drawerService: DrawerService) {}
  @Input() resetFormSubject: Subject<number> = new Subject<number>();
  @Input() timeScale?: number;
  @Input() toDate: string = '';
  ngOnInit() {
    this.resetFormSubject.subscribe((response) => {
      if (response) {
        this.arrSeries = [];
        this.timeScale = response;
        this.loadChartData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['toDate']) {
      this.loadChartData();
    }
  }

  onClick(e: any) {
    const selectedDate = moment(e.name, 'DD/MM/YYYY').format('YYYY-MM-DD');
    const selectedEvents = this.events.reduce((accumulator, event) => {
      if (selectedDate === event.date && /^observation|matauranga$/.test(event.type)) {
        accumulator.push({ id: event.id, type: event.type });
      }

      return accumulator;
    }, [] as any[]);

    // OK so obs and mātauranga can be gotten from the old data.
    // However, we need to do a full rewrite of this widget.
    // TODO - Rewrite this widget.
    // We're going to use the data for the graph for the maramataka and obs.
    // But for the rest, we're going to get the environmental data instead.
    // Also there's a bug in the old data - it's assuming data is UTC, which it's not.
    const selectedDateObject = moment(selectedDate);
    forkJoin([
      // If no old skool data, just return an empty array
      selectedEvents.length > 0
        ? this.chartService.getMarmataEventData(selectedEvents).pipe(take(1))
        : of({ data: [] }),
      this.chartService
        .getEnvironmentalDataForSiteByDate(
          selectedDateObject.format('YYYY-MM-DD'),
          selectedDateObject.format('YYYY-MM-DD'),
          'hour'
        )
        .pipe(take(1)),
    ]).subscribe(([legacyAPIResult, environmentalDataResult]) => {
      const drawerDataTemplate: IDrawerData = {
        title: '',
        domain: '',
        date: '',
        date_format: 'dd/MM/yyyy',
        source: 'maramataka',
        source_data: {},
      };
      const drawerData: IDrawerData[] = (legacyAPIResult as any).data
        .map((datapoint: any) => {
          if (datapoint.type === 'observation') {
            return {
              ...drawerDataTemplate,
              title: datapoint.location,
              domain: 'Observation',
              date: datapoint.date,
              source: 'observation',
              source_data: {
                ...datapoint,
                lattitude: datapoint.lat,
                latitude: datapoint.lat,
                longitude: datapoint.lng,
              },
            };
          }
          if (datapoint.type === 'matauranga') {
            return {
              ...drawerDataTemplate,
              title: `${datapoint.element_reo} Mātauranga`,
              domain: 'Mātauranga',
              date: datapoint.date,
              source: 'matauranga',
              source_data: {
                title_reo_maori: datapoint.element_reo,
                title_english: datapoint.element_eng,
                event_type_reo_maori: datapoint.type_reo,
                event_type_english: datapoint.type_eng,
                about_reo_maori: datapoint.text_reo,
                about_english: datapoint.text_eng,
                description_reo_maori: datapoint.description_reo,
                description_english: datapoint.description_eng,
              },
            };
          }
          return null;
        })
        .filter((datapoint: IDrawerData | null) => datapoint !== null);

      // Environmental data
      this.drawerService
        .formatEnvironmentalDataForDrawer(
          environmentalDataResult.data,
          'YYYY-MM-DD HH:00:00',
          'dd/MM/yyyy hh:mma',
          environmentalDataResult.analytes,
          environmentalDataResult.environmentalDataSources
        )
        .forEach((datapoint: IDrawerData) => drawerData.push(datapoint));
      this.drawerService.setDrawerData({ data: drawerData, granularity: 'hour' });
    });
  }

  setOptions = () => {
    this.options = {
      color: ['#3398DB'],
      style: {
        border: '1px solid red',
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none', //none | line | shadow
        },
        formatter: function (params: any) {
          var retData = '';
          for (var i = 0, l = params.length; i < l; i++) {
            if (params[i].seriesName == 'SECOND' && params[i].data.tooltipData) {
              // check if tooltipData is there or empty
              retData += `<span style="color:#ffffff">${params[i].data.tooltipData}</span><br/>`;
            }
          }
          return retData;
        },
      },
      grid: {
        left: '4%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          color: '#000000',
          type: 'category',
          data: this.arrDates,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: {
        name: 'Tohu',
        splitLine: { show: false },
        axisTick: { show: false },
        axisLine: { show: true },
        axisLabel: { show: false },
        max: 180,
      },
      series: this.arrSeries,
    };
    // });
  };

  loadChartData = () => {
    if (!this.toDate) {
      return;
    }

    this.arrSeries = [];
    this.arrDates = [];
    // Query from the end of the range - 1 month as showing a whole year
    // is too crowded
    const d = new Date(this.toDate);
    d.setMonth(d.getMonth() - 1);
    const fromDate = d.toISOString().slice(0, 10);
    forkJoin([
      this.chartService.getMarmatakaRecentEvents(fromDate, this.toDate).pipe(take(1)),
      this.chartService.getEnvironmentalDataForSiteByDate(fromDate, this.toDate, 'day').pipe(take(1)),
    ]).subscribe(([legacyAPIResult, environmentalDataResult]) => {
      /**
       * OVERWRITE environmental data
       */
      // For now, we're going to overwrite data where needed from the environmental API.
      // We do this because seriously - they just don't match up!!
      const environmentalDataSourcesById = environmentalDataResult.environmentalDataSources.reduce(
        (accumulator: any, source: any) => ({
          ...accumulator,
          [source.id]: source,
        }),
        {}
      );
      const environmentalDataDomainByDate = environmentalDataResult.data.reduce(
        (accumulator: any, datapoint: any) => ({
          ...accumulator,
          [moment(datapoint.date_time).format('YYYY-MM-DD')]: [
            ...(accumulator[moment(datapoint.date_time).format('YYYY-MM-DD')] || []),
            environmentalDataSourcesById[datapoint.environmental_data_source_id].domain,
          ],
        }),
        {}
      );
      legacyAPIResult.ring2 = legacyAPIResult.dates.map((date: string) => {
        const template = { icons: [] as number[] };
        if (environmentalDataDomainByDate[date]?.includes('Hau')) {
          template.icons.push(1);
        }
        if (environmentalDataDomainByDate[date]?.includes('Wai')) {
          template.icons.push(2);
        }
        if (environmentalDataDomainByDate[date]?.includes('Moana')) {
          template.icons.push(3);
        }
        if (environmentalDataDomainByDate[date]?.includes('Whenua')) {
          template.icons.push(4);
        }
        return template;
      });
      legacyAPIResult.events = legacyAPIResult.events.filter((event: any) =>
        /^observation|matauranga$/.test(event.type)
      );
      environmentalDataResult.data.forEach((datapoint: any) =>
        legacyAPIResult.events.push({
          date: moment(datapoint.date_time).format('YYYY-MM-DD'),
          id: datapoint.id,
          type: environmentalDataSourcesById[datapoint.environmental_data_source_id].domain,
        })
      );

      /** START OLD CODE - TO BE REPLACED */

      this.events = legacyAPIResult.events;
      this.arrDates = legacyAPIResult.dates.map((date: any) => moment(date).format('DD/MM/YYYY'));
      //Load Ring3 data
      this.loadRing3Data(legacyAPIResult);
      //Load Ring1 data
      this.loadRing1Data(legacyAPIResult);
      /** END OLD CODE - TO BE REPLACED */

      this.loadRing2Data(legacyAPIResult);

      if (this.timeScale !== 0) {
        // alert('refresh')
        setTimeout(() => {
          this.setOptions();
          const chartElement1 = document.getElementById('chart1');
          if (chartElement1 != null) {
            const chart1 = getInstanceByDom(chartElement1);
            if (chart1) {
              connect([chart1]);
            }
          }
        });
      }
    });
  };

  loadRing1Data = (data: any) => {
    let ring1Data = data.ring1;
    let heightsData: any = [];
    let resultDataHeights: any;
    let resultDataIcons: any = [];
    // data: [100, 100, 0, 0, 0],
    CommonConstants.RING1INFO.forEach((innerItem: any, i: any) => {
      let iconData: any = CommonConstants.ICONS_DATA.ring1Info.filter((value: any) => {
        return value.observationType === innerItem;
      });
      resultDataHeights = [];
      resultDataIcons = [];

      ring1Data.forEach((value: any, index: number) => {
        var keys = Object.keys(value);
        if (keys.length > 1) {
          keys.forEach((innerVal: any) => {
            if (innerVal === innerItem) {
              if (index !== 1 && value[innerVal] > 0) {
                resultDataHeights.push(iconData.length > 0 ? iconData[0].value : 0);
                resultDataIcons.push({
                  value: iconData.length > 0 ? iconData[0].value : 0,
                  symbol: iconData[0].path,
                  symbolSize: iconData[0].size,
                  tooltipData: iconData[0].caption,
                  symbolOffset: iconData[0].symbolOffset,
                });
              } else {
                resultDataHeights.push(0);
                resultDataIcons.push({
                  value: 0,
                  symbol: '',
                  symbolSize: '',
                  tooltipData: '',
                });
              }
            }
          });
        }
      });

      this.arrSeries.push({
        name: iconData[0].name,
        type: 'bar',
        barWidth: '2px',
        color: iconData[0].color,
        barGap: '100%',
        //symbol: 'image://./images/line.png',
        itemStyle: {
          // opacity: 0.5
        },
        data: resultDataHeights,
        z: -10,
      });

      this.arrSeries.push({
        name: iconData[0].name,
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        symbolSize: iconData[0].value,
        symbolOffset: iconData[0].symbolOffset,
        data: resultDataIcons,
        z: 10,
      });
    });
  };

  loadRing2Data = (data: any) => {
    //let ring1Data = data.ring1;
    let ring2Data = data.ring2;
    //let heightsData: any = [];
    let resultDataHeights: any;
    let resultDataIcons: any = [];
    // data: [100, 100, 0, 0, 0],
    CommonConstants.RING2INFO.forEach((innerItem: any, i: any) => {
      let iconData: any = CommonConstants.ICONS_DATA.ring2Info.filter((value: any) => {
        return value.id === innerItem;
      });
      resultDataHeights = [];
      resultDataIcons = [];

      ring2Data.forEach((item: any, k: any) => {
        if (k !== 0 && item.icons.indexOf(innerItem) !== -1) {
          let icons = item.icons[innerItem];
          resultDataHeights.push(icons !== 0 ? iconData[0].value : 0);
          resultDataIcons.push({
            value: icons !== 0 ? iconData[0].value : 0,
            symbol: iconData[0].path,
            symbolSize: [22, 40],
            symbolOffset: iconData[0].symbolOffset,
            tooltipData: iconData[0].caption,
          });
        } else {
          resultDataHeights.push(0);
          resultDataIcons.push({
            value: 0,
            symbol: '',
            symbolSize: '',
            tooltipData: '',
          });
        }
      });
      this.arrSeries.push({
        name: 'FIRST',
        type: 'bar',
        barWidth: '2px',
        color: iconData[0].color,
        barGap: '100%',
        //symbol: 'image://./images/line.png',
        itemStyle: {
          // opacity: 0.5
        },
        data: resultDataHeights,
        z: -10,
      });
      this.arrSeries.push({
        name: 'SECOND',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        data: resultDataIcons,
        z: 10,
      });
    });
  };

  loadRing3Data = (data: any) => {
    let ring3Data = data.ring3;
    let resultDataHeights: any;
    let resultDataIcons: any = [];
    CommonConstants.RING3INFO.forEach((innerItem: any, i: any) => {
      let iconData: any = CommonConstants.ICONS_DATA.ring3Info.filter((value: any) => {
        return value.id === innerItem;
      });
      resultDataHeights = [];
      resultDataIcons = [];

      ring3Data.forEach((item: any, k: any, key: any) => {
        //let icons = item.icons[i];
        if (k !== 0 && item.icons.indexOf(innerItem) !== -1) {
          let icons = item.icons[innerItem];
          resultDataHeights.push(icons !== 0 ? iconData[0].value : 0);
          // console.log('symbolSize: ', iconData[0].size);
          // console.log('symbolOffset: ', iconData[0].symbolOffset);
          resultDataIcons.push({
            value: icons !== 0 ? iconData[0].value : 0,
            symbol: iconData[0].path,
            symbolSize: iconData[0].symbolSize,
            tooltipData: iconData[0].caption,
            symbolOffset: iconData[0].symbolOffset,
          });
        } else {
          resultDataHeights.push(0);
          resultDataIcons.push({
            value: 0,
            symbol: '',
            symbolSize: '',
            tooltipData: '',
          });
        }
      });
      this.arrSeries.push({
        name: 'FIRST',
        type: 'bar',
        barWidth: '2px',
        color: iconData[0].color,
        barGap: '100%',
        //symbol: 'image://./images/line.png',
        itemStyle: {
          // opacity: 0.5
        },
        data: resultDataHeights,
        z: -10,
      });
      this.arrSeries.push({
        name: 'SECOND',
        type: 'pictorialBar',
        barGap: '-100%',
        symbolPosition: 'end',
        data: resultDataIcons,
        z: 10,
      });
    });
  };
}
