<div class="dashboardPage">
  <div class="col-sm flex-col">
    <legacy-app-widget-overview
      [title]="performanceSummaryTitle || 'Farm Performance Summary'"
    ></legacy-app-widget-overview>
  </div>
  <div class="flex-col" *ngIf="domain === 'overview'">
    <app-widget-monitoring-information></app-widget-monitoring-information>
  </div>

  <div class="flex-col" *ngIf="domain === 'overview'">
    <legacy-app-widget-taiao-scorecard></legacy-app-widget-taiao-scorecard>
  </div>
  <div class="flex-col">
    <legacy-app-widget-methane *ngIf="domain === 'overview'"></legacy-app-widget-methane>
  </div>
  <div class="flex-col" *ngIf="domain === 'overview'">
    <app-widget-maramataka-phase></app-widget-maramataka-phase>
  </div>
  <div class="col-sm flex-col" *ngIf="domain === 'moana' || domain === 'whenua' || domain === 'hau'">
    <legacy-app-widget-oxygen></legacy-app-widget-oxygen>
  </div>
  <div class="flex-col">
    <legacy-app-widget-groundwater-wells></legacy-app-widget-groundwater-wells>
  </div>

  <div class="flex-col">
    <app-widget-forcasted-weather *ngIf="domain === 'overview'"></app-widget-forcasted-weather>
  </div>
  <div class="flex-col">
    <legacy-app-widget-water-monitoring-conductivity></legacy-app-widget-water-monitoring-conductivity>
  </div>
  <div class="col-sm flex-col">
    <legacy-app-widget-indicators *ngIf="domain === 'wai'"></legacy-app-widget-indicators>
  </div>

  <div class="col-sm-12 flex-col">
    <app-widget-kokopu *ngIf="domain === 'wai'"></app-widget-kokopu>
  </div>
</div>
