import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

type Range = { start: Date; end: Date };

@Injectable({ providedIn: 'root' })
export class DateRangeService {
  rangeSubject: Subject<Range> = new Subject();

  updateRange(start: Date, end: Date) {
    this.rangeSubject.next({ start, end });
  }

  getRangeObservable = (): Observable<Range> => this.rangeSubject.asObservable();
}
