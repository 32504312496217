import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { SurfaceWaterChart } from '../charts/surfaceWaterChart';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { AlertService } from '@app/data/services/alerts.service';
import { EChartsOption } from 'echarts';

import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { ClientConstantType } from '@app/client/client.constant';

@Component({
  selector: 'legacy-app-widget-swd',
  templateUrl: './widget-swd.component.html',
  styleUrls: ['./widget-swd.component.scss'],
})
export class WidgetSWDComponent extends LegacyWidgetComponentBase {
  surfaceWaterGrabOption: EChartsOption = {};
  chartTitle: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement ? 'Wai - Surface Water' : 'Water Quality';
  chartHeader: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement ? 'Teviotdale Stream (SWD)' : 'Site 2';

  constructor(
    private surfaceWaterChart: SurfaceWaterChart,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService,
    private alertService: AlertService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  onFilterUpdate(): void {
    forkJoin({
      surfacewater: this.legacyDataService.getSurfaceWaterData(this.fromDate, this.toDate).pipe(take(1)),
      alerts: this.alertService
        .search({
          startDate: this.fromDate,
          endDate: this.toDate,
          params: ['Boron', 'Zinc', 'Nitrate Nitrogen', 'Ammoniacal Nitrogen'],
        })
        .pipe(take(1)),
    }).subscribe((result) => {
      const { surfacewater, alerts } = result;
      // Filter the alerts for SWD
      const filteredAlerts = alerts.filter((a: any) => a.name.includes('SWD'));
      const renamedAlerts = filteredAlerts.map((a: any) => ({ ...a, name: a.name.replace(' SWD', '') }));
      const filteredResult = { surfacewater, alerts: renamedAlerts };

      // check if no data returned from selected dates
      if (surfacewater.length === 0) {
        // set echart options for grab data by location
        this.surfaceWaterGrabOption = this.surfaceWaterChart.getSurfaceWaterData([]).D;
      } else {
        // set echart options for grab data by location
        this.surfaceWaterGrabOption = this.surfaceWaterChart.getSurfaceWaterData(
          filteredResult,
          this.client === ClientConstantType.wastemanagement
        ).D;
      }
    });
  }
}
