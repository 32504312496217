<div class="clientNavigation w-100" [ngClass]="menuHidden ? 'hide' : 'expandMenu'">
  <div class="curveCorner curveLeft text-end">
    <img src="/assets/clients/wastemanagement/nav-logo-left.png" (click)="onLogoClick()" alt="" class="img-fluid" />
  </div>
  <div class="curveCorner curveRight text-end">
    <div class="nav-item">
      <a (click)="openMapView()"><img src="/assets/map-icon.png" alt="" class="img-fluid" /></a>
    </div>
    <div class="nav-item">
      <a (click)="openDashboard()"><img src="/assets/graph-icon.png" alt="" class="img-fluid" /></a>
    </div>
    <div *ngIf="isAuthenticated" class="nav-item" ngbDropdown>
      <a id="user-dropdown" class="nav-link loggedInUser" ngbDropdownToggle>
        <img src="/assets/user-icon.png" alt="" class="img-fluid" />&nbsp; {{ currentUserName }}
        <i class="fa fa-arrow-up"></i>
      </a>
      <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <h6 class="dropdown-header">
          <b>{{ username }}</b>
        </h6> -->
        <a href="javascript:;" class="dropdown-item" (click)="logout()" translate>Sign out</a>
        <a
          *ngIf="isAuthenticated && currentUser.isAdmin"
          href="javascript:;"
          (click)="openAdmin()"
          class="dropdown-item"
          translate
          >Admin</a
        >
        <a href="javascript:;" class="dropdown-item" translate>Profile settings</a>
        <a href="javascript:;" class="dropdown-item" translate>Terms of use</a>
      </div>
    </div>
    <div class="nav-item">
      <a><img src="/assets/theme-mode-icon.png" alt="" class="img-fluid" /></a>
    </div>
    <div class="nav-item hamburgerMenu" (click)="toggleMenu()">
      <span class="divider"></span>
      <span class="divider"></span>
      <span class="divider"></span>
    </div>
  </div>
  <!-- Navigation Links Container -->
  <div
    class="mainNavigation container py-5"
    [ngClass]="menuAnimate ? 'NoAnim' : 'animate__animated animate__fadeIn animate__delay-1s'"
  >
    <div class="row w-100 mt-3">
      <div class="col-md-3" *ngFor="let meniItem of menuJSON">
        <h2>{{ meniItem?.name }}</h2>
        <div *ngIf="meniItem?.name !== 'Information'">
          <ul>
            <!-- <li *ngFor="let childItem of meniItem.children"><a href="javascript:;" (click) ="childItem.url !='#' && menuClick(childItem.url)" >{{childItem.name}}</a></li>
          -->
            <div *ngFor="let childItem of meniItem.children">
              <li *ngIf="childItem?.children === undefined">
                <a href="javascript:;" (click)="toggleMenu(); childItem.url !== '#' && menuClick(childItem.url)">{{
                  childItem.name
                }}</a>
              </li>
              <li *ngIf="childItem?.children">
                <mat-accordion>
                  <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      {{ childItem?.name }}
                    </mat-expansion-panel-header>
                    <ul>
                      <li *ngFor="let item of childItem.children">
                        <a href="javascript:;" (click)="toggleMenu(); item.url !== '#' && menuClick(item.url)">{{
                          item.name
                        }}</a>
                        <!-- {{item.name}} -->
                      </li>
                    </ul>
                  </mat-expansion-panel>
                </mat-accordion>
              </li>
            </div>
          </ul>
        </div>
        <div *ngIf="meniItem?.name === 'Information'">
          <ul>
            <li *ngFor="let childItem of meniItem.children">
              {{ childItem.name }}
              <div class="menu-info">
                <ul *ngIf="childItem?.children">
                  <li *ngFor="let innerItem of childItem.children">
                    <a href="javascript:;" (click)="toggleMenu(); innerItem.url !== '#' && menuClick(innerItem.url)">{{
                      innerItem.name
                    }}</a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Navigation Links Container -->
</div>

<div class="clientContent client-wm">
  <router-outlet></router-outlet>
</div>
