<div class="dashboardPage">
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-widget-forcasted-weather></app-widget-forcasted-weather>
    </div>
    <div class="col" style="width: 100%">
      <app-widget-maramataka-phase></app-widget-maramataka-phase>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ControlStructureRainfall"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div style="width: 100%">
      <app-basic-cartesian-chart [config]="WaterLevels"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="IslandBlockRoadTotalNutrients"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="IslandBlockRoadWaterChemistry"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="IslandBlockRoadDOM"></app-basic-cartesian-chart>
    </div>
    <div class="col">&nbsp;</div>
  </div>
</div>
