import { Injectable } from '@angular/core';
import { EChartsOption } from 'echarts';
import { truncate } from 'fs';
import moment from 'moment';
import { getAlertSeries } from './chartUtil';

@Injectable({
  providedIn: 'root',
})
export class GroundWaterCharts {
  public getGroundWaterData(result: any, selectedGWLocation: any, scatter = true): EChartsOption {
    if (result.length === 0) {
      return {
        title: {
          text: 'No data available for the selected date range.',
        },
        color: ['#6AB6B0', '#2A5FA5', '#52844B', '#8F3895', '#428644'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'g/m³',
          },
        ],
        series: [],
      };
    } else {
      const series = scatter
        ? [
            {
              name: 'magnesium',
              type: 'scatter',
              symbolSize: 13,
              stack: 'Total',
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.magnesium : 0) : e.magnesium
              ),
            },
            {
              name: 'potassium',
              type: 'scatter',
              symbolSize: 13,
              stack: 'Total',
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.potassium : 0) : e.potassium
              ),
            },
            {
              name: 'sulphate',
              type: 'scatter',
              symbolSize: 13,
              stack: 'Total',
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.sulphate : 0) : e.sulphate
              ),
            },
            ...getAlertSeries(result.alerts, result.groundwater, (e) => moment(e.date).format('YYYY-MM-DD')),
          ]
        : [
            {
              name: 'magnesium',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#6AB6B0',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.magnesium : 0) : e.magnesium
              ),
            },
            {
              name: 'potassium',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#2A5FA5',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.potassium : 0) : e.potassium
              ),
            },
            {
              name: 'sulphate',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: '#8F3895',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.magnesium : 0) : e.magnesium
              ),
            },
            {
              name: 'pH',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              label: {
                show: true,
                position: 'top',
              },
              areaStyle: {
                opacity: 0.8,
                color: '#428644',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.fieldPh : 0) : e.fieldPh
              ),
            },
            {
              name: 'phosphorus',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              label: {
                show: true,
                position: 'top',
              },
              areaStyle: {
                opacity: 0.8,
                color: '#428644',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.phosphorus : 0) : e.phosphorus
              ),
            },
            {
              name: 'sodium',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 0,
              },
              showSymbol: false,
              label: {
                show: true,
                position: 'top',
              },
              areaStyle: {
                opacity: 0.8,
                color: '#D0752B',
              },
              emphasis: {
                focus: 'series',
              },
              data: result.groundwater.map((e: any) =>
                selectedGWLocation != '' ? (e.location === selectedGWLocation ? e.sodium : 0) : e.sodium
              ),
            },
            ...getAlertSeries(result.alerts, result.groundwater, (e) => moment(e.date).format('YYYY-MM-DD')),
          ];

      const selected = scatter
        ? {
            sulphate: true,
            potassium: false,
            magnesium: false,
          }
        : undefined;

      return {
        color: ['#6AB6B0', '#2A5FA5', '#8F3895', '#428644', '#D0752B'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          show: true,
          data: Object.keys(result.groundwater[0]).filter(function (key) {
            return key !== 'date';
          }),
          selected,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: result.groundwater.map((e: any) =>
              selectedGWLocation != ''
                ? e.location === selectedGWLocation
                  ? moment(e.date).format('DD/MM/YYYY')
                  : null
                : moment(e.date).format('DD/MM/YYYY')
            ),
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'g/m³',
          },
        ],
        series,
      };
    }
  }

  public getGroundWaterNitrogenData(result: any): EChartsOption {
    if (result.length === 0) {
      return {
        title: {
          text: 'No data available for the selected date range.',
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: [],
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
          },
        ],
        series: [],
      };
    } else {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: result.groundwater.map((e: any) => e.location),
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: result.groundwater.map((e: any) => moment(e.date).format('DD/MM/YYYY')),
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: 'g/m³',
          },
        ],
        series: [
          {
            name: 'BH4/80',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH4/80' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH13/50',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH13/50' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH22/15',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH22/15' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH22/15',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH22/15' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH22/33',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH22/33' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH22/62',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH22/62' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH18/30',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH18/30' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH19/8',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH19/8' ? e.totalAmmoniacalNitrogen : 0)),
          },
          {
            name: 'BH21/12',
            type: 'scatter',
            symbolSize: 13,
            stack: 'Total',
            emphasis: {
              focus: 'series',
            },
            data: result.groundwater.map((e: any) => (e.location === 'BH21/12' ? e.totalAmmoniacalNitrogen : 0)),
          },
        ],
      };
    }
  }
}
