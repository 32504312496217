// Angular core imports
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

// Third-party libraries
import * as Leaflet from 'leaflet';
import 'leaflet-textpath';
import Papa from 'papaparse';
import moment from 'moment';

// Environment configuration
import { environment } from '@env/environment';

// Core constants and types
import { CommonConstants } from '@app/core/constants/commonConstants';

// Client module
import { ClientConstantType } from '@app/client/client.constant';
import { ClientService } from '@app/client/client.service';

// Data classes, models and types
import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { EnvironmentalDataSource } from '@app/data/services/types/EnvironmentalDataSource';

// Services
import { ChartService } from '@app/data/services/chartService';
import { DrawerService } from '@app/data/services/drawerService';
import { FilterService, FilterSetupSubscribe } from '@app/data/services/filterService';
import { LoaderService } from '@app/data/services/loader.services';
import { MapService } from '@app/data/services/map.service';
import { Site, SitesService } from '@app/data/services/sitesService';

const iconUrl = './../assets/img/map-icons/map-marker-blue-active@2x.png';
const shadowUrl = './assets/marker-shadow.png';

@Component({
  selector: 'app-mapview',
  templateUrl: './mapview.component.html',
  styleUrls: ['./mapview.component.scss'],
})
export class MapviewComponent extends UnsubscribeManager implements AfterViewInit, OnDestroy, OnInit {
  client: ClientConstantType;

  static csvData: any;
  mapOptions: any;
  map: any;
  layerGroup: any;
  apiLayers: { [key: string]: Leaflet.GeoJSON } = {}; // Cache for api retrieved layers.
  timer: undefined | ReturnType<typeof setTimeout>;
  apiLayerIds: string[] = [];

  markerArray: Leaflet.Marker[] = [];
  observationMarkersArray: any = [];
  private environmentalDataSourceCache: { data: EnvironmentalDataSource[]; timestamp: number } | null = null;

  site: Site | null = null;
  siteSubscription: Subscription | null = null;

  iconDefault = Leaflet.icon({
    iconUrl,
    shadowUrl,
    iconSize: [24, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  observationIconMarker = Leaflet.icon({
    iconUrl: '../../assets/img/map-icons/observation-icon.png',
    iconSize: [24, 47],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
  });

  activeMarker: any;

  private mapInitializing = false;

  constructor(
    private filterService: FilterService,
    private drawerService: DrawerService,
    private chartService: ChartService,
    protected clientService: ClientService,
    private mapService: MapService,
    private sitesService: SitesService,
    private loaderService: LoaderService
  ) {
    super();
    this.client = this.clientService.getClient();
  }

  ngOnInit(): void {
    this.filterService.subscribe(this.handleFilterUpdate.bind(this), [
      'toDate',
      'fromDate',
      'searchTerm',
      'sensor',
      'mapFeatures',
      'baseMap',
      'dataLayer',
    ]);

    this.siteSubscription = this.sitesService.getSelectedSite().subscribe((site: Site | null) => {
      this.site = site;
      this.environmentalDataSourceCache = null;

      // Only initialize map if it doesn't exist yet or if we have valid coordinates
      if ((!this.map || (site?.latitude && site?.longitude)) && !this.mapInitializing) {
        this.initMap();
      }
    });

    this.handleFilterUpdate({
      filters: this.filterService.getFilters(),
      previousFilters: this.filterService.getFilters(),
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    if (this.map) {
      this.map.off();
      this.map.remove();
    }
    if (this.siteSubscription) {
      this.siteSubscription.unsubscribe();
    }
  }

  private handleFilterUpdate({ filters, previousFilters }: FilterSetupSubscribe): void {
    const affectedMapFeatures: string[] = filters.mapFeatures
      .filter((x) => !previousFilters.mapFeatures.includes(x))
      .concat(previousFilters.mapFeatures.filter((x) => !filters.mapFeatures.includes(x)));

    /**
     * Because we're also listening on to/from date changes, we need to see if we have any used
     * map features that rely on the date range. If our date range has changed then basically
     * reload them (remove and add the back with the new date range)
     */
    if (
      filters.fromDate &&
      filters.toDate &&
      (filters.fromDate !== previousFilters.fromDate || filters.toDate !== previousFilters.toDate)
    ) {
      const affectedMapFeaturesByDate: string[] = ['observation'];

      affectedMapFeaturesByDate.forEach((x) => {
        if (filters.mapFeatures.includes(x) && !affectedMapFeatures.includes(x)) {
          affectedMapFeatures.push(x);
        }
      });
    }

    // Set delay on searching by text, otherwise you get a race condition bug
    // Delay set to 1 second after last key pressed for slow browsers
    if (filters.searchTerm !== previousFilters.searchTerm) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.removeObservationMarkersLayer();
        this.addObservationMarkerslayer();
      }, 1000);
    }

    this.updateAllMonitoringMarkers(affectedMapFeatures);
    this.updateAllMapLayers(affectedMapFeatures);

    if (affectedMapFeatures.includes('observation')) {
      this.removeObservationMarkersLayer();

      if (filters.mapFeatures.includes('observation')) {
        this.addObservationMarkerslayer();
      }
    }
  }

  ngAfterViewInit() {
    // Only initialize if not already done and we have valid coordinates
    if (!this.map && this.site?.latitude && this.site?.longitude) {
      this.initMap();
    }

    // Preload api map layers. Currently for rohe/boundary
    const mapFeatureLayerIds = this.filterService
      .getFilters()
      .mapFeatures.filter((feature) => feature.startsWith('map-layer'))
      ?.map((feature) => feature.split('-')[2]);
    if (mapFeatureLayerIds) {
      this.addApiMapLayers(mapFeatureLayerIds);
    }
    // Preload environmental data sources
    if (this.filterService.getFilters().mapFeatures.includes('monitor')) {
      this.updateAllMonitoringMarkers(['monitor']);
    }
  }

  private zoomLevels: { [key: number]: number } = {
    1000: 12,
    5000: 11,
    7000: 10,
    10000: 9,
  };

  private initMap(): void {
    // Prevent concurrent initialization
    if (this.mapInitializing) return;
    this.mapInitializing = true;

    try {
      // Don't initialize if we don't have valid coordinates
      if (!this.site?.latitude || !this.site?.longitude) {
        console.warn('Cannot initialize map: missing site coordinates');
        this.mapInitializing = false;
        return;
      }

      // clear map
      if (this.map) {
        this.removeObservationMarkersLayer();
        this.map.off();
        this.map.remove();
      }

      // Typing makes me do this the old way
      let zoomLevel = 15;
      for (const greaterThan in this.zoomLevels) {
        if (this.site?.siteArea && this.site.siteArea > parseInt(greaterThan)) {
          zoomLevel = this.zoomLevels[greaterThan];
        }
      }

      // set map config
      this.mapOptions = {
        center: [this.site.latitude, this.site.longitude],
        zoom: zoomLevel,
      };

      this.map = Leaflet.map('map', this.mapOptions);

      var layer = Leaflet.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          id: 'mapbox/satellite-v9',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: environment.mapbox.accessToken,
        }
      ).addTo(this.map);

      // Create and add the feature group
      this.layerGroup = Leaflet.featureGroup();
      this.layerGroup.setStyle({ color: 'blue', opacity: 0.5 });
      this.layerGroup.addLayer(layer);
      this.layerGroup.addTo(this.map);

      // Add observation marker layer if map initialized successfully
      this.addObservationMarkerslayer();

      console.log('Map initialized successfully');
    } catch (error) {
      console.error('Error initializing map:', error);
      // Reset map-related objects in case of error
      this.map = null;
      this.layerGroup = null;
    } finally {
      this.mapInitializing = false;
    }
  }

  private loadApiLayer(layerId: string, layerData: any): void {
    if (!this.layerGroup || !this.map) {
      console.warn('Cannot load API layer: layerGroup or map is not initialized');
      return;
    }

    const domainColors: any = {
      Hau: '#e4f527e6',
      Moana: '#d9dfff',
      Wai: '#00a0ba',
      Whenua: '#dd7000e6',
    };
    const domainMarkers: any = {
      Hau: 'marker-hau.png',
      Moana: 'marker-blue.png',
      Wai: 'marker-blue.png',
      Whenua: 'marker-green.png',
    };

    this.apiLayers[layerId] = Leaflet.geoJson(layerData.data, {
      onEachFeature: (feature: any, layer: any) => {
        // Use the configured name_attribute from the layer data if available
        const nameAttribute = layerData.name_attribute || 'name';

        // Try to get the display name using the configured attribute, then fall back to alternatives
        const displayName =
          (nameAttribute && feature.properties?.[nameAttribute]) ||
          feature.properties?.name ||
          layerData.layer_name ||
          layerData.name ||
          'Unnamed Feature';

        // Create popup content with proper hyperlinks
        let popupContent = `<b>${displayName}</b>`;

        // Add "Click for more info" text if layer has source_url with proper link
        if (feature.properties?.externalURL) {
          popupContent += `<br><a href="${feature.properties.externalURL}" target="_blank">More info</a>`;
        } else if (layerData.source_url) {
          popupContent += `<br><a href="${layerData.source_url}" target="_blank">More info</a>`;
        }

        // Configure popup options for better UX
        const popupOptions = {
          closeButton: false,
          offset: [0, -2] as [number, number],
          className: 'feature-popup',
          autoPan: false,
        };

        // Store original style to restore after hover
        const originalStyle = {
          weight: 2,
          color: domainColors[layerData.domain],
          dashArray: '2, 2',
          opacity: 0.5,
          fillOpacity: 0.2,
        };

        // Highlight style to apply on hover
        const highlightStyle = {
          weight: 3,
          color: '#fff',
          dashArray: '',
          opacity: 0.8,
          fillOpacity: 0.5,
        };

        // Bind popup with improved options
        layer.bindPopup(popupContent, popupOptions);

        // Use event timeout to prevent flickering
        let hoverTimeout: ReturnType<typeof setTimeout>;
        let popupHovered = false;

        // Show popup on hover with delay to prevent flickering
        layer.on('mouseover', () => {
          clearTimeout(hoverTimeout);

          // Bring this layer to front to handle overlapping areas
          if (layer.bringToFront) {
            layer.bringToFront();
          }

          // Apply highlight style
          if (layer.setStyle) {
            layer.setStyle(highlightStyle);
          }

          layer.openPopup();

          // Add event listener to the popup to maintain hover state
          const popup = layer.getPopup();
          if (popup && popup.getElement()) {
            const popupElement = popup.getElement();
            if (popupElement) {
              popupElement.addEventListener('mouseenter', () => {
                popupHovered = true;
              });
              popupElement.addEventListener('mouseleave', () => {
                popupHovered = false;
                // When leaving the popup, start the same timeout as when leaving the layer
                hoverTimeout = setTimeout(() => {
                  if (!popupHovered) {
                    layer.closePopup();
                    if (layer.setStyle) {
                      layer.setStyle(originalStyle);
                    }
                  }
                }, 300);
              });
            }
          }
        });

        layer.on('mouseout', () => {
          // Only restore style and close popup after delay
          // and if not hovering over the popup
          hoverTimeout = setTimeout(() => {
            if (!popupHovered) {
              // Restore original style
              if (layer.setStyle) {
                layer.setStyle(originalStyle);
              }
              layer.closePopup();
            }
          }, 300);
        });

        // Handle click events
        layer.on('click', () => {
          // Keep popup open on click
          clearTimeout(hoverTimeout);

          // Keep the highlight style when clicked
          if (layer.setStyle) {
            layer.setStyle(highlightStyle);
          }

          // Bring this layer to front to handle overlapping areas
          if (layer.bringToFront) {
            layer.bringToFront();
          }

          // Since we're using actual links now, we don't need to handle URL opening here
          // The <a> tags will handle that

          // Set timeout to remove highlight after a delay
          setTimeout(() => {
            if (layer.setStyle && !popupHovered) {
              layer.setStyle(originalStyle);
            }
          }, 1000);
        });

        layer.myTag = `apiLayer-${layerId}-GeoJSON`;
      },
      pointToLayer: function (feature: any, latlng: any) {
        return Leaflet.marker(latlng, {
          icon: Leaflet.icon({ iconUrl: `/assets/icons/${domainMarkers[layerData.domain]}` }),
        });
      },
      style: {
        weight: 2,
        color: domainColors[layerData.domain],
        dashArray: '2, 2',
        opacity: 0.5,
        fillOpacity: 0.2,
      },
    });
    this.layerGroup.addLayer(this.apiLayers[layerId]).addTo(this.map);
  }

  addApiMapLayers(layerIds: string[], showLoader = true): void {
    if (!this.map) return; // Don't try to add layers if map isn't initialized

    layerIds.forEach((layerId) => {
      // Check if data is already cached.
      this.apiLayerIds.push(layerId);
      if (layerId in this.apiLayers) {
        if (showLoader) {
          this.loaderService.show();
        }
        this.layerGroup.addLayer(this.apiLayers[layerId]).addTo(this.map);
        if (showLoader) {
          this.loaderService.hide();
        }
      } else {
        // Fetch data from API.
        if (this.site) {
          this.mapService.getMapLayerJSON(layerId, this.site?.id).subscribe(
            (response) => {
              if (!response) return;
              if (showLoader) {
                this.loaderService.show();
              }
              this.loadApiLayer(layerId, response);
              if (showLoader) {
                this.loaderService.hide();
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    });
  }

  removeApiMapLayer(layerId: string): void {
    if (this.apiLayers[layerId]) {
      this.layerGroup.removeLayer(this.apiLayers[layerId]);
      this.apiLayerIds = this.apiLayerIds.filter((id) => id !== layerId);
    }
  }

  addObservationMarkerslayer = () => {
    if (!this.map || !this.layerGroup) {
      console.warn('Cannot add observation markers: map or layerGroup is not initialized');
      return;
    }

    const { fromDate, toDate, searchTerm } = this.filterService.getFilters();

    this.chartService
      .getObservationData(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD'))
      .subscribe(
        (response) => {
          if (!response) return;

          const observations = response.filter(
            (observation: any) =>
              observation.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
              observation.items?.some((item: any) => item.value.toLowerCase().includes(searchTerm.toLowerCase()))
          );

          const observationsByCoordinates = observations.reduce(
            (acc: any, observation: any) => ({
              ...acc,
              [`${observation.lat}${observation.lng}`]: [
                ...(acc[`${observation.lat}${observation.lng}`] || []),
                observation,
              ],
            }),
            {}
          );

          // Add markers to map
          observations.forEach((observation: any) => {
            const observationMarker = Leaflet.marker([observation.lat, observation.lng], {
              icon: this.observationIconMarker,
              zIndexOffset: 200, // Ensure observation markers appear above other markers
            });

            // Configure popup options for better UX
            const popupOptions = {
              closeButton: false,
              offset: [0, -2] as [number, number],
              className: 'observation-popup',
              autoPan: false,
            };

            // Create more informative popup content
            let popupContent = `<b>${observation.location}</b>`;
            if (observation.date) {
              popupContent += `<br><small>Observed: ${observation.date}</small>`;
            }
            if (observation.items && observation.items.length > 0) {
              const firstItem = observation.items[0];
              popupContent += `<br><small>${firstItem.title}: ${firstItem.value}</small>`;
            }

            observationMarker.bindPopup(popupContent, popupOptions);

            // Use event timeout to prevent flickering
            let hoverTimeout: ReturnType<typeof setTimeout>;
            let popupHovered = false;

            observationMarker.on('mouseover', () => {
              clearTimeout(hoverTimeout);
              // Bring marker to front when hovered
              observationMarker.setZIndexOffset(2000);
              observationMarker.openPopup();

              // Add event listener to the popup to maintain hover state
              const popup = observationMarker.getPopup();
              if (popup && popup.getElement()) {
                const popupElement = popup.getElement();
                if (popupElement) {
                  popupElement.addEventListener('mouseenter', () => {
                    popupHovered = true;
                  });
                  popupElement.addEventListener('mouseleave', () => {
                    popupHovered = false;
                    // When leaving the popup, start the same timeout as when leaving the marker
                    hoverTimeout = setTimeout(() => {
                      if (!popupHovered) {
                        observationMarker.closePopup();
                        observationMarker.setZIndexOffset(200);
                      }
                    }, 300);
                  });
                }
              }
            });

            observationMarker.on('mouseout', () => {
              // Only close popup after delay and if not hovering over the popup
              hoverTimeout = setTimeout(() => {
                if (!popupHovered) {
                  // Reset z-index offset
                  observationMarker.setZIndexOffset(200);
                  observationMarker.closePopup();
                }
              }, 300);
            });

            observationMarker.on('click', () => {
              // Keep popup open on click
              clearTimeout(hoverTimeout);
              // Keep marker in front when clicked
              observationMarker.setZIndexOffset(2000);
              this.showObservations(observationsByCoordinates[`${observation.lat}${observation.lng}`]);

              // Reset z-index after delay
              setTimeout(() => {
                observationMarker.setZIndexOffset(200);
              }, 1000);
            });

            this.observationMarkersArray.push(observationMarker);
            this.layerGroup.addLayer(observationMarker);
          });

          this.setCSVObservationData(observations);
        },
        (error: Error) => {
          console.log(error);
        }
      );
  };
  removeObservationMarkersLayer = () => {
    if (!this.layerGroup || !this.map) {
      return;
    }

    this.observationMarkersArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
  };

  private createMonitoringStationMarker = (source: EnvironmentalDataSource): void => {
    if (!this.layerGroup || !this.map) {
      console.warn('Cannot create marker: layerGroup or map is not initialized');
      return;
    }

    const iconFile = CommonConstants.MARKER_ICONS[source.domain as keyof typeof CommonConstants.MARKER_ICONS];
    const leafletIcon = Leaflet.icon({
      ...this.iconDefault,
      iconUrl: `${CommonConstants.ICON_DIR}${iconFile}`,
    });
    let marker: Leaflet.Marker = Leaflet.marker([source.latitude, source.longitude], {
      icon: leafletIcon,
      alt: source.source_identifier,
      zIndexOffset: 100, // Ensure markers appear above polygons
    });

    // Configure popup options for better UX
    const popupOptions = {
      closeButton: false,
      offset: [0, -2] as [number, number],
      className: 'monitor-popup',
      autoPan: false,
    };

    // Create popup content with additional details if available
    let popupContent = `<b>${source.human_readable_name}</b>`;
    if (source.description) {
      popupContent += `<br><small>${source.description}</small>`;
    }

    marker.bindPopup(popupContent, popupOptions);

    // Use event timeout to prevent flickering
    let hoverTimeout: ReturnType<typeof setTimeout>;
    let popupHovered = false;

    marker.on('mouseover', () => {
      clearTimeout(hoverTimeout);
      // Bring marker to front when hovered
      marker.setZIndexOffset(1000);
      marker.openPopup();

      // Add event listener to the popup to maintain hover state
      const popup = marker.getPopup();
      if (popup && popup.getElement()) {
        const popupElement = popup.getElement();
        if (popupElement) {
          popupElement.addEventListener('mouseenter', () => {
            popupHovered = true;
          });
          popupElement.addEventListener('mouseleave', () => {
            popupHovered = false;
            // When leaving the popup, start the same timeout as when leaving the marker
            hoverTimeout = setTimeout(() => {
              if (!popupHovered) {
                marker.closePopup();
                marker.setZIndexOffset(100);
              }
            }, 300);
          });
        }
      }
    });

    marker.on('mouseout', () => {
      // Only close popup after delay and if not hovering over the popup
      hoverTimeout = setTimeout(() => {
        if (!popupHovered) {
          // Reset z-index offset
          marker.setZIndexOffset(100);
          marker.closePopup();
        }
      }, 300);
    });

    marker.on('click', () => {
      // Keep popup open on click
      clearTimeout(hoverTimeout);
      // Keep marker in front when clicked
      marker.setZIndexOffset(1000);
      this.showEnvironmentalDataSourceDataInDrawer(source.source_identifier);

      // Reset z-index after delay
      setTimeout(() => {
        marker.setZIndexOffset(100);
      }, 1000);
    });

    this.markerArray.push(marker);
    this.layerGroup.addLayer(marker);
  };

  // each station selected individually (sidebar version)
  updateEnvironmentalSourceMarkers = (affectedMapFeatures: string[]): void => {
    const markerMapFeatures = affectedMapFeatures.filter((x) => !x.startsWith('map-layer'));
    if (markerMapFeatures.length === 0) return;

    if (!this.layerGroup || !this.map) {
      console.warn('Cannot update markers: layerGroup or map is not initialized');
      return;
    }

    this.loaderService.show();
    this.markerArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
    this.markerArray = [];
    const features: string[] = this.filterService.getFilters().mapFeatures;
    if (!this.site) return;

    const processResponse = (response: EnvironmentalDataSource[]) => {
      features.forEach((feature) => {
        const source = response.find((x) => x.source_identifier === feature);
        if (!source) return;
        this.createMonitoringStationMarker(source);
      });
      this.loaderService.hide();
    };

    const isEnvSourcesRecent = () => {
      if (!this.environmentalDataSourceCache) return false;
      const now = Date.now();
      const cacheAge = now - this.environmentalDataSourceCache.timestamp;
      return cacheAge < 60000;
    };

    if (isEnvSourcesRecent()) {
      // Cache sources for 1 minute to avoid spamming the API
      // for multiple checkbox changes
      processResponse(this.environmentalDataSourceCache?.data ?? []);
    } else {
      this.mapService
        .getMapEnvironmentDataSources(this.site.id)
        .pipe(take(1))
        .subscribe((response: EnvironmentalDataSource[]) => {
          this.environmentalDataSourceCache = { data: response, timestamp: Date.now() };
          processResponse(response);
        });
    }
  };

  showEnvironmentalDataSourceDataInDrawer(environmentalDataSourceIdentifier: string): void {
    const { fromDate, toDate } = this.filterService.getFilters();
    // Get the environmental data for the source
    this.chartService
      .getEnvironmentalDataForSourceByDate(
        environmentalDataSourceIdentifier,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD')
      )
      .pipe(take(1))
      .subscribe((response: any) => {
        // Now format it for the drawer
        const environmentalDataSourcesById = (response.environmentalDataSources || []).reduce(
          (accumulator: any, source: any) => ({
            ...accumulator,
            [source.id]: source,
          }),
          {}
        );
        const analytesById = (response.analytes || []).reduce(
          (accumulator: any, analyte: any) => ({
            ...accumulator,
            [analyte.id]: analyte,
          }),
          {}
        );
        const precisionMap = {
          raw: 'YYYY-MM-DD HH:mm',
          hour: 'YYYY-MM-DD HH:mm',
          day: 'YYYY-MM-DD',
          week: 'YYYY-MM-DD',
          month: 'YYYY-MM',
        };
        const displayPrecision = {
          raw: 'dd/MM/yyyy hh:mma',
          hour: 'dd/MM/yyyy hh:mma',
          day: 'dd/MM/yyyy',
          week: 'dd/MM/yyyy',
          month: 'dd/MM/yyyy',
        };
        const endDateMap = {
          raw: { hours: 0 },
          hour: { hours: 0 },
          day: { days: 0 },
          week: { days: 6 },
          month: { months: 1, days: -1 },
        };
        const precision = precisionMap[response.granularity as keyof typeof precisionMap];
        const drawerData = Object.values(
          (response.data || [])
            .map((dataPoint: any) => ({
              ...dataPoint,
              displayDate: moment(dataPoint.date_time).format(precision),
              startDate: moment(dataPoint.date_time).format('YYYY-MM-DD'),
              endDate: moment(dataPoint.date_time)
                .add(endDateMap[response.granularity as keyof typeof endDateMap])
                .format('YYYY-MM-DD'), // TODO - cahnge
              orderField: `${moment(dataPoint.date_time).format(precision)}-${
                environmentalDataSourcesById[dataPoint.environmental_data_source_id].human_readable_name
              }`,
            }))
            .reduce(
              (accumulator: any, dataPoint: any) => ({
                ...accumulator,
                [dataPoint.orderField]: {
                  ...dataPoint,
                  ...(accumulator[dataPoint.orderField] || {}),
                  analyte_id: undefined,
                  analyte: undefined,
                  environmentalDataSource: environmentalDataSourcesById[dataPoint.environmental_data_source_id],
                  // Roll up the readings
                  data: [
                    ...(accumulator[dataPoint.orderField]?.data || []),
                    {
                      avg: dataPoint.avg,
                      min: dataPoint.min,
                      max: dataPoint.max,
                      sum: dataPoint.sum,
                      stddev: dataPoint.stddev,
                      stddev_pop: dataPoint.stddev_pop,
                      stddev_samp: dataPoint.stddev_samp,
                      variance: dataPoint.variance,
                      var_pop: dataPoint.var_pop,
                      var_samp: dataPoint.var_samp,
                      percentile_25: dataPoint.percentile_25,
                      percentile_50: dataPoint.percentile_50,
                      percentile_75: dataPoint.percentile_75,
                      percentile_90: dataPoint.percentile_90,
                      percentile_95: dataPoint.percentile_95,
                      percentile_99: dataPoint.percentile_99,
                      mode: dataPoint.mode,
                      count: dataPoint.count,
                      analyte: analytesById[dataPoint.analyte_id],
                    },
                  ],
                },
              }),
              {}
            )
        );
        drawerData.sort((a: any, b: any) => (a.orderField < b.orderField ? -1 : 1));
        this.drawerService.setDrawerData({
          granularity: response.granularity,
          data: drawerData.map((timeEntry: any) => ({
            title: timeEntry.environmentalDataSource.human_readable_name,
            domain: timeEntry.environmentalDataSource.domain,
            date: timeEntry.displayDate,
            date_format: displayPrecision[response.granularity as keyof typeof displayPrecision],
            source: 'environmental_data_source',
            source_data: timeEntry,
          })),
        });
      });
  }
  // all stations (non-sidebar version)
  updateAllMonitoringMarkers(affectedMapFeatures: string[]): void {
    if (!affectedMapFeatures.includes('monitor')) {
      return;
    }

    if (!this.layerGroup || !this.map) {
      console.warn('Cannot update monitoring markers: layerGroup or map is not initialized');
      return;
    }

    this.markerArray.forEach((innerItem: any, i: any) => {
      this.layerGroup.removeLayer(innerItem);
    });
    this.markerArray = [];
    if (!this.site) {
      return;
    }
    if (!this.filterService.getFilters().mapFeatures.includes('monitor')) {
      return;
    }
    this.loaderService.show();
    this.mapService
      .getMapEnvironmentDataSources(this.site.id)
      .pipe(take(1))
      .subscribe((response: EnvironmentalDataSource[]) => {
        response.forEach((source) => {
          this.createMonitoringStationMarker(source);
        });
        this.loaderService.hide();
      });
  }

  updateAllMapLayers(affectedMapFeatures: string[]): void {
    if (!affectedMapFeatures.find((feature) => /^map-layer-\d{1,}/.test(feature))) {
      return;
    }
    if (!this.site) {
      return;
    }
    // Get the new layer ids
    const currentMapFilters = this.filterService.getFilters().mapFeatures;
    const newLayerIds = currentMapFilters
      .filter((feature) => /^map-layer-\d{1,}/.test(feature))
      .map((feature) => feature.split('-')[2]);

    // Get rid of the old ones
    const oldLayerIds = this.apiLayerIds.filter((id) => !newLayerIds.includes(id));
    oldLayerIds.forEach((layerId) => this.removeApiMapLayer(layerId));

    // Add the new ones
    const toAddLayerIds = newLayerIds.filter((id) => !this.apiLayerIds.includes(id));
    this.addApiMapLayers(toAddLayerIds, false);
  }

  showObservations(observations: any[]): void {
    this.drawerService.setDrawerData({
      granularity: 'na',
      data: observations.map((observation) => ({
        title: observation.location,
        domain: 'Observation',
        date: observation.date,
        source: 'observation',
        source_data: {
          ...observation,
          latitude: observation.lat,
          longitude: observation.lng,
        },
      })),
    });
  }

  setCSVObservationData(observations: Array<any>): void {
    let cleansedObs: any[] = [];
    if (observations && observations.length) {
      // We have standard headers that should always be shown, and item headers that should match the title of each item
      const standardHeaders = [
        'ID',
        'Name',
        'Observed Date',
        'Revisit Date',
        'Latitude',
        'Longitude',
        'Status',
        'Site',
        'Location',
      ];
      const itemHeaders = [].concat.apply(
        [],
        observations.map((s: any) => s.items.map((i: any) => i.title))
      );
      const headers = [...standardHeaders, ...new Set(itemHeaders)];

      // Cleanse each observation before converting to CSV
      cleansedObs = observations.map((s: any) => {
        const cleansedItem: { [key: string]: any } = {};
        headers.forEach((h) => {
          switch (h as string) {
            case 'ID':
              cleansedItem[h] = s.id;
              break;
            case 'Name':
              cleansedItem[h] = s.location;
              break;
            case 'Observed Date':
              cleansedItem[h] = s.date;
              break;
            case 'Revisit Date':
              cleansedItem[h] = s.revisitDate ?? '';
              break;
            case 'Latitude':
              cleansedItem[h] = s.lat;
              break;
            case 'Longitude':
              cleansedItem[h] = s.lng;
              break;
            case 'Status':
              cleansedItem[h] = s.status?.name;
              break;
            case 'Site':
              cleansedItem[h] = this.site?.name ?? '';
              break;
            case 'Location':
              cleansedItem[h] = s.location;
              break;
            default:
              cleansedItem[h] = s.items.find((i: any) => i.title === h)?.value ?? '';
              break;
          }
        });

        return cleansedItem;
      });
    }

    // Convert to CSV string
    MapviewComponent.csvData = Papa.unparse(cleansedObs);
  }
}
