<div nz-row>
  <div nz-col nzSpan="12">
    <div
      id="chart1"
      echarts
      [options]="options"
      theme="macarons"
      class="demo-chart"
      style="max-height: 320px; margin-top: -30px"
    ></div>
  </div>
</div>
