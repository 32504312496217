import { Component, OnInit } from '@angular/core';
import { FilterDrawerLayoutComponentBase } from '../../../classes/filter-drawer-layout-component.class';
import moment from 'moment';

@Component({
  selector: 'app-client-filter-drawer-layout',
  templateUrl: './filter-drawer-layout.component.html',
  styleUrls: ['./filter-drawer-layout.component.scss'],
})
export class wastemanagement_FilterDrawerLayoutComponent extends FilterDrawerLayoutComponentBase implements OnInit {
  protected getCheckboxFields(): string[] {
    return ['mapFeatures', 'matauranga', 'monitoring', 'observation'];
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.myFilterService.setFilters({
      fromDate: moment('2022-01-01').toDate(),
      toDate: moment('2022-03-31').toDate(),
      calendarTypeYear: 2022,
      calendarTypeMonth: 2,
      calendarTypeDay: 13,
      calendarTypeWeek: 2,
    });
  }

  protected getMultipleSelectFields(): string[] {
    return [];
  }
}
