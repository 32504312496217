import { Component } from '@angular/core';
import * as ChartConfig from './charts.config';
@Component({
  selector: 'app-client-taiao-dashboard-layout',
  templateUrl: './taiao-dashboard-layout.component.html',
  styleUrls: ['./taiao-dashboard-layout.component.scss'],
})
export class tahaaroa_TaiaoDashboardLayoutComponent {
  ChartConfig = ChartConfig;
}
