import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { SubscribeManager } from '../class/subscribe-manager.class';

export type Site = {
  id: string;
  name: string;
  longitude?: number;
  latitude?: number;
  siteArea?: number;
};

@Injectable({
  providedIn: 'root',
})
export class SitesService extends SubscribeManager<Site> {
  private siteSubject: BehaviorSubject<Site | null>;

  constructor(private router: Router) {
    super();
    const storedSite = localStorage.getItem('selectedSite');
    const initialSite: Site | null = storedSite ? JSON.parse(storedSite) : null;
    this.siteSubject = new BehaviorSubject<Site | null>(initialSite);
  }

  // What if our available sites have changed and we no longer have access
  // to the site we're on?
  public checkAvailableSites(sites: Site[]): void {
    const currentSiteId = this.siteSubject.getValue()?.id;
    const allowedSiteIds = sites.map((site) => site.id);

    if (!currentSiteId || !allowedSiteIds.includes(currentSiteId)) {
      if (allowedSiteIds.length > 0) {
        this.setSelectedSite(sites[0]);
      } else {
        this.clearSelectedSite();
      }
    }
  }

  public getSelectedSite(): Observable<Site | null> {
    return this.siteSubject.asObservable();
  }

  public setSelectedSite(site: Site): void {
    this.siteSubject.next(site);
    localStorage.setItem('selectedSite', JSON.stringify(site));
    this.pushChange(site);
  }

  public clearSelectedSite(): Observable<null> | null {
    localStorage.removeItem('selectedSite');
    this.siteSubject.next(null);
    return null;
  }
}
