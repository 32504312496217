import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CommonConstants } from '@app/core/constants/commonConstants';
import { ChartService } from '@app/data/services/chartService';

import { connect, getInstanceByDom, graphic } from 'echarts';
import moment from 'moment';
import { forkJoin, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  EnergyPhasesChartData,
  MaramatakaApiResult,
  MaramatakaApiResultLunarDays,
  MaramatakaDaysApiItem,
  MataurangaApiResult,
  MataurangaApiResultDay,
  MataurangaApiResultLunar,
} from '../../../data/services/types/Maramataka';

declare let $: any;
@Component({
  selector: 'app-energy-phases-charts',
  templateUrl: './energy-phases-chart.component.html',
  styleUrls: ['./energy-phases-chart.component.scss'],
})
export class EnergyPhasesChartComponent implements OnInit, OnChanges {
  @Input() resetFormSubject: Subject<number> = new Subject<number>();
  @Input() timeScale?: number;
  @Input() toDate: string = '';
  count: any = [];
  weeks: any = [];
  energyPhaseDates: string[] = [];
  energyPhase = 0;
  currentState = '';
  moonPhaseImages = [
    'image://./assets/moon_phases/moon_1.svg',
    'image://./assets/moon_phases/moon_2.svg',
    'image://./assets/moon_phases/moon_3.svg',
    'image://./assets/moon_phases/moon_4.svg',
    'image://./assets/moon_phases/moon_5.svg',
    'image://./assets/moon_phases/moon_6.svg',
  ];
  chartDateArray: any = [];
  barData = [100, 100, 100, 100, 100, 100];
  barColors = [
    ['#0e3238', '#0f0f0f'],
    ['#0e3238', '#972e98'],
    ['#0e3238', '#972e98'],
    ['#0e3238', '#972e98'],
    ['#0e3238', '#68015f'],
    ['#0e3238', '#68015f'],
    ['#0e3238', '#68015f'],
  ];

  colorMapper = {
    'Low Energy': '#ad53aa',
    'Medium Energy': '#972e98',
    'High Energy': '#68015f',
    'Very High Energy': '#381B4A',
  };

  chartData: EnergyPhasesChartData[] = [];
  options: any;
  constructor(private chartService: ChartService) {}
  ngOnInit() {
    // this.loadChartData();
    this.resetFormSubject.subscribe((response) => {
      if (response) {
        this.timeScale = response;
        alert(this.timeScale);
        this.loadChartData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['toDate']) {
      this.loadChartData();
    }
  }

  setOptions = () => {
    this.options = {
      color: ['#000000'],
      style: {
        border: '1px solid red',
      },
      actions: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'none',
        },
        position: (point: any) => {
          return [point[0] - 180, point[1] + 30];
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        formatter: (params: any) => {
          return `
          <h4 style="color:#ffffff;text-align:left">${params[0].data.energy} Energy</h4>
          ${
            params[0].data.actions &&
            params[0].data.actions.length &&
            params[0].data.actions &&
            params[0].data.actions.length !== ''
              ? `
            <div style="color:#ffffff;text-align:left">Recommended Actions</div>
            <ul style="padding-left: 1.25em">
              ${params[0].data.actions
                .filter((a: string) => a && a !== '')
                .map((a: string) => a.split('/'))
                .map((aParts: Array<string>) =>
                  aParts.length === 1 || aParts[0].trim() === aParts[1].trim() ? aParts[0].trim() : aParts.join(' / ')
                )
                .map((a: string) => {
                  return `<li style="text-align:left;max-width:320px">
                      <${a.length > 32 ? 'div' : 'span'}>
                        ${a.split(' / ')[0] ?? ''}
                      </${a.length > 32 ? 'div' : 'span'}>
                      <${a.length > 32 ? 'div' : 'span'} style="opacity: .6">
                        ${a.split(' / ')[1] ? ` / ${a.split(' / ')[1]}` : ''}
                      </${a.length > 32 ? 'div' : 'span'}>
                    </li>`;
                })
                .join('')}
            </ul>
          `
              : '<div style="color:#ffffff;text-align:left">No Recommended Actions</div>'
          }`;
        },
      },
      xAxis: {
        type: 'category',
        data: this.chartDateArray?.length > 0 ? this.chartDateArray : this.energyPhaseDates,
      },
      yAxis: {
        show: false,
      },
      containLabel: false,
      series: [
        {
          name: 'phase',
          data: this.chartData,
          type: 'bar',
          barWidth: '100%',
          z: -10,
        },
        {
          name: 'moon',
          type: 'pictorialBar',
          data: this.moonPhaseImages.map((image) => ({
            value: 100,
            symbolSize: 50,
            symbolPosition: 'center',
            symbol: image,
          })),
          z: 10,
        },
      ],
    };
  };

  loadChartData = () => {
    // Query from the end of the range - 5 days
    if (!this.toDate) {
      return;
    }

    const dayRange = 6;
    const addDaysAfterEnd = 3;
    const momentDate: moment.Moment = moment.tz(`${this.toDate} 23:59:59`, 'Pacific/Auckland');
    const chartDates: moment.Moment[] = [];
    // const chartLunarMonthDays: number[] = [];
    for (let i = addDaysAfterEnd + 1 - dayRange; i < addDaysAfterEnd + 1; i++) {
      chartDates.push(momentDate.clone().add(i, 'days'));
    }
    const utcStartDateTime = momentDate
      .clone()
      .add(addDaysAfterEnd - dayRange + 1, 'days')
      .utc()
      .toISOString();
    const utcEndDateTime = momentDate.clone().add(addDaysAfterEnd, 'days').utc().toISOString();
    forkJoin([
      this.chartService.getMaramataka().pipe(take(1)),
      this.chartService.getMaramatakaDays(utcStartDateTime, utcEndDateTime).pipe(take(1)),
      this.chartService.getMatauranga(utcStartDateTime, utcEndDateTime).pipe(take(1)),
    ]).subscribe(
      ([maramatakaResult, maramatakaDaysResult, mataurangaApiResult]: [
        MaramatakaApiResult,
        MaramatakaDaysApiItem[],
        MataurangaApiResult
      ]) => {
        this.energyPhaseDates = chartDates.map((date) => date.format('DD/MM/YYYY'));
        this.chartDateArray = chartDates.map((date) => date.date());
        this.moonPhaseImages = [];
        this.chartData = [];
        for (let i = 0; i < 6; i++) {
          const actions: string[] = [];
          const lunarDayData = maramatakaDaysResult[i];
          const lunarDayId = lunarDayData.maramataka_lunar_month_day_id;
          const maramatakaDayData = maramatakaResult.lunarMonthDays.find(
            (m: MaramatakaApiResultLunarDays) => m.id.toString() === lunarDayId
          );
          const dayMatauranga = mataurangaApiResult.daysWithMatauranga.find(
            (d: MataurangaApiResultLunar) => d.maramataka_lunar_month_day_id === lunarDayId
          );
          if (dayMatauranga) {
            dayMatauranga.matauranga.forEach((mataurangaId: number) => {
              const source = mataurangaApiResult.matauranga[mataurangaId];
              if (source) {
                // Avoid double-ups
                const titleMaori = source.title_reo_maori;
                const titleEnglish = source.title_english === titleMaori ? null : source.title_english;
                const eventTypeMaori = source.event_type_reo_maori === titleMaori ? null : source.event_type_reo_maori;
                const eventTypeEnglish =
                  source.event_type_english === eventTypeMaori || source.event_type_english === titleEnglish
                    ? null
                    : source.event_type_english;
                let maori = '';
                if (titleMaori) {
                  maori += `${titleMaori}`;
                }
                if (eventTypeMaori) {
                  if (titleMaori) {
                    maori += ` (${eventTypeMaori})`;
                  } else {
                    maori += `${eventTypeMaori}`;
                  }
                }
                let english = '';
                if (titleEnglish) {
                  english += `${titleEnglish}`;
                }
                if (eventTypeEnglish) {
                  if (titleEnglish) {
                    english += ` (${eventTypeEnglish})`;
                  } else {
                    english += `${eventTypeEnglish}`;
                  }
                }
                actions.push(`${maori}${english.length > 0 ? ' / ' : ''}${english}`);
              }
            });
          }
          // ecliptic_longitude can be 0. Force id to 1
          // otherwise math.ceil keeps id in 1-30 range
          const moonPhaseImageId = Math.max(Math.ceil((30 * lunarDayData.ecliptic_longitude) / 360), 1);
          this.moonPhaseImages.push(`image://./assets/moon_phases/moon_${moonPhaseImageId}.svg`);
          this.chartData.push({
            value: 100,
            energy: maramatakaDayData?.energy_text_english ?? '',
            actions,
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: this.barColors[i][0],
                },
                {
                  offset: 1,
                  color: this.getColorCode(maramatakaDayData?.energy_text_english ?? ''),
                },
              ]),
            },
          });
        }

        this.currentState = this.chartData[2].energy ? this.chartData[2].energy + ' Energy' : '';
        if (this.timeScale !== 0) {
          setTimeout(() => {
            this.setOptions();
            const chartElement1 = document.getElementById('chart1');
            if (chartElement1 != null) {
              const chart1 = getInstanceByDom(chartElement1 as HTMLDivElement | HTMLCanvasElement);
              if (chart1) {
                connect([chart1]);
              }
            }
          });
        }
      }
    );
  };
  getColorCode = (text: string) => {
    let enegryLevel: any = CommonConstants.ENERGY_LEVEL_COLORS.filter((value: any) => {
      return value?.level?.toLowerCase() === text?.toLowerCase();
    });

    return enegryLevel && enegryLevel.length > 0 ? enegryLevel[0].color : '';
  };
}
