import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SitesService } from './sitesService';
import { ClientService } from '@app/client/client.service';

@Injectable({
  providedIn: 'root',
})
export class LocationParamsService {
  originIdentifier: string;

  constructor(private sitesService: SitesService, private clientService: ClientService) {
    this.originIdentifier = this.clientService.getClientOriginIdentifier();
  }

  private getLocationParams(): Observable<{ [key: string]: string }> {
    return this.sitesService.getSelectedSite().pipe(
      switchMap((site) => {
        const locationParams: { [key: string]: string } = {
          origin_identifier: this.originIdentifier,
        };
        if (site?.id) {
          locationParams['site_id'] = site.id;
        }
        return [locationParams];
      })
    );
  }

  public getUrlWithParams(endpoint: string, additionalQueryParameters?: any): Observable<string> {
    return this.getLocationParams().pipe(
      map((locationParams) => {
        const allParameters = {
          ...locationParams,
          ...(additionalQueryParameters || {}),
        };
        const queryString = new URLSearchParams(allParameters).toString();
        let urlPath = endpoint;
        // This takes care of path parameters.
        Object.keys(allParameters).forEach((key) => {
          urlPath = urlPath.replace(`{${key}}`, allParameters[key]);
        });
        return `${urlPath}?${queryString}`;
      })
    );
  }
}
