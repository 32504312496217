import { Component } from '@angular/core';
import { FilterDrawerLayoutComponentBase } from '../../../classes/filter-drawer-layout-component.class';
import { MapviewComponent } from '@app/pages/mapview/mapview.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-client-filter-drawer-layout',
  templateUrl: './filter-drawer-layout.component.html',
  styleUrls: ['./filter-drawer-layout.component.scss'],
})
export class default_FilterDrawerLayoutComponent extends FilterDrawerLayoutComponentBase {
  drawerGraphsStacked: boolean = true;
  hasStreamBranding: boolean = true;

  backgroundImageStyle = `url(/assets/clients/${
    environment.forceBrand || window.location.host.split(/\.|:/g)[0]
  }/background.png)`;

  protected getCheckboxFields(): string[] {
    return [];
  }

  protected getMultipleSelectFields(): string[] {
    return ['mapFeatures', 'matauranga', 'monitoring', 'observation'];
  }

  public getDrawerLabel(): string {
    if (this.type === 'maramataka') {
      return 'Event Details';
    } else if (this.type === 'mapview') {
      return 'Environmental Data';
    }
    return '';
  }

  public getFilterLabel(): string {
    if (this.type === 'mapview') {
      return 'Layers';
    }

    return 'Filters';
  }

  public getCSV(): void {
    // Retrieve filtered list and create blob with data
    const blob = new Blob([MapviewComponent.csvData], { type: 'text/csv;charset=utf-8;' });

    // Create anchor tag and initiate download
    const invisibleAnchor = document.createElement('a');
    invisibleAnchor.href = URL.createObjectURL(blob);
    invisibleAnchor.setAttribute('download', `observations-${new Date().toLocaleDateString()}.csv`);
    invisibleAnchor.click();
  }
}
