import { Component, Input, OnInit } from '@angular/core';
import { APIWithLoaderHelper } from '@app/data/helpers/apiWithLoaderHelper';
import { Observable } from 'rxjs';
import { LocationParamsService } from '@app/data/services/locationParamsService';
import { URLConstants } from '@app/core/constants/URLConstants';
import { switchMap } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-rongo-observation-summary',
  templateUrl: './rongo-observation-summary.component.html',
  styleUrls: ['./rongo-observation-summary.component.scss'],
})
export class RongoObservationSummaryComponent implements OnInit {
  @Input() observation: any;

  constructor(
    private apiWithLoaderHelper: APIWithLoaderHelper,
    private locationParamsService: LocationParamsService,
    private sanitizer: DomSanitizer
  ) {}

  public sanitizedLayoutHtml: SafeHtml | undefined;

  ngOnInit() {
    if (!this.observation?.id) {
      return;
    }
    this.getObservationLayout(this.observation.id).subscribe((layout) => {
      this.sanitizedLayoutHtml = this.sanitizer.bypassSecurityTrustHtml(layout);
    });
  }

  private getObservationLayout(observationId: number): Observable<any> {
    return this.locationParamsService.getUrlWithParams(`/rongo/observation/${observationId}/layout`).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.get<any>(urlWithParams);
      })
    );
  }
}
