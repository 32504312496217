import { Component } from '@angular/core';
import { WidgetComponentBase } from '../widget.class';
import { WeatherChart } from '../charts/weatherChart';
import { FilterService } from '@app/data/services/filterService';
import { ChartService } from '@app/data/services/chartService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';
import { Site, SitesService } from '@app/data/services/sitesService';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-widget-forcasted-weather',
  templateUrl: './widget-forcasted-weather.component.html',
  styleUrls: ['./widget-forcasted-weather.component.scss'],
})
export class WidgetForcastedWeatherComponent extends WidgetComponentBase {
  weatherForecastOption: EChartsOption = {};
  site?: Site | null;
  siteId?: string | null;

  constructor(
    private weatherChart: WeatherChart,
    protected filterService: FilterService,
    protected chartService: ChartService,
    protected clientService: ClientService,
    protected sitesService: SitesService
  ) {
    super(filterService, chartService, clientService);
    sitesService.getSelectedSite().subscribe((site: Site | null) => {
      this.site = site;
      if (!!site && this.siteId !== site?.id) {
        this.siteId = site?.id;
        this.onFilterUpdate();
      }
    });
  }

  onFilterUpdate(): void {
    if (!this.site) return;

    this.chartService
      .getForecastedWeatherData(this.site.latitude || 0, this.site.longitude || 0)
      .pipe(take(1))
      .subscribe((data) => {
        this.weatherForecastOption = this.weatherChart.getForecastedWeatherChart(data);
      });
  }
}
