<span class="{{ standaloneClass }} {{ colorMode }} {{ screenPosition }}" *ngIf="!isHidden">
  <p class="site-name" *ngIf="selectedSite && showSite">{{ selectedSite?.name }}</p>
  <button
    mat-icon-button
    aria-label="Change Site"
    class="change-site-button"
    (click)="openSiteSelectionDialog()"
    *ngIf="siteList.length > 1"
    title="Change Site"
  >
    <mat-icon>arrow_downward</mat-icon>
  </button>
</span>

<!-- Site Selection Dialog Template -->
<ng-template #siteSelectionDialog>
  <div mat-dialog-container>
    <h4 mat-dialog-title>Select Site</h4>
    <div mat-dialog-content>
      <form [formGroup]="siteFormGroup">
        <mat-radio-group class="site-options" formControlName="sites">
          <mat-radio-button
            *ngFor="let site of siteList"
            [value]="site"
            [checked]="site.id === tempSelectedSite?.id"
            (change)="onRadioChange(site)"
            class="site-option"
          >
            {{ site.name }}
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="onCancelSelection()">Cancel</button>
      <button mat-raised-button color="primary" (click)="onSelectSite()" cdkFocusInitial>Save Selection</button>
    </div>
  </div>
</ng-template>
