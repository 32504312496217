import { Injectable } from '@angular/core';
import { SubscribeManager, SubscribeManagerUnsubscribe } from '@app/data/class/subscribe-manager.class';
import moment from 'moment';

export type FilterSetupType = 'taiao' | 'sitehealth' | 'maramataka' | 'mapview';
export type FilterSetupDomain = 'overview' | 'hau' | 'wai' | 'moana' | 'whenua';
export type FilterSetupSensor = 'air' | 'waterway' | 'groundwater' | 'coastal';
export type FilterDrawMapFeatures = {
  [domain: string]: {
    id: string;
    display: string;
  }[];
};
export type FilterSetupBaseMap = 'orthophoto' | 'topographical';
export type FilterSetupDataLayer = 'odour' | 'particulate' | 'pm10' | 'pm2-5';
export type FilterSetupMatauranga = 'hau' | 'wai' | 'moana' | 'whenua';
export type FilterSetupMonitoring = 'hau' | 'wai' | 'moana' | 'whenua' | 'waste';
export type FilterSetupObservation = 'text' | 'multimedia';
export type FilterSetupCalendarType = 'day' | 'week' | 'month';

export interface FilterSetup {
  [key: string]: any;
  domain: FilterSetupDomain;
  fromDate: Date;
  toDate: Date;
  searchTerm: string;
  sensor: FilterSetupSensor;
  mapFeatures: string[];
  baseMap: FilterSetupBaseMap;
  dataLayer: FilterSetupDataLayer;
  matauranga: FilterSetupMatauranga[];
  monitoring: FilterSetupMonitoring[];
  observation: FilterSetupObservation[];
  calendarType: FilterSetupCalendarType;
  calendarTypeYear: number;
  calendarTypeMonth: number;
  calendarTypeWeek: number;
  calendarTypeDay: number;
}

export interface FilterSetupSubscribe {
  filters: FilterSetup;
  previousFilters: FilterSetup;
}

type FilterSetupKeys = (keyof FilterSetup)[];

export type FilterSubscribeCallback = (setup: FilterSetupSubscribe) => void;
export type FilterUnsubscribe = () => void;

@Injectable({
  providedIn: 'root',
})
export class FilterService extends SubscribeManager<FilterSetupSubscribe, [FilterSetupKeys?]> {
  public defaultMapFilters = ['boundaries', 'observation'];
  private filters: FilterSetup = {
    domain: 'overview',
    fromDate: moment().subtract(3, 'month').toDate(),
    toDate: new Date(),
    searchTerm: '',
    sensor: 'groundwater',
    mapFeatures: ['monitor', 'observation'],
    baseMap: 'orthophoto',
    dataLayer: 'odour',
    matauranga: ['hau', 'moana', 'wai', 'whenua'],
    monitoring: ['hau', 'moana', 'wai', 'waste', 'whenua'],
    observation: ['multimedia', 'text'],
    calendarType: 'day',
    calendarTypeYear: moment().year(),
    calendarTypeMonth: moment().month(),
    calendarTypeWeek: 1,
    calendarTypeDay: moment().date(),
  };
  private previousFilters: FilterSetup = this.getFilters();
  private changedFilters: FilterSetupKeys = [];

  public subscribe(callback: FilterSubscribeCallback, optionalFilters?: FilterSetupKeys): SubscribeManagerUnsubscribe {
    return super.subscribe((setup: FilterSetupSubscribe) => {
      if (!Array.isArray(optionalFilters) || optionalFilters.some((x) => this.changedFilters.includes(x))) {
        callback(setup);
      }
    });
  }

  public getFilters(): FilterSetup {
    return { ...this.filters };
  }

  public setFilters(filters: Partial<FilterSetup>): void {
    console.log({ filters });
    this.changedFilters = (Object.keys(filters) as FilterSetupKeys).reduce((accumulator, key) => {
      let changed: boolean = false;

      if (Array.isArray(filters[key])) {
        changed =
          (filters[key] as string[]).length !== (this.filters[key] as string[]).length ||
          (filters[key] as string[]).some((x) => !(this.filters[key] as string[]).includes(x));
      } else {
        changed = filters[key] !== this.filters[key];
      }

      if (changed) {
        accumulator.push(key);
      }

      return accumulator;
    }, [] as FilterSetupKeys);

    this.filters = { ...this.filters, ...filters };

    this.pushChange({ filters: { ...this.filters }, previousFilters: { ...this.previousFilters } });

    this.previousFilters = this.getFilters();
  }
}
