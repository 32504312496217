import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Site, SitesService } from '@app/data/services/sitesService';
import { AuthService } from '@app/data/services/authService';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Event, Router } from '@angular/router';

@Component({
  selector: 'app-site-selector',
  templateUrl: './site-selector.component.html',
  styleUrls: ['./site-selector.component.scss'],
})
export class SiteSelectorComponent {
  @Input() colorMode: string = 'light';
  @Input() screenPosition: string = 'top';
  @Input() showSite: boolean = false;

  siteList: Site[] = [];
  selectedSite: Site | null = null;
  tempSelectedSite: Site | null = null;
  siteFormGroup: FormGroup = new FormGroup({});
  isHidden: boolean = true;
  standaloneClass: string = 'site-list-wrapper';

  @ViewChild('siteSelectionDialog') siteSelectionDialog!: TemplateRef<any>;
  private siteSelectionDialogRef!: MatDialogRef<TemplateRef<any>>;

  constructor(
    private authService: AuthService,
    private sitesService: SitesService,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    // Get the sites from the user
    this.authService.getLoggedInUser().subscribe(({ sites }) => {
      if (sites && sites.length > 0) {
        this.siteList = sites;
        this.siteFormGroup = this.formBuilder.group({
          sites: [sites],
        });

        // If there is no selected site, set the first site as the selected site
        if (!this.selectedSite) {
          this.sitesService.setSelectedSite(sites[0]);
          this.selectedSite = sites[0];
        }
      }
    });

    // Get the selected site from the service
    this.sitesService.getSelectedSite().subscribe((site) => {
      this.selectedSite = site;
      this.tempSelectedSite = site;
    });

    this.isHidden = window.location.pathname.includes('admin');

    this.router.events.subscribe((event: Event) => {
      this.isHidden = window.location.pathname.includes('admin');
    });
  }

  ngOnInit() {
    this.standaloneClass = this.showSite ? 'site-list-wrapper' : '';
  }

  openSiteSelectionDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';

    this.tempSelectedSite = this.selectedSite;
    this.siteSelectionDialogRef = this.dialog.open(this.siteSelectionDialog, dialogConfig);

    this.siteSelectionDialogRef.afterClosed().subscribe((result) => {
      if (!result) return;

      if (result?.id !== this.selectedSite?.id) {
        this.sitesService.setSelectedSite(result);
      }
    });
  }

  onRadioChange(site: Site) {
    this.tempSelectedSite = site;
  }

  onSelectSite(): void {
    this.siteSelectionDialogRef.close(this.tempSelectedSite);
  }

  onCancelSelection(): void {
    this.siteSelectionDialogRef.close(null);
  }
}
