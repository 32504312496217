import { OnDestroy, Directive } from '@angular/core';
import { SubscribeManager } from './subscribe-manager.class';
import { Observable } from 'rxjs';

@Directive()
export abstract class UnsubscribeManager implements OnDestroy {
  private unsubscribe: Array<() => void> = [];

  public subscribe<T = any>(source: SubscribeManager, callback: (observee: T) => void, ...args: any[]) {
    this.unsubscribe.push(source.subscribe(callback, ...args));
  }

  public subscribeOnObservable<A = any>(observable: Observable<A>, callback: (observee: A) => void) {
    const subscription = observable.subscribe({ next: callback });

    this.unsubscribe.push(subscription.unsubscribe.bind(subscription));
  }

  public ngOnDestroy(): void {
    this.unsubscribe.map((x) => x());
  }
}
