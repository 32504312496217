import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';

@Component({
  selector: 'legacy-app-widget-taiao-scorecard',
  templateUrl: './widget-taiao-scorecard.component.html',
  styleUrls: ['./widget-taiao-scorecard.component.scss'],
})
export class WidgetTaiaoScorecardComponent extends LegacyWidgetComponentBase {
  categories: any[] = [];

  onFilterUpdate(): void {
    if (!this.fromDate || !this.toDate) {
      return;
    }

    // TODO: called from the base
    this.legacyDataService.getTaiaoScorecard(this.fromDate, this.toDate, this.client).subscribe((result) => {
      this.categories = [];

      result.forEach(
        (item: {
          category: string;
          color: string;
          values: {
            name: string;
            value: number;
          }[];
        }) => {
          const mapped = item.values.map((v) => ({
            name: v.name,
            options: {
              legend: {
                show: false,
              },
              xAxis: {
                type: 'value',
                show: false,
              },
              yAxis: {
                type: 'category',
                show: false,
              },
              grid: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              },
              // Scorecard values go from 1 - 10 however we're breaking them up
              // on a 1 - 5 scale here
              series: [2, 4, 6, 8, 10].map((cutoff, index) => ({
                type: 'bar',
                stack: 'total',
                label: {
                  show: false,
                },
                emphasis: {
                  focus: 'series',
                },
                data: [
                  {
                    value: 100,
                    itemStyle: {
                      color: cutoff <= v.value ? item.color : '#F6F6F6',
                      opacity: cutoff <= v.value ? 1 / (index + 1) : 1,
                    },
                  },
                ],
              })),
            },
          }));

          this.categories.push({
            name: item.category,
            charts: mapped,
          });
        }
      );
    });
  }
}
