import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; // @TODO: This module is only used for the slide-up menu when clicking on the username
import { MaterialModule } from '@app/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { WidgetsModule } from '@app/widgets/widget.module';

/**
 * Unfortunately we need to also import the client conponents + keep AOT compilation
 */
import { waikatotainui_FilterDrawerLayoutComponent } from './filter-drawer-layout/filter-drawer-layout.component';
import { waikatotainui_MainLayoutComponent } from './main-layout/main-layout.component';
import { waikatotainui_TaiaoDashboardLayoutComponent } from './taiao-dashboard-layout/taiao-dashboard-layout.component';
import { SharedModule } from '@app/@shared';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    WidgetsModule,
    SharedModule,
  ],
  declarations: [
    waikatotainui_FilterDrawerLayoutComponent,
    waikatotainui_MainLayoutComponent,
    waikatotainui_TaiaoDashboardLayoutComponent,
  ],
})
export class ClientWaikatotainuiModule {}
