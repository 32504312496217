import { AfterViewInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, Directive } from '@angular/core';
import { ClientConstantType } from '../client.constant';
import { ClientService } from '../client.service';

type ClientResolverLayoutType = 'main' | 'filter-drawer' | 'taiao-dashboard';

@Directive()
export abstract class ClientResolverBase implements AfterViewInit {
  @ViewChild('layoutView', { read: ViewContainerRef }) layoutView!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private clientService: ClientService) {}

  private async buildComponent() {
    const client: ClientConstantType = this.clientService.getClient();

    /**
     * Component loading is dynamic, so there be some dragons here to keep AOT happy
     */
    const componentName = `${client.toLowerCase()}_${this.getLayoutType()
      .split('-')
      .map((x: string) => x.substring(0, 1).toUpperCase() + x.substring(1).toLowerCase())
      .join('')}LayoutComponent`;
    let componentStruct;
    try {
      componentStruct = await import(
        `../clients/${client}/${this.getLayoutType()}-layout/${this.getLayoutType()}-layout.component`
      );
    } catch (e) {
      componentStruct = await import(
        `../clients/default/${this.getLayoutType()}-layout/${this.getLayoutType()}-layout.component`
      );
    }
    const resolvedComponent = this.componentFactoryResolver.resolveComponentFactory(componentStruct[componentName]);

    if (this.layoutView) {
      this.layoutView.clear();
      this.layoutView.createComponent(resolvedComponent);
    }
  }

  protected abstract getLayoutType(): ClientResolverLayoutType;

  ngAfterViewInit(): void {
    this.buildComponent();
  }
}
