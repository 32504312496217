<div class="clearfix flex-clearfix">
  <div class="card customCard mb-3 animate__animated animate__fadeIn animate__delay-1s animate__duration-2s">
    <div class="card-body">
      <h5 class="card-title card-title-main mb-3">
        {{ title }}
        <span class="icon"><i class="fas fa-ellipsis-h"></i></span>
      </h5>
      <ng-content></ng-content>
    </div>
  </div>
</div>
