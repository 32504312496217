import { Injectable } from '@angular/core';
import { URLConstants } from '@app/core/constants/URLConstants';
import { Observable } from 'rxjs';
import { APIWithLoaderHelper } from '../helpers/apiWithLoaderHelper';

import { AlertSearchParams } from '../interface/alerts';
import { LocationParamsService } from './locationParamsService';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private apiWithLoaderHelper: APIWithLoaderHelper, private locationParamsService: LocationParamsService) {}

  search(params: AlertSearchParams): Observable<any[]> {
    return this.locationParamsService.getUrlWithParams(URLConstants.getAlertSearch).pipe(
      switchMap((urlWithParams) => {
        return this.apiWithLoaderHelper.post<any[]>(urlWithParams, params, {}, false);
      })
    );
  }
}
