import { environment } from '@env/environment';
import { get } from 'jquery';

const { keyBy, padStart, clone } = require('lodash');
const moment = require('moment-timezone');

const ENERGY_LEVEL_COLORS = {
  Low: '#870086',
  Medium: '#9a2e9a',
  High: '#c375c9',
  'Very High': '#ad53aa',
};

const STAR_ASSET_PATH = [
  '/assets/stars/aries.svg',
  '/assets/stars/canis-major.svg',
  '/assets/stars/persues.svg',
  '/assets/stars/hydra.svg',
  '/assets/stars/venus.svg',
  '/assets/stars/leo.svg',
  '/assets/stars/centaurus.svg',
  '/assets/stars/scorpius.svg',
  '/assets/stars/scorpius.svg',
  '/assets/stars/aquila.svg',
  '/assets/stars/pegasus.svg',
  '/assets/stars/pegasus.svg',
  '/assets/stars/aries.svg',
];

// Takes in two date parameters and the data we are filtering, returns data within the
// specified date range
export function filterDataByDate(startDate: Date, endDate: Date, data: Array<any>) {
  // try catch
  return data.filter((a) => {
    var date = new Date(a.date);
    return date >= startDate && date <= endDate;
  });
}

export function unixToDateConverter(unix: any) {
  const unixTime = unix;
  const date = new Date(unixTime * 1000);
  return date.toLocaleDateString('en-US');
}

/**
 * OK so for now we are going to assume that maramataka is midnight to midnight
 * If this changes, the logic here will need to be updated.
 * This emulates the way the maramataka wheel expects to see the day data.
 * We are doing this so that we don't have to rewrite the front and back end at the same time.
 * The front end needs a rewrite, but we can't do that right now.
 * So it takes lots of data from APIs and mashes it into something like this:
 * [
    {
        "lunarPhase": "22",
        "lunarPhaseEnergy": "High",
        "lunarPhaseEnergyColour": "#c375c9",
        "lunarPhaseMedia": "assets/moon_phases/moon_22.svg",
        "starNameEng": "Alpha Aquilae",
        "starMedia": "assets/stars/aquila.svg",
        "lunarMonth": "10",
        "sunriseTime": "0635 ",
        "sunsetTime": "1834",
        "twilightAmTime": "504",
        "twilightPmTime": "2004",
        "hightideAmTime": "10:22",
        "hightidePmTime": "22:50",
        "lowtideAmTime": "4:08",
        "lowtidePmTime": "16:32",
        "moonriseTime": "6",
        "moonsetTime": "1611",
        "lunarStageEng": "",
        "lunarStageReo": "Tangaroa-ā-roto",
        "starZenithTime": "2:51",
        "starZenithAzimuth": "180",
        "starNameReo": "Poutūterangi",
        "starDescriptionEng": "Te Ngahuru o Poutūterangi\nStarts on the day of the new moon when the pre-dawn rising of Altair in Aquila",
        "starDescriptionReo": "Te Ngahuru o Poutūterangi\nI te takiwā o Poutū-te-rangi/Māehe, ko Poutūterangi te ingoa Māori o Altair kei te kāhui o Aquila",
        "seasonNameEng": "Autumn",
        "seasonNameReo": "Ngahuru",
        "seasonDescriptionEng": "Autumn. From the New Moon in March to the last Waning Crescent Moon in May/June",
        "seasonDescriptionReo": "Ngahuru\nMai i Te Ngahuru o Poutūterangi ki te mutunga o Te Ngahuru mā rua o Haki Haratua",
        "date": "2022-03-24",
        "slices": [
            {
                "tidePosition": 0.5,
                "sunPosition": 0,
                "observations": [],
                "monitoring": {
                    "surfaceWater": [],
                    "groundWater": []
                },
                "matauranga": []
            },
            {
                "tidePosition": 0.6666666666666667,
            // ... 24 slices total
        ]
    },
    {
        "lunarPhase": "23",
    // all the days in the requested date range.
 */
export function mashMaramatakaDayData(
  maramataka: any,
  maramatakaDates: any,
  matauranga: any,
  astrologicalAndMeteorologicalEvents: any,
  environmentalData: any,
  observations: any
) {
  const maramatakaDays = keyBy(maramataka.lunarMonthDays, 'id');
  const maramatakaMonths = keyBy(maramataka.months, 'id');
  const maramatakaSeasons = keyBy(maramataka.seasons, 'id');
  maramataka.stars.sort((a: any, b: any) => a.id - b.id);
  const maramatakaStars = keyBy(
    maramataka.stars.map((star: any, index: Number) => ({
      ...star,
      index,
    })),
    'id'
  );
  const mataurangaMapByDate = matauranga.daysWithMatauranga.reduce(
    (accumulator: Object, day: any) => ({
      ...accumulator,
      [moment(day.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD 12:00:00')]: day.matauranga,
    }),
    {}
  );
  const eventsByDate = astrologicalAndMeteorologicalEvents.reduce(
    (accumulator: Object, event: any) => ({
      ...accumulator,
      [`${moment(event.gregorian_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD')}-${event.event_type}`]: event,
    }),
    {}
  );
  const observationsByDate = observations
    .map((observation: any) => ({
      ...observation,
      dateHour: moment(observation.date).tz('Pacific/Auckland').format('YYYY-MM-DD HH:00:00'),
    }))
    .reduce(
      (accumulator: Object, observation: any) => ({
        ...accumulator,
        [observation.dateHour]: [...(accumulator[observation.dateHour] || []), observation],
      }),
      {}
    );
  const analytesById = keyBy(environmentalData.analytes, 'id');
  const environmentalDataSourcesById = keyBy(environmentalData.environmentalDataSources, 'id');
  const environmentalDataByHour = (environmentalData.data || []).reduce((accumulator: Object, data: any) => {
    const domain = environmentalDataSourcesById[data.environmental_data_source_id].domain.toLowerCase();
    const dateTime = moment(data.date_time).format('YYYY-MM-DD 12:00:00');
    return {
      ...accumulator,
      [dateTime]: {
        ...accumulator[dateTime],
        [domain]: {
          sources: {
            ...((accumulator[dateTime] || {})[domain]?.sources || {}),
            [`${data.environmental_data_source_id}-${moment(data.date_time).format('HH:mm')}`]: 1,
          },
          data: [
            ...((accumulator[dateTime] || {})[domain]?.data || []),
            {
              ...data,
              analyte: analytesById[data.analyte_id],
              environmentalDataSource: environmentalDataSourcesById[data.environmental_data_source_id],
            },
          ],
        },
      },
    };
  }, {});
  // We have to do a slices array.
  const slicesTemplate: any[] = [];
  for (let i = 0; i < 24; i++) {
    slicesTemplate.push({
      tidePosition: 0,
      sunPosition: 0,
      monitoring: {
        surfaceWater: [],
        groundWater: [],
      },
      matauranga: [],
      observations: [],
    });
  }

  const formatEventDate = (event: any) => {
    if (event) {
      return moment(event.gregorian_datetime).tz('Pacific/Auckland').format('HH:mm');
    }
    return null;
  };

  const formatDateAsHoursMinutes = (date: Date) => {
    if (date) {
      return moment(date).tz('Pacific/Auckland').format('HH:mm');
    }
    return null;
  };

  // Put it all together.
  return maramatakaDates
    .map((date: any) => ({
      ...date,
      date: moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD'),
      year: parseInt(moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY'), 10),
      slices: clone(slicesTemplate),
    }))
    .map((date: any) => ({
      date: moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD'),
      hightideAmTime: formatEventDate(eventsByDate[`${date.date}-hightide_am`]),
      hightidePmTime: formatEventDate(eventsByDate[`${date.date}-hightide_pm`]),
      lowtideAmTime: formatEventDate(eventsByDate[`${date.date}-lowtide_am`]),
      lowtidePmTime: formatEventDate(eventsByDate[`${date.date}-lowtide_pm`]),
      lunarMonth: maramatakaMonths[date.maramataka_month_id].month_of_year,
      lunarPhaseEnergy: maramatakaDays[date.maramataka_lunar_month_day_id].energy_text_english,
      lunarPhase: maramatakaDays[date.maramataka_lunar_month_day_id].day_of_month,
      lunarPhaseEnergyColour:
        ENERGY_LEVEL_COLORS[maramatakaDays[date.maramataka_lunar_month_day_id].energy_text_english],
      // This image is not related to the phase - we have 30 to use and we have to pick the closest.
      // For full moon and new moon it's exact (see the generation code)
      // For the others it's the closest.
      lunarPhaseMedia: `/assets/moon_phases/moon_${Math.ceil((date.ecliptic_longitude / 360) * 30) || 1}.svg`,
      lunarStageEng:
        maramatakaDays[date.maramataka_lunar_month_day_id].name_english === '0'
          ? '-'
          : maramatakaDays[date.maramataka_lunar_month_day_id].name_english,
      lunarStageReo: maramatakaDays[date.maramataka_lunar_month_day_id].name_reo_maori,
      moonriseTime: formatEventDate(eventsByDate[`${date.date}-moonrise`]),
      moonsetTime: formatEventDate(eventsByDate[`${date.date}-moonset`]),
      seasonDescriptionEng:
        maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].description_english,
      seasonDescriptionReo:
        maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].description_reo_maori,
      seasonNameEng: maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].name_english,
      seasonNameReo: maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].name_reo_maori,
      slices: date.slices.map((slice: any, index: number) => ({
        ...slice,
        startDateTime: moment(date.gregorian_start_datetime)
          .tz('Pacific/Auckland')
          .add(index, 'hours')
          .format('YYYY-MM-DD HH:mm:ss'),
        endDateTime: moment(date.gregorian_start_datetime)
          .tz('Pacific/Auckland')
          .add(index + 1, 'hours')
          .subtract(1, 'seconds')
          .format('YYYY-MM-DD HH:mm:ss'),
        sunPosition: Math.abs(
          1 -
            Math.abs(
              parseInt(
                moment(eventsByDate[`${date.date}-sunrise`]?.gregorian_datetime).tz('Pacific/Auckland').format('HH')
              ) -
                (index % 24)
            ) /
              12
        ),
        tidePosition: Math.abs(1 - (index % 12) / 6),
        monitoring: environmentalDataByHour[`${date.date} ${padStart(index, 2, '0')}:00:00`] || {},
        observations: (observationsByDate[`${date.date} ${padStart(index, 2, '0')}:00:00`] || []).map(
          (observation: any) => ({
            id: observation.id,
            media: observation.media.length > 0 ? 'multimedia' : 'text',
            type: 'observation',
            originalItem: observation,
          })
        ),
        matauranga: (mataurangaMapByDate[`${date.date} ${padStart(index, 2, '0')}:00:00`] || []).map(
          (mataurangaIndex: number) => ({
            id: matauranga.matauranga[mataurangaIndex].id,
            media: matauranga.matauranga[mataurangaIndex].mediafile_path,
            domain: matauranga.matauranga[mataurangaIndex].domain_type,
            type: 'matauranga',
            originalItem: matauranga.matauranga[mataurangaIndex],
            date: date.date,
          })
        ),
      })),
      starDescriptionEng: maramatakaStars[date.maramataka_star_id].description_english,
      starDescriptionReo: maramatakaStars[date.maramataka_star_id].description_reo_maori,
      starNameEng: maramatakaStars[date.maramataka_star_id].name_english,
      starNameReo: maramatakaStars[date.maramataka_star_id].name_reo_maori,
      starMedia: STAR_ASSET_PATH[maramatakaStars[date.maramataka_star_id].index],
      starZenithAzimuth: date.star_azimuth,
      starZenithTime: formatDateAsHoursMinutes(date.star_zenith),
      sunsetTime: formatEventDate(eventsByDate[`${date.date}-sunset`]),
      sunriseTime: formatEventDate(eventsByDate[`${date.date}-sunrise`]),
      twilightAmTime: formatEventDate(eventsByDate[`${date.date}-twilight_am`]),
      twilightPmTime: formatEventDate(eventsByDate[`${date.date}-twilight_pm`]),
    }));
}

const getDirectionLabel = (degrees: number) => {
  if (11.25 <= degrees && degrees < 33.75) {
    return 'NNE';
  } else if (33.75 <= degrees && degrees < 56.25) {
    return 'NE';
  } else if (56.25 <= degrees && degrees < 78.75) {
    return 'ENE';
  } else if (78.75 <= degrees && degrees < 101.25) {
    return 'E';
  } else if (101.25 <= degrees && degrees < 123.75) {
    return 'ESE';
  } else if (123.75 <= degrees && degrees < 146.25) {
    return 'SE';
  } else if (146.25 <= degrees && degrees < 168.75) {
    return 'SSE';
  } else if (168.75 <= degrees && degrees < 191.25) {
    return 'S';
  } else if (191.25 <= degrees && degrees < 213.75) {
    return 'SSW';
  } else if (213.75 <= degrees && degrees < 236.25) {
    return 'SW';
  } else if (236.25 <= degrees && degrees < 258.75) {
    return 'WSW';
  } else if (258.75 <= degrees && degrees < 281.25) {
    return 'W';
  } else if (281.25 <= degrees && degrees < 303.75) {
    return 'WNW';
  } else if (303.75 <= degrees && degrees < 326.25) {
    return 'NW';
  } else if (326.25 <= degrees && degrees < 348.75) {
    return 'NNW';
  } else {
    return 'N';
  }
};

const getWindSpeedMedia = (windSpeed: number) => {
  if (!windSpeed || windSpeed < 6) return '/assets/icons/wind-icon1.svg';
  if (windSpeed < 12) return '/assets/icons/wind-icon2.svg';
  return '/assets/icons/wind-icon3.svg';
};

const getPrecipitationMedia = (precipitation: number) => {
  if (!precipitation || precipitation < 3) return '/assets/icons/rain-icon1.svg';
  if (precipitation < 6) return '/assets/icons/rain-icon2.svg';
  return '/assets/icons/rain-icon3.svg';
};

export function mashMaramatakaWeekData(
  maramataka: any,
  maramatakaDates: any,
  matauranga: any,
  environmentalData: any,
  observations: any,
  weather: any
) {
  const maramatakaDays = keyBy(maramataka.lunarMonthDays, 'id');
  const maramatakaMonths = keyBy(maramataka.months, 'id');
  const maramatakaSeasons = keyBy(maramataka.seasons, 'id');
  maramataka.stars.sort((a: any, b: any) => a.id - b.id);
  const maramatakaStars = keyBy(
    maramataka.stars.map((star: any, index: Number) => ({
      ...star,
      index,
    })),
    'id'
  );
  const mataurangaMapByDate = matauranga.daysWithMatauranga.reduce(
    (accumulator: Object, day: any) => ({
      ...accumulator,
      [moment(day.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD')]: day.matauranga,
    }),
    {}
  );
  const observationsByDate = observations
    .map((observation: any) => ({
      ...observation,
      date: moment(observation.date).tz('Pacific/Auckland').format('YYYY-MM-DD'),
    }))
    .reduce(
      (accumulator: Object, observation: any) => ({
        ...accumulator,
        [observation.date]: [...(accumulator[observation.date] || []), observation],
      }),
      {}
    );
  const analytesById = keyBy(environmentalData.analytes, 'id');
  const environmentalDataSourcesById = keyBy(environmentalData.environmentalDataSources, 'id');
  const environmentalDataByStartOfWeek = (environmentalData.data || []).reduce((accumulator: Object, data: any) => {
    const domain = environmentalDataSourcesById[data.environmental_data_source_id].domain.toLowerCase();
    const dataDate = moment(data.date_time).tz('Pacific/Auckland').startOf('isoWeek').format('YYYY-MM-DD');
    return {
      ...accumulator,
      [dataDate]: {
        ...accumulator[dataDate],
        [domain]: {
          ...((accumulator[dataDate] || {})[domain] || {}),
          sources: {
            ...((accumulator[dataDate] || {})[domain]?.sources || {}),
            [`${data.environmental_data_source_id}-${moment(data.date_time)
              .tz('Pacific/Auckland')
              .format('YYYY-MM-DD')}`]: 1,
          },
          data: [
            ...((accumulator[dataDate] || {})[domain]?.data || []),
            {
              ...data,
              analyte: analytesById[data.analyte_id],
              environmentalDataSource: environmentalDataSourcesById[data.environmental_data_source_id],
            },
          ],
        },
      },
    };
  }, {});
  const weatherByDate = weather.reduce(
    (accumulator: Object, weatherEntry: any) => ({
      ...accumulator,
      [moment(weatherEntry.dt * 1000)
        .tz('Pacific/Auckland')
        .format('YYYY-MM-DD')]: weatherEntry,
    }),
    {}
  );
  // We have to do a slices array.
  const slicesTemplate: any[] = [];
  for (let i = 0; i < 7; i++) {
    slicesTemplate.push({
      dateLabel: 0,
      windSpeed: 0,
      windSpeedMedia: 0,
      windDirection: 0,
      windDirectionLabel: '',
      precipitation: 0,
      precipitationMedia: 0,
      // Add day data
      monitoring: {},
      matauranga: [],
      observations: [],
    });
  }

  // Put it all together.
  return maramatakaDates
    .map((date: any) => ({
      ...date,
      date: moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD'),
      year: parseInt(moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY'), 10),
      slices: clone(slicesTemplate),
    }))
    .map((date: any) => ({
      date: moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD'),
      lunarMonth: maramatakaMonths[date.maramataka_month_id].month_of_year,
      lunarPhaseEnergy: maramatakaDays[date.maramataka_lunar_month_day_id].energy_text_english,
      lunarPhase: maramatakaDays[date.maramataka_lunar_month_day_id].day_of_month,
      lunarPhaseEnergyColour:
        ENERGY_LEVEL_COLORS[maramatakaDays[date.maramataka_lunar_month_day_id].energy_text_english],
      // This image is not related to the phase - we have 30 to use and we have to pick the closest.
      // For full moon and new moon it's exact (see the generation code)
      // For the others it's the closest.
      lunarPhaseMedia: `/assets/moon_phases/moon_${Math.ceil((date.ecliptic_longitude / 360) * 30) || 1}.svg`,
      lunarStageEng:
        maramatakaDays[date.maramataka_lunar_month_day_id].name_english === '0'
          ? '-'
          : maramatakaDays[date.maramataka_lunar_month_day_id].name_english,
      lunarStageReo: maramatakaDays[date.maramataka_lunar_month_day_id].name_reo_maori,
      seasonDescriptionEng:
        maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].description_english,
      seasonDescriptionReo:
        maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].description_reo_maori,
      seasonNameEng: maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].name_english,
      seasonNameReo: maramatakaSeasons[maramatakaMonths[date.maramataka_month_id].maramataka_season_id].name_reo_maori,
      slices: date.slices
        .map((slice: any, index: number) => ({
          ...slice,
          sliceDate: moment(date.date).add(index, 'days'),
          // Wind is in metres per second, we want km/h
          windSpeed:
            ((weatherByDate[moment(date.date).add(index, 'days').format('YYYY-MM-DD')]?.wind_speed || 0) * 60 * 60) / // get to metres per hour
            1000, // metres to kilometers
        }))
        .map((slice: any, index: number) => ({
          ...slice,
          windSpeedMedia: weatherByDate[slice.sliceDate.format('YYYY-MM-DD')] ? getWindSpeedMedia(slice.windSpeed) : '',
          windDirection: weatherByDate[slice.sliceDate.format('YYYY-MM-DD')]?.wind_deg || 0,
          windDirectionLabel: weatherByDate[slice.sliceDate.format('YYYY-MM-DD')]
            ? getDirectionLabel(weatherByDate[slice.sliceDate.format('YYYY-MM-DD')].wind_deg)
            : '',
          precipitation: Math.ceil(weatherByDate[slice.sliceDate.format('YYYY-MM-DD')]?.rain_1h || 0),
          precipitationMedia: weatherByDate[slice.sliceDate.format('YYYY-MM-DD')]
            ? getPrecipitationMedia(weatherByDate[slice.sliceDate.format('YYYY-MM-DD')].rain_1h)
            : '',
          startDateTime: slice.sliceDate.format('YYYY-MM-DD HH:mm:ss'),
          endDateTime: slice.sliceDate.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          monitoring: index == 2 ? environmentalDataByStartOfWeek[date.date] || {} : {},
          observations: (observationsByDate[slice.sliceDate.format('YYYY-MM-DD')] || []).map((observation: any) => ({
            id: observation.id,
            media: observation.media.length > 0 ? 'multimedia' : 'text',
            type: 'observation',
            originalItem: observation,
          })),
          matauranga: (mataurangaMapByDate[slice.sliceDate.format('YYYY-MM-DD')] || []).map(
            (mataurangaIndex: number) => ({
              id: matauranga.matauranga[mataurangaIndex].id,
              media: matauranga.matauranga[mataurangaIndex].mediafile_path,
              domain: matauranga.matauranga[mataurangaIndex].domain_type,
              type: 'matauranga',
              originalItem: matauranga.matauranga[mataurangaIndex],
              date: slice.sliceDate.format('YYYY-MM-DD'),
            })
          ),
        })),
      starDescriptionEng: maramatakaStars[date.maramataka_star_id].description_english,
      starDescriptionReo: maramatakaStars[date.maramataka_star_id].description_reo_maori,
      starNameEng: maramatakaStars[date.maramataka_star_id].name_english,
      starNameReo: maramatakaStars[date.maramataka_star_id].name_reo_maori,
      starMedia: STAR_ASSET_PATH[maramatakaStars[date.maramataka_star_id].index],
      starZenithAzimuth: date.star_azimuth,
      starZenithTime: moment(date.star_zenith).tz('Pacific/Auckland').format('HH:mm'),
    }));
}

export function mashMaramatakaMonthData(
  maramataka: any,
  maramatakaDates: any,
  maramatakaStars: any,
  maramatakaMonthWeeks: any,
  matauranga: any,
  environmentalData: any,
  observations: any
) {
  const maramatakaDays = keyBy(maramataka.lunarMonthDays, 'id');
  const maramatakaMonths = keyBy(maramataka.months, 'id');
  const maramatakaSeasons = keyBy(maramataka.seasons, 'id');
  maramataka.stars.sort((a: any, b: any) => a.id - b.id);
  const maramatakaStarsById = keyBy(
    maramataka.stars.map((star: any, index: Number) => ({
      ...star,
      index,
    })),
    'id'
  );
  const maramatakaStarsByGregorianMonth = maramatakaStars
    .map((starMonth: any) => ({
      ...starMonth,
      startOfMonth: moment(starMonth.gregorian_start_datetime)
        .tz('Pacific/Auckland')
        .startOf('month')
        .format('YYYY-MM-DD'),
    }))
    .reduce(
      (accumulator: any, starMonth: any) => ({
        ...accumulator,
        [starMonth.startOfMonth]:
          !accumulator[starMonth.startOfMonth] ||
          accumulator[starMonth.startOfMonth].gregorian_start_datetime < starMonth.gregorian_start_datetime
            ? starMonth
            : accumulator[starMonth.startOfMonth],
      }),
      {}
    );
  const mataurangaMapByDate = matauranga.daysWithMatauranga.reduce(
    (accumulator: Object, day: any) => ({
      ...accumulator,
      [moment(day.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD')]: day.matauranga,
    }),
    {}
  );
  const maramatakaMonthWeeksByMonth = maramatakaMonthWeeks
    .map((monthWeek: any) => ({
      ...monthWeek,
      startOfMonth: moment(monthWeek.gregorian_start_datetime)
        .tz('Pacific/Auckland')
        .startOf('month')
        .format('YYYY-MM-DD'),
    }))
    .reduce(
      (accumulator: any, monthWeek: any) => ({
        ...accumulator,
        [monthWeek.startOfMonth]: [...(accumulator[monthWeek.startOfMonth] || []), monthWeek],
      }),
      {}
    );

  const observationsByMonth = observations
    .map((observation: any) => ({
      ...observation,
      month: moment(observation.date).tz('Pacific/Auckland').format('YYYY-MM'),
    }))
    .reduce(
      (accumulator: Object, observation: any) => ({
        ...accumulator,
        [observation.month]: [...(accumulator[observation.month] || []), observation],
      }),
      {}
    );
  const analytesById = keyBy(environmentalData.analytes, 'id');
  const environmentalDataSourcesById = keyBy(environmentalData.environmentalDataSources, 'id');
  const environmentalDataByMonth = (environmentalData.data || []).reduce((accumulator: Object, data: any) => {
    const domain = environmentalDataSourcesById[data.environmental_data_source_id].domain.toLowerCase();
    const dataMonth = data.date_time.substring(0, 7);
    return {
      ...accumulator,
      [dataMonth]: {
        ...accumulator[dataMonth],
        [domain]: {
          sources: {
            ...((accumulator[dataMonth] || {})[domain]?.sources || {}),
            [`${data.environmental_data_source_id}-${moment(data.date_time).format('YYYY-MM-DD')}`]: 1,
          },
          data: [
            ...((accumulator[dataMonth] || {})[domain]?.data || []),
            {
              ...data,
              analyte: analytesById[data.analyte_id],
              environmentalDataSource: environmentalDataSourcesById[data.environmental_data_source_id],
            },
          ],
        },
      },
    };
  }, {});
  // We have to do a slices array.
  const sliceTemplate = {
    dateLabel: 0,
    hightideAmTime: '',
    hightidePmTime: '',
    lowtideAmTime: 0,
    lowtidePmTime: '',
    lunarMonth: 0,
    lunarPhase: 0,
    lunarPhaseEnergy: '',
    lunarPhaseEnergyColour: '',
    lunarPhaseMedia: '',
    lunarStageEng: '',
    lunarStageReo: '',
    moonriseTime: '',
    moonsetTime: '',
    seasonDescriptionEng: '',
    seasonDescriptionReo: '',
    seasonNameEng: '',
    seasonNameReo: '',
    starDescriptionEng: '',
    starDescriptionReo: '',
    starNameEng: '',
    starMedia: '',
    starNameReo: '',
    starZenithAzimuth: 0,
    starZenithTime: '',
    sunsetTime: '',
    sunriseTime: '',
    twilightAmTime: '',
    twilightPmTime: '',
    // Add day data
    monitoring: {},
    matauranga: [],
    observations: [],
  };

  // Put it all together.
  return maramatakaDates
    .map((date: any) => {
      // Deal with which star-month we're going to define
      date.startOfMonth = moment(date.gregorian_start_datetime)
        .tz('Pacific/Auckland')
        .startOf('month')
        .format('YYYY-MM-DD');
      let startOfStarMonth = maramatakaStarsByGregorianMonth[date.startOfMonth];
      // No star months start this month.  This could be due to February.
      if (!startOfStarMonth) {
        // That's cool, what about last month?
        const tryForThisMonth = moment(date.gregorian_start_datetime)
          .tz('Pacific/Auckland')
          .startOf('month')
          .subtract(1, 'months')
          .format('YYYY-MM-DD');
        startOfStarMonth = maramatakaStarsByGregorianMonth[tryForThisMonth];
        // Nope?  Maybe the first month in our date range was a February?  OK use the start of the month.
        startOfStarMonth = date;
      }
      // Some labels and images
      date.date = moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM-DD');
      date.month = moment(date.gregorian_start_datetime).tz('Pacific/Auckland').format('YYYY-MM');
      date.dateLabel = moment(startOfStarMonth.gregorian_start_datetime).tz('Pacific/Auckland').format('MMMM Do YYYY');
      (date.monthLabel = maramatakaMonths[date.maramataka_month_id].name_reo_maori),
        (date.starMedia = STAR_ASSET_PATH[maramatakaStarsById[startOfStarMonth.maramataka_star_id].index]);
      date.sliceDates = maramatakaMonthWeeksByMonth[date.startOfMonth]
        .map((week: any, index: number) => ({
          start: week.gregorian_start_datetime,
          end:
            index === maramatakaMonthWeeksByMonth[date.startOfMonth].length - 1
              ? moment(date.gregorian_end_datetime).tz('Pacific/Auckland').endOf('month').format('YYYY-MM-DD HH:mm:ss')
              : moment(maramatakaMonthWeeksByMonth[date.startOfMonth][index + 1].gregorian_start_datetime)
                  .tz('Pacific/Auckland')
                  .subtract(1, 'seconds')
                  .format('YYYY-MM-DD HH:mm:ss'),
          days: [],
        }))
        .map((week: any) => {
          let current = moment(week.start);
          const end = moment(week.end);
          while (current.isBefore(end)) {
            week.days.push(current.format('YYYY-MM-DD'));
            current.add(1, 'days');
          }
          return week;
        });
      date.slices = maramatakaMonthWeeksByMonth[date.startOfMonth];

      return date;
    })
    .map((date: any) => ({
      ...date,
      slices: date.slices.map((slice: any, index: number) => ({
        ...clone(sliceTemplate),
        dateLabel: moment(slice.date).tz('Pacific/Auckland').format('MMMM Do YYYY'),
        startDateTime: moment(date.sliceDates[index].start).tz('Pacific/Auckland').format('YYYY-MM-DD HH:mm:ss'),
        startDate: moment(date.sliceDates[index].start).tz('Pacific/Auckland').format('YYYY-MM-DD HH:mm:ss'),
        endDateTime: moment(date.sliceDates[index].end).tz('Pacific/Auckland').format('YYYY-MM-DD HH:mm:ss'),
        lunarMonth: maramatakaMonths[slice.maramataka_month_id].month_of_year,
        lunarPhaseEnergy: maramatakaDays[slice.maramataka_lunar_month_day_id].energy_text_english,
        lunarPhase: maramatakaDays[slice.maramataka_lunar_month_day_id].day_of_month,
        lunarPhaseEnergyColour:
          ENERGY_LEVEL_COLORS[maramatakaDays[slice.maramataka_lunar_month_day_id].energy_text_english],
        // This image is not related to the phase - we have 30 to use and we have to pick the closest.
        // For full moon and new moon it's exact (see the generation code)
        // For the others it's the closest.
        lunarPhaseMedia: `/assets/moon_phases/moon_${Math.ceil((slice.ecliptic_longitude / 360) * 30) || 1}.svg`,
        lunarStageEng:
          maramatakaDays[slice.maramataka_lunar_month_day_id].name_english === '0'
            ? '-'
            : maramatakaDays[slice.maramataka_lunar_month_day_id].name_english,
        lunarStageReo: maramatakaDays[slice.maramataka_lunar_month_day_id].name_reo_maori,
        seasonDescriptionEng:
          maramatakaSeasons[maramatakaMonths[slice.maramataka_month_id].maramataka_season_id].description_english,
        seasonDescriptionReo:
          maramatakaSeasons[maramatakaMonths[slice.maramataka_month_id].maramataka_season_id].description_reo_maori,
        seasonNameEng: maramatakaSeasons[maramatakaMonths[slice.maramataka_month_id].maramataka_season_id].name_english,
        seasonNameReo:
          maramatakaSeasons[maramatakaMonths[slice.maramataka_month_id].maramataka_season_id].name_reo_maori,
        starDescriptionEng: maramatakaStarsById[slice.maramataka_star_id].description_english,
        starDescriptionReo: maramatakaStarsById[slice.maramataka_star_id].description_reo_maori,
        starNameEng: maramatakaStarsById[slice.maramataka_star_id].name_english,
        starNameReo: maramatakaStarsById[slice.maramataka_star_id].name_reo_maori,
        starMedia: STAR_ASSET_PATH[maramatakaStarsById[slice.maramataka_star_id].index],
        starZenithAzimuth: slice.star_azimuth,
        starZenithTime: moment(slice.star_zenith).tz('Pacific/Auckland').format('HH:mm'),
        observations: (observationsByMonth[date.month] || [])
          .map((observation: any) => ({
            ...observation,
            localDateTime: moment(observation.date).tz('Pacific/Auckland').format('YYYY-MM-DD HH:mm:ss'),
          }))
          .filter(
            (observation: any) =>
              observation.localDateTime >= date.sliceDates[index].start &&
              observation.localDateTime <= date.sliceDates[index].end
          )
          .map((observation: any) => ({
            id: observation.id,
            media: observation.media.length > 0 ? 'multimedia' : 'text',
            type: 'observation',
            originalItem: observation,
          })),
        monitoring: index === 2 ? environmentalDataByMonth[date.month] || {} : {},
        matauranga: date.sliceDates[index].days.reduce(
          (accumulator: any, sliceDay: any) => [
            ...accumulator,
            ...(mataurangaMapByDate[sliceDay] || []).map((mataurangaIndex: number) => ({
              id: matauranga.matauranga[mataurangaIndex].id,
              media: matauranga.matauranga[mataurangaIndex].mediafile_path,
              domain: matauranga.matauranga[mataurangaIndex].domain_type,
              type: 'matauranga',
              originalItem: matauranga.matauranga[mataurangaIndex],
              date: sliceDay,
            })),
          ],
          []
        ),
      })),
    }));
}
