// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: false,
  forceBrand: null,
  title: 'Dev Environment Heading',
  iconBaseURL: '/assets/',
  apiBaseURL: 'https://api.dev.thestream.nz',
  mapbox: {
    accessToken: 'pk.eyJ1IjoibWlrZWxlZW56IiwiYSI6ImNsZGM0azk2ajA3MG4zdnM2Z296YWdnM2cifQ.ZPKNV4CuZLiTy2NGduZ7gw',
  },
  version: env.npm_package_version,
  serverUrl: '',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  weatherAPI: '472e4cd0ae8aa6cd987d0e1bc7aa73a6',
  amplifyAuthConfig: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_W5sUb2k4e',
    identityPoolId: 'ap-southeast-2:61eded49-24e9-410f-ba0f-05f9c0faca74',
    userPoolWebClientId: '3hb5jemto7kob821hvfj2lp3j2',
    oauth: {
      domain: 'auth.dev.thestream.nz',
      scope: ['email', 'profile', 'openid'],
      responseType: 'code',
      options: {
        AdvancedSecurityDataCollectionFlag: false,
      },
    },
  },
};
