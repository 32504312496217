import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/data/services/authService';

import { Logger } from '@shared';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const log = new Logger('AuthenticationGuard');

/**
 * What a mess. Quickly adding onto the mess for now
 */
const authenticatedRoutes: string[] = ['mapview', 'dashboard', 'hau', 'wai', 'moana', 'whenua'];

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.getLoggedInUser().pipe(
      map(({ user, forbidden }) => {
        if (forbidden) {
          if (state.url !== '/no-access') {
            this.router.navigate(['/no-access']);
          }
          return false;
        }
        if (!user) {
          if (state.url !== '/login') {
            this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
          }
          return false;
        }
        return true;
      })
    );
  }
}
