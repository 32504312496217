import { MapLayersApi } from '@app/data/services/types/MapLayersApi';

export class URLConstants {
  // Identity API
  public static getCurrentUser: string = '/user';

  // Shared API
  public static getMaramatakaEventData = '/events';

  // Maramataka API
  public static getMaramataka = '/maramataka/{site_id}';
  public static getMaramatakaDays = '/maramataka/{site_id}/days';
  public static getMaramatakaWeeks = '/maramataka/{site_id}/weeks';
  public static getMaramatakaMonths = '/maramataka/{site_id}/months';
  public static getMaramatakaMonthWeeks = '/maramataka/{site_id}/month-weeks';
  public static getMaramatakaStars = '/maramataka/{site_id}/stars';
  public static getMaramatakaMatauranga = '/maramataka/{site_id}/matauranga';
  public static getMaramatakaSiteAstronomicalMeteorologicalEvents =
    '/maramataka/{site_id}/site_astronomical_meteorological_events';

  // environmental data
  public static getEnvironmentalDataForSiteByDate =
    '/environmental-data/{site_id}/sources/{environmental_data_source_id}/analytes/{analyte_id}';

  // Map API
  public static getSiteMapLayers = (siteId: string) => `/maps/${siteId}/layers`;
  public static getSiteLayerFile = (siteId: string, layerId: string) => `/maps/${siteId}/layers/${layerId}`;
  public static getEnvironmentalDataSources = (siteId: string) => `/environmental-data/${siteId}/sources`;

  // Observation API
  public static getSiteObservations = '/rongo/observations/{site_id}';

  // Monitoring API
  public static getMarmatakaEvents = '/monitoring/maramataka-events';
  public static getSurfaceWaterData = '/surfacewater';
  public static getGroundWaterData = '/groundwater';
  public static getWaiStationMonitoringData = '/data/wai';
  public static getHistoricalWeather = '/weather/historic';
  public static getForecastedWeather = '/weather/forecast';
  public static getHistoricalAndForecastedData = '/weather';
  public static getMarmatakaMoonPhases = '/phases';
  public static getAlertSearch = '/alerts/search';

  // Assets
  public static getLineChartData = '/phvalues';
}
