import { Component } from '@angular/core';
import { WidgetComponentBase } from '../widget.class';

@Component({
  selector: 'app-widget-maramataka-phase',
  templateUrl: './widget-maramataka-phase.component.html',
  styleUrls: ['./widget-maramataka-phase.component.scss'],
})
export class WidgetMaramatakaPhaseComponent extends WidgetComponentBase {
  timeScale = 1; //Days -1, Weeks - 2
}
