<app-widget-card-wrapper title="Taiao Scorecard">
  <div class="card-stretch">
    <mat-dialog-content class="mat-typography">
      <div class="mb-2" *ngFor="let category of categories">
        <h2>{{ category.name }}</h2>
        <div *ngFor="let chart of category.charts">
          <h4 style="margin: 0px">{{ chart.name }}</h4>
          <div echarts style="height: 40px" [options]="chart.options" class="demo-chart"></div>
        </div>
      </div>
    </mat-dialog-content>
  </div>
</app-widget-card-wrapper>
