import { Component } from '@angular/core';
import {
  IslandBlockRoadTotalNutrients,
  IslandBlockRoadWaterChemistry,
  ControlStructureRainfall,
  WaterLevels,
  IslandBlockRoadDOM,
} from './charts.config';
@Component({
  selector: 'app-client-taiao-dashboard-layout',
  templateUrl: './taiao-dashboard-layout.component.html',
  styleUrls: ['./taiao-dashboard-layout.component.scss'],
})
export class waikatotainui_TaiaoDashboardLayoutComponent {
  IslandBlockRoadTotalNutrients = IslandBlockRoadTotalNutrients;
  IslandBlockRoadWaterChemistry = IslandBlockRoadWaterChemistry;
  ControlStructureRainfall = ControlStructureRainfall;
  WaterLevels = WaterLevels;
  IslandBlockRoadDOM = IslandBlockRoadDOM;
}
