import { Component } from '@angular/core';
import { WidgetComponentBase } from '../widget.class';
import { WeatherChart } from '../charts/weatherChart';
import { FilterService } from '@app/data/services/filterService';
import { ChartService } from '@app/data/services/chartService';
import { ClientService } from '@app/client/client.service';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-widget-historicat-weather',
  templateUrl: './widget-historicat-weather.component.html',
  styleUrls: ['./widget-historicat-weather.component.scss'],
})
export class WidgetHistoricatWeatherComponent extends WidgetComponentBase {
  weatherHistoricOption: EChartsOption = {};

  constructor(
    private weatherChart: WeatherChart,
    protected filterService: FilterService,
    protected chartService: ChartService,
    protected clientService: ClientService
  ) {
    super(filterService, chartService, clientService);
  }

  onFilterUpdate(): void {
    this.chartService.getHistoricalWeatherData(this.fromDate, this.toDate, 'Kate Valley Landfill').subscribe((data) => {
      this.weatherHistoricOption = this.weatherChart.getHistoricalWeatherChart(data);
    });
  }
}
