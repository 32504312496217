import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@shared';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/data/services/authService';
import { ClientService } from '@app/client/client.service';
import { ClientConstantType } from '@app/client/client.constant';
import { ClientSetup } from '@app/client/classes/client-setup.interface';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  loginForm!: FormGroup;
  cognitoUser: any | null;
  setNewPasswordForm!: FormGroup;
  startForgotPasswordForm!: FormGroup;
  completeForgotPasswordForm!: FormGroup;
  mustChangePassword = false;
  wantsToResetPassword = false;
  resettingPassword = false;
  passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;
  arrRoles: any = [];
  client: ClientConstantType = this.clientService.getClient();
  clientSetup: ClientSetup = this.clientService.getClientSetup();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private authService: AuthService,
    private clientService: ClientService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.getRoles();
  }

  getRoles = () => {
    this.arrRoles = [
      { id: 101, name: 'superadmin' },
      { id: 102, name: 'clientadmin' },
      { id: 103, name: 'staff' },
    ];
  };

  login() {
    // username password login.
    this.authService
      .login({
        email: this.loginForm.value.username,
        password: this.loginForm.value.password,
      })
      .subscribe(
        (result) => {
          this.cognitoUser = result;
          if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // this is where the user status is FORCE_CHANGE_PASSWORD
            this.mustChangePassword = true;
          } else {
            if (this.route.snapshot.queryParams.redirect) {
              this.router.navigate([this.route.snapshot.queryParams.redirect]);
            } else {
              this.router.navigate([this.clientSetup.splashRoute]);
            }
          }
        },
        (error) => this.toaster.error('Invalid username or password.', 'Error!')
      );
  }

  setNewPassword() {
    if (this.setNewPasswordForm.value.newPassword !== this.setNewPasswordForm.value.repeatNewPassword) {
      this.toaster.error('Passwords must match.', 'Error!');
      return;
    }
    if (!this.passwordStrength.test(this.setNewPasswordForm.value.newPassword)) {
      this.toaster.error('Password is not strong enough.', 'Error!');
      return;
    }
    if (this.resettingPassword && !this.setNewPasswordForm.value.code) {
      this.toaster.error('Please check your email for the reset password code.', 'Error!');
      return;
    }
    if (this.resettingPassword) {
      this.authService
        .resetPassword(
          this.startForgotPasswordForm.value.email,
          this.setNewPasswordForm.value.code,
          this.setNewPasswordForm.value.newPassword
        )
        .subscribe(
          () => {
            // it worked!
            this.resettingPassword = false;
            this.wantsToResetPassword = false;
            this.mustChangePassword = false;
          },
          (error) => this.toaster.error('Error setting new password, please try again.', 'Error!')
        );
    } else {
      this.authService.newPassword(this.cognitoUser, this.setNewPasswordForm.value.newPassword).subscribe(
        (result) => {
          this.cognitoUser = result;
          this.mustChangePassword = false;
          // GET /me
          if (this.route.snapshot.queryParams.redirect) {
            this.router.navigate([this.route.snapshot.queryParams.redirect]);
          } else {
            this.router.navigate([this.clientSetup.splashRoute]);
          }
        },
        (error) => this.toaster.error('Error setting new password, please try again.', 'Error!')
      );
    }
  }

  startForgotPassword() {
    if (!this.startForgotPasswordForm.value.email) {
      this.toaster.error('Please enter your email address to reset your password.', 'Error!');
      return;
    }
    this.authService.getResetPasswordCode(this.startForgotPasswordForm.value.email).subscribe(
      () => {
        // if we get here, it worked.
        this.resettingPassword = true;
        this.wantsToResetPassword = false;
        this.cognitoUser = {
          email: this.startForgotPasswordForm.value.email,
        };
      },
      (error) => this.toaster.error('Error resetting password, please try again.', 'Error!')
    );
  }

  /**
   *
   * Log in with a microsoft account etc.
   * Current options:
   * Microsoft
   * google
   *
   */
  federatedLogin(customProvider: string) {
    this.authService.federatedLogin(customProvider).subscribe();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.setNewPasswordForm = this.formBuilder.group({
      code: ['', Validators.pattern(/^[0-9]{6}$/)],
      newPassword: ['', Validators.pattern(this.passwordStrength)],
      repeatNewPassword: ['', Validators.pattern(this.passwordStrength)],
    });
    this.startForgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }
}
