import { Directive } from '@angular/core';
import { UnsubscribeManager } from '@app/data/class/unsubscribe-manager.class';
import { FilterService, FilterSetupDomain, FilterSetupSubscribe } from '@app/data/services/filterService';
import { ClientService } from '@app/client/client.service';
import { ClientConstantType } from '@app/client/client.constant';

@Directive()
export abstract class TaiaoDashboardLayoutComponentBase extends UnsubscribeManager {
  client: ClientConstantType;
  domain: FilterSetupDomain = 'overview';

  constructor(private filterService: FilterService, protected clientService: ClientService) {
    super();

    this.client = this.clientService.getClient();

    this.subscribe(this.filterService, this.handleFilterUpdate.bind(this), ['domain']);

    const filters = this.filterService.getFilters();

    this.handleFilterUpdate({ filters, previousFilters: filters });
  }

  private handleFilterUpdate({ filters }: FilterSetupSubscribe) {
    if (this.domain === filters.domain) {
      return;
    }

    this.domain = filters.domain;
  }
}
