import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonConstants } from '@app/core/constants/commonConstants';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private http: HttpClient) {}

  public getMenuItems(role: string): Observable<any> {
    let menuURL =
      role === CommonConstants.LANDFILL_MANAGER
        ? CommonConstants.MENU_LANDFILL_MANAGER
        : role === CommonConstants.IWI_MANAGER
        ? CommonConstants.MENU_IWI_MANAGER
        : CommonConstants.MENU_PUBLIC_MANAGER;
    return this.http.get('assets/menu/' + menuURL);
  }
}
