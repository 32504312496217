import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ChartService } from '@app/data/services/chartService';
import { connect, getInstanceByDom } from 'echarts';
import LinearGradient from 'zrender/lib/graphic/LinearGradient';
declare const require: any; // DEMO IGNORE

@Component({
  selector: 'app-line-charts',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit {
  data: any = [];
  date: any = [];
  options: any;
  constructor(private chartService: ChartService) {}
  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.setOptions();
      const chartElement1 = document.getElementById('chart1');
      if (chartElement1 != null) {
        const chart1 = getInstanceByDom(chartElement1);
        if (chart1) {
          connect([chart1]);
        }
      }
    });
  }

  setOptions = () => {
    this.chartService.getLineChartData().subscribe((result) => {
      this.date = result?.dateRange;
      this.data = result?.dataRange;

      this.options = {
        tooltip: {
          trigger: 'axis',
          position: function (pt: any) {
            return [pt[0], '10%'];
          },
          textStyle: {
            color: '#fff',
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.date,
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
        },
        series: [
          {
            name: 'Stream pH levels',
            type: 'line',
            smooth: false,
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: '#00AAFF',
            },
            data: this.data,
          },
        ],
      };
    });
  };
}
