import { Component } from '@angular/core';
import { ClientResolverBase } from '../classes/client-resolver-base.class';

@Component({
  selector: 'app-resolver-client-taiao-dashboard-layout',
  template: `
    <div>
      <ng-template #layoutView></ng-template>
    </div>
  `,
})
export class ClientResolverTaiaoDashboardLayoutComponent extends ClientResolverBase {
  protected getLayoutType(): 'taiao-dashboard' {
    return 'taiao-dashboard';
  }
}
