import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { APIWithLoaderHelper } from '../helpers/apiWithLoaderHelper';
import { URLConstants } from '@app/core/constants/URLConstants';
import { ClientService } from '@app/client/client.service';
import { SitesService } from './sitesService';

type UserRole = 'public' | 'administrator';

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: UserRole;
  isAdmin: boolean;
}

type Sites = {
  id: string;
  name: string;
  latitude?: number;
  longitude?: number;
  siteArea?: number;
}[];

@Injectable({
  providedIn: 'root',
})
/**
 * Call cognito.
 */
export class AuthService {
  private user: User | null = null;
  originIdentifier: string;

  constructor(
    private apiWithLoaderHelper: APIWithLoaderHelper,
    private clientService: ClientService,
    private sitesService: SitesService
  ) {
    this.originIdentifier = this.clientService.getClientOriginIdentifier();
  }

  login(user: any): Observable<any> {
    return from(Auth.signIn(user.email, user.password));
  }

  newPassword(user: any, newPassword: string): Observable<any> {
    return from(Auth.completeNewPassword(user, newPassword));
  }

  getResetPasswordCode(email: string): Observable<any> {
    return from(Auth.forgotPassword(email));
  }

  resetPassword(email: string, code: string, newPassword: string): Observable<any> {
    return from(Auth.forgotPasswordSubmit(email, code, newPassword));
  }

  getAuthenticatedData(): Observable<any> {
    return from(Auth.currentAuthenticatedUser().catch((e) => null));
  }

  getLoggedInUser(): Observable<{ user: User | null; sites: Sites | null; forbidden: boolean | null }> {
    return this.getAuthenticatedData().pipe(
      mergeMap((authData) => {
        if (!authData) {
          return of({ user: null, sites: null, forbidden: false });
        }

        return this.apiWithLoaderHelper
          .get<{ user: User; sites: Sites }>(
            URLConstants.getCurrentUser,
            { queryStringParameters: { origin_identifier: this.originIdentifier } },
            false
          )
          .pipe(
            map((x) => {
              this.sitesService.checkAvailableSites(x.sites);
              return { user: x.user, sites: x.sites, forbidden: false };
            }),
            catchError((): Observable<{ user: User | null; sites: Sites | null; forbidden: boolean | null }> => {
              return of({ user: null, sites: null, forbidden: true });
            })
          );
      })
    );
  }

  federatedLogin(customProvider: string) {
    return from(Auth.federatedSignIn({ customProvider }));
  }

  logout(): Observable<any> {
    this.user = null;
    this.sitesService.clearSelectedSite();
    return from(Auth.signOut());
  }
}
