import { Component } from '@angular/core';
import { ClientResolverBase } from '../classes/client-resolver-base.class';

@Component({
  selector: 'app-resolver-client-main-layout',
  template: `
    <div>
      <ng-template #layoutView></ng-template>
    </div>
  `,
})
export class ClientResolverMainLayoutComponent extends ClientResolverBase {
  protected getLayoutType(): 'main' {
    return 'main';
  }
}
