<div class="dashboardPage">
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-widget-forcasted-weather></app-widget-forcasted-weather>
    </div>
    <div class="col" style="width: 100%">
      <app-widget-maramataka-phase></app-widget-maramataka-phase>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.WaterLevels"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.HarihariWaterChemistry"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.NumitiWaterChemistry"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.RotoroaWaterChemistry"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.HarihariNutrients"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.NumitiNutrients"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.RotoroaNutrients"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-scatter-chart [config]="ChartConfig.HarihariTempChlorophyll"></app-basic-scatter-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-scatter-chart [config]="ChartConfig.NumitiTempChlorophyll"></app-basic-scatter-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-scatter-chart [config]="ChartConfig.RotoroaTempChlorophyll"></app-basic-scatter-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.HarihariWaterClarity"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.NumitiWaterClarity"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.RotoroaWaterClarity"></app-basic-cartesian-chart>
    </div>
  </div>
  <div class="row" style="width: 100%">
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.HarihariContaminants"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.NumitiContaminants"></app-basic-cartesian-chart>
    </div>
    <div class="col" style="width: 100%">
      <app-basic-cartesian-chart [config]="ChartConfig.RotoroaContaminants"></app-basic-cartesian-chart>
    </div>
  </div>
</div>
