import { Component } from '@angular/core';
import { LegacyWidgetComponentBase } from '../widget.class';
import { EChartsOption } from 'echarts';
import { WatertakeChart } from '../../widgets/charts/waterTakeChart';
import { FilterService } from '@app/data/services/filterService';
import { LegacyDataService } from '@app/data/services/legacyDataService';
import { ClientService } from '@app/client/client.service';
import { ClientConstantType } from '@app/client/client.constant';

@Component({
  selector: 'legacy-app-widget-water-take',
  templateUrl: './widget-water-take.component.html',
  styleUrls: ['./widget-water-take.component.scss'],
})
export class WidgetWaterTakeComponent extends LegacyWidgetComponentBase {
  watertakeOption: EChartsOption = {};
  watertakeAvgOption: EChartsOption = {};
  chartTitle: string =
    this.clientService.getClient() === ClientConstantType.wastemanagement
      ? 'Current and Average Efficiency of Water Use'
      : 'Water Use';

  constructor(
    private waterTakeChart: WatertakeChart,
    protected filterService: FilterService,
    protected legacyDataService: LegacyDataService,
    protected clientService: ClientService
  ) {
    super(filterService, legacyDataService, clientService);
  }

  onFilterUpdate(): void {
    this.legacyDataService.getWatertakeData(this.fromDate, this.toDate).subscribe((result) => {
      this.watertakeOption = this.waterTakeChart.getWatertake(
        result,
        false,
        this.client === ClientConstantType.wastemanagement ? '#28adde' : '#6894cd',
        this.client === ClientConstantType.wastemanagement ? '#c1e7f7' : '#a8ceee'
      );
      this.watertakeAvgOption = this.waterTakeChart.getWatertake(
        result,
        true,
        this.client === ClientConstantType.wastemanagement ? '#2877de' : '#007093',
        this.client === ClientConstantType.wastemanagement ? '#a1bcf7' : '#5fc9de'
      );
    });
  }
}
