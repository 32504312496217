<div class="side-panel">
  <div class="filter">
    <div class="site-name-wrapper">
      <h2>
        <span class="siteTitle">{{ (site || { name: 'Site not set' }).name }}</span>
      </h2>
      <span class="arrowDown"><app-site-selector colorMode="light" screenPosition="top"></app-site-selector></span>
    </div>

    <form [formGroup]="filterForm">
      <section>
        <h4>
          <span class="icon"><img src="/assets/eye-icon.png" alt="" /></span> View Mode
        </h4>
        <mat-radio-group formControlName="type" (change)="handleTypeChange()">
          <mat-radio-button *ngFor="let option of typeOptions" [value]="option.key">
            {{ option.value }}
          </mat-radio-button>
        </mat-radio-group>
      </section>

      <!-- <section *ngIf="canDisplaySection('domain')">
        <h4>
          <span class="icon"><img src="/assets/domain-icon.png" alt="" /></span> Domain
        </h4>
        <mat-radio-group formControlName="domain">
          <mat-radio-button
            *ngFor="let option of domainOptions"
            [value]="option.key"
            [disabled]="option.key !== 'overview'"
          >
            {{ option.value }}
          </mat-radio-button>
        </mat-radio-group>
      </section> -->

      <section *ngIf="canDisplaySection('timescale')">
        <h4>
          <span class="icon"><img src="/assets/time-icon.png" alt="" /></span> Timescale
        </h4>
        <mat-form-field>
          <mat-label></mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate placeholder="From Date" required formControlName="fromDate" />
            <input matEndDate placeholder="To Date" required formControlName="toDate" />
          </mat-date-range-input>

          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>

          <mat-error *ngIf="filterForm.controls.fromDate.errors?.required">From Date Required</mat-error>
          <mat-error *ngIf="filterForm.controls.fromDate.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="filterForm.controls.toDate.errors?.required">To Date Required</mat-error>
          <mat-error *ngIf="filterForm.controls.toDate.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>
      </section>

      <section *ngIf="canDisplaySection('mapFeatures')">
        <h4>
          <span class="icon"><img src="/assets/terrain-icon.png" alt="" /></span> Map Features
        </h4>
        <div *ngFor="let option of mapFeaturesOptions" formArrayName="mapFeatures">
          <mat-checkbox [formControlName]="option.key" [value]="option.key">{{ option.value }}</mat-checkbox>
        </div>
      </section>

      <section *ngIf="canDisplaySection('matauranga')">
        <h4>
          <span class="icon"><img src="/assets/terrain-icon.png" alt="" /></span> MĀTAURANGA
        </h4>
        <div *ngFor="let option of mataurangaOptions" formArrayName="matauranga">
          <mat-checkbox [formControlName]="option.key">{{ option.value }}</mat-checkbox>
        </div>
      </section>

      <section *ngIf="canDisplaySection('monitoring')">
        <h4>
          <span class="icon"><img src="/assets/data-layers-icon.png" alt="" /></span> Monitoring
        </h4>
        <div *ngFor="let option of monitoringOptions" formArrayName="monitoring">
          <mat-checkbox [formControlName]="option.key">{{ option.value }}</mat-checkbox>
        </div>
      </section>

      <section *ngIf="canDisplaySection('observations')">
        <h4>
          <span class="icon"><img src="/assets/domain-icon.png" alt="" /></span> Observations
        </h4>
        <div *ngFor="let option of observationOptions" formArrayName="observation">
          <mat-checkbox [formControlName]="option.key">{{ option.value }}</mat-checkbox>
        </div>
      </section>

      <section *ngIf="canDisplaySection('calendarView')">
        <h4>
          <span class="icon"><img src="/assets/time-icon.png" alt="" /></span> Calendar View
        </h4>
        <mat-select formControlName="calendarType">
          <mat-option *ngFor="let option of calendarTypeOptions" [value]="option.key">{{ option.value }}</mat-option>
        </mat-select>
        <br /><br />
        <div class="row">
          <div class="col-md-6">Year</div>
          <div class="col-md-6">
            <mat-select formControlName="calendarTypeYear">
              <mat-option *ngFor="let year of calendarTypeYearOptions" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Month</div>
          <div class="col-md-6">
            <mat-select formControlName="calendarTypeMonth">
              <mat-option *ngFor="let month of calendarTypeMonthOptions; let monthIndex = index" [value]="monthIndex">{{
                month
              }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div *ngIf="getCalendarType() === 'week'" class="row">
          <div class="col-md-6">Week</div>
          <div class="col-md-6">
            <mat-select formControlName="calendarTypeWeek">
              <mat-option *ngFor="let week of calendarTypeWeekOptions" [value]="week">{{ week }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div *ngIf="getCalendarType() === 'day'" class="row">
          <div class="col-md-6">Day</div>
          <div class="col-md-6">
            <mat-select formControlName="calendarTypeDay">
              <mat-option *ngFor="let day of calendarTypeDayOptions" [value]="day">{{ day }}</mat-option>
            </mat-select>
          </div>
        </div>
      </section>
    </form>
  </div>

  <mat-drawer-container>
    <mat-drawer #drawerView mode="over" position="start" class="drawer">
      <div *ngIf="!selectedEvent && events.length > 0">
        <table class="table">
          <thead>
            <tr>
              <th>Location or Type</th>
              <th>Domain</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let event of events" (click)="setSelectedEvent(event)">
              <td>{{ event.title }}</td>
              <td>{{ event.domain }}</td>
              <td>{{ event.date | date : event.date_format || 'dd/MM/yyyy' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="!selectedEvent && events.length === 0">No data for selected date(s)</div>

      <div *ngIf="selectedEvent">
        <div class="trayContent matauranga" *ngIf="selectedEvent.source === 'matauranga'">
          <h4 class="m-0">
            {{ selectedEvent.source_data.title_reo_maori || selectedEvent.source_data.title_english }} Mātauranga
            <span class="icon"><i class="fas fa-ellipsis-h"></i></span>
          </h4>
          <div class="row">
            <div class="col-sm-12">
              <small><strong>Date</strong></small>
              <p>{{ selectedEvent.source_data.date | date : 'dd/MM/yyyy' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small
                ><strong
                  >{{ selectedEvent.source_data.title_reo_maori }}/{{ selectedEvent.source_data.title_english }}</strong
                ></small
              >
              <p
                *ngIf="selectedEvent.source_data.description_reo_maori || selectedEvent.source_data.description_english"
              >
                {{ selectedEvent.source_data.description_reo_maori || selectedEvent.source_data.description_english }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small
                ><strong
                  >{{ selectedEvent.source_data.event_type_reo_maori }}/{{
                    selectedEvent.source_data.event_type_english
                  }}</strong
                ></small
              >
              <p *ngIf="selectedEvent.source_data.about_reo_maori || selectedEvent.source_data.about_english">
                {{ selectedEvent.source_data.about_reo_maori || selectedEvent.source_data.about_english }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small><strong>Domain</strong></small>
              <p>{{ selectedEvent.domain }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12"></div>
          </div>
        </div>

        <div class="trayContent" *ngIf="selectedEvent.source === 'environmental_data_source'">
          <h4 class="m-0">
            Environmental Data
            <span class="icon"><i class="fas fa-ellipsis-h"></i></span>
          </h4>
          <div class="row">
            <div class="col-sm-6">
              <small><strong>Sample location</strong></small>
              <p>{{ selectedEvent.source_data.environmentalDataSource.human_readable_name }}</p>
            </div>
            <div class="col-sm-6">
              <small><strong>Sample date</strong></small>
              <p>
                {{ selectedEvent.source_data.date_time | date : selectedEvent.date_format || 'dd/MM/yyyy hh:mm:ssa' }}
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <p>
                <small><strong>Location</strong></small
                ><br />
                {{
                  getLatLngDisplayString(
                    selectedEvent.source_data.environmentalDataSource.latitude,
                    selectedEvent.source_data.environmentalDataSource.longitude
                  )
                }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <small><strong>Field measurements on sample date</strong></small>
              </p>
              <table class="table">
                <thead>
                  <tr>
                    <th>Analyte</th>
                    <th>
                      Measurement
                      <small
                        *ngIf="drawerGranularity !== 'na' && drawerGranularity !== '' && drawerGranularity !== null"
                        >({{ drawerGranularity | titlecase }}ly average)</small
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let dataPoint of selectedEvent.source_data.data">
                    <td>{{ dataPoint.analyte.long_name }}</td>
                    <td>
                      {{ dataPoint.avg.toFixed(dataPoint.analyte.significant_figures) }} {{ dataPoint.analyte.unit }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="sensorGraph mt-2">
              <small
                ><strong>
                  {{ selectedEvent.title }}
                  <span class="icon"><i class="fa fa-arrow-down"></i></span> </strong
              ></small>
              <app-drawer-graphs
                [environmentalDataSource]="selectedEvent.source_data.environmentalDataSource.id"
                [datapoints]="selectedEvent.source_data.data"
                [date]="selectedEvent.source_data.date_time"
                graphType="scatter"
              ></app-drawer-graphs>
            </div>
          </div>
        </div>

        <div class="trayContent" *ngIf="selectedEvent.source === 'observation'">
          <h4 class="m-0">
            {{ selectedEvent.source_data.location }}
            <span class="icon"><i class="fas fa-ellipsis-h"></i></span>
          </h4>
          <p>
            <small> {{ selectedEvent.source_data.zone || '' }}</small>
          </p>

          <div class="row">
            <div class="col-sm-12">
              <app-widget-status-badge
                *ngIf="selectedEvent.source_data.status"
                [status]="selectedEvent.source_data.status.name"
              ></app-widget-status-badge>
            </div>
          </div>

          <div class="row" style="--bs-gutter-x: 0">
            <div class="col-sm-6">
              <small><strong>Date Observed</strong></small>
              <p>{{ selectedEvent.source_data.date | date : 'dd/MM/yyyy' }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <p>
                <small><strong>Location</strong></small
                ><br />
                {{
                  getLatLngDisplayString(
                    selectedEvent.source_data.latitude || selectedEvent.source_data.lat,
                    selectedEvent.source_data.longitude || selectedEvent.source_data.lng
                  )
                }}
              </p>
            </div>
          </div>

          <div *ngFor="let obsItem of selectedEvent.source_data.items">
            <div class="row">
              <div class="col-sm-12">
                <small
                  ><strong>{{ obsItem.title }}</strong></small
                >
                <p>
                  {{ obsItem.value }}
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <small><strong>Media</strong></small>
              <div *ngFor="let mediaItem of selectedEvent.source_data.media">
                <img
                  *ngIf="mediaItem.type.split('/')[0] === 'image'"
                  [src]="mediaItem.url"
                  class="img-fluid"
                  style="margin-top: 5px"
                />

                <video
                  *ngIf="mediaItem.type.split('/')[0] === 'video'"
                  class="img-fluid"
                  style="margin-top: 5px"
                  controls
                >
                  <source [src]="mediaItem.url" [type]="mediaItem.type" />
                </video>

                <audio *ngIf="mediaItem.type.split('/')[0] === 'audio'" style="margin-top: 5px" controls>
                  <source [src]="mediaItem.url" [type]="mediaItem.type" />
                </audio>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <small><strong>Contact Type</strong></small>
              <p>
                {{ selectedEvent.source_data.observation_type }}
              </p>
            </div>
          </div>

          <div class="row history-container" *ngIf="selectedEvent.source_data.history.length">
            <mat-accordion>
              <mat-expansion-panel
                class="mat-elevation-z0"
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title>History</mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let historyEntry of selectedEvent.source_data.history">
                  <hr />
                  <div class="main-history">
                    <div class="status-change">
                      <app-widget-status-badge [status]="historyEntry.oldStatus.name"></app-widget-status-badge>
                      <mat-icon class="direction-arrow">arrow_downward</mat-icon>
                      <app-widget-status-badge [status]="historyEntry.newStatus.name"></app-widget-status-badge>
                    </div>
                    <div class="comment">
                      <span>{{ historyEntry.comment || 'No Comment' }}</span>
                    </div>
                  </div>
                  <div class="history-author">
                    <small
                      ><i>Updated {{ formatDate(historyEntry.createdAt) }} by {{ historyEntry.user.email }}</i></small
                    >
                  </div>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="trayContent scientific" *ngIf="selectedEvent.source === 'maramataka'">
          <h4 class="m-0">
            Maramataka
            <span class="icon"><i class="fas fa-ellipsis-h"></i></span>
          </h4>

          <div class="row">
            <div class="col-sm-12">
              <small><strong>Energy</strong></small>
              <p>{{ selectedEvent.source_data.lunarPhaseEnergy }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small><strong>Lunar Month</strong></small>
              <p>{{ selectedEvent.source_data.lunarMonth }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small><strong>Lunar Stage</strong></small>
              <p>{{ selectedEvent.source_data.lunarStageEng }} / {{ selectedEvent.source_data.lunarStageReo }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <small
                ><strong
                  >{{ selectedEvent.source_data.starNameEng }} / {{ selectedEvent.source_data.starNameReo }}</strong
                ></small
              >
              <p>
                {{ selectedEvent.source_data.starDescriptionEng }} / {{ selectedEvent.source_data.starDescriptionReo }}
              </p>
            </div>
          </div>
          <div class="row" *ngIf="selectedEvent.source_data.mode === 'month'">
            <div class="col-sm-12">
              <small
                ><strong
                  >{{ selectedEvent.source_data.seasonNameEng }} / {{ selectedEvent.source_data.seasonNameReo }}</strong
                ></small
              >
              <p>
                {{ selectedEvent.source_data.seasonDescriptionEng }} /
                {{ selectedEvent.source_data.seasonDescriptionReo }}
              </p>
            </div>
          </div>

          <table class="table" *ngIf="selectedEvent.source_data.mode === 'day'">
            <thead>
              <tr>
                <th>Event</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Sunrise</td>
                <td>{{ selectedEvent.source_data.sunriseTime }}</td>
              </tr>
              <tr>
                <td>Sunset</td>
                <td>{{ selectedEvent.source_data.sunsetTime }}</td>
              </tr>
              <tr>
                <td>Moonrise</td>
                <td>{{ selectedEvent.source_data.moonriseTime }}</td>
              </tr>
              <tr>
                <td>Moonset</td>
                <td>{{ selectedEvent.source_data.moonsetTime }}</td>
              </tr>
              <tr>
                <td>Twilight AM</td>
                <td>{{ selectedEvent.source_data.twilightAmTime }}</td>
              </tr>
              <tr>
                <td>Twilight PM</td>
                <td>{{ selectedEvent.source_data.twilightPmTime }}</td>
              </tr>
              <tr>
                <td>Star Zenith Time</td>
                <td>{{ selectedEvent.source_data.starZenithTime }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="trayPagination" *ngIf="events.length > 1">
          <div class="trayBtn prev">
            <mat-icon
              *ngIf="selectedEventIndex > 0"
              aria-hidden="false"
              aria-label="Previous event"
              (click)="setPreviousEvent()"
              >arrow_back_ios</mat-icon
            >
          </div>

          <div class="trayBtn back">
            <mat-icon aria-hidden="false" aria-label="Back to events list" (click)="unsetSelectedEvent()"
              >file_download</mat-icon
            >
          </div>

          <div class="trayBtn next">
            <mat-icon
              *ngIf="selectedEventIndex < events.length - 1"
              aria-hidden="false"
              aria-label="Next event"
              (click)="setNextEvent()"
              >arrow_forward_ios</mat-icon
            >
          </div>
        </div>

        <div class="dashboardBtn" *ngIf="events.length > 1">
          <button type="button" (click)="toggleDrawer()">Close</button>
        </div>
      </div>
    </mat-drawer>

    <mat-drawer-content [ngClass]="contentClass">
      <router-outlet></router-outlet>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
