import { Subject } from 'rxjs';

type SubscribeManagerCallback<T> = (arg0: T) => void;

export type SubscribeManagerUnsubscribe = () => void;

export abstract class SubscribeManager<T = any, A extends any[] = any[]> {
  private subject: Subject<T> = new Subject<T>();
  private lastChange!: T;
  private hasLastChange: boolean = false;

  /**
   * Subscribe to changes
   *
   * Method will subscribe to changes
   *
   * @access public
   * @param {SubscribeManagerCallback<T>} callback The acllback function for all changes
   * @returns {SubscribeManagerUnsubscribe} Disposer (unsubscribe) function
   */
  public subscribe(callback: SubscribeManagerCallback<T>, ...args: A): SubscribeManagerUnsubscribe {
    const subscription = this.subject.subscribe({
      next: callback,
    });

    if (this.hasLastChange) {
      callback(this.lastChange);
    }

    return subscription.unsubscribe.bind(subscription);
  }

  /**
   * Subscribe to once off change as a promise
   *
   * Method will subscribe to a once off change as a promise, auto unsubscribing on
   * success/error outcome. Set to protected just in case if it isn't desired
   *
   * @access protected
   * @returns {Promise<T>} The promise to resolve
   */
  protected toPromise(): Promise<T> {
    return new Promise((resolve, reject) => {
      if (this.hasLastChange) {
        return resolve(this.lastChange);
      }

      const subscription = this.subject.subscribe({
        next: (observee: T) => {
          resolve(observee);
          subscription.unsubscribe();
        },
        error: (err) => {
          reject(err);
          subscription.unsubscribe();
        },
      });
    });
  }

  /**
   * Push changes
   *
   * Method will push changes to all subscriptions
   *
   * @access protected
   * @param {T} value The value to push to the subscriptions
   * @return {void}
   */
  protected pushChange(value: T): void {
    this.hasLastChange = true;
    this.lastChange = value;
    this.subject.next(value);
  }
}
