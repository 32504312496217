import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-widget-card-wrapper',
  templateUrl: './widget-card-wrapper.component.html',
  styleUrls: ['./widget-card-wrapper.component.scss'],
})
export class WidgetCardWrapperComponent {
  @Input() title: string = '';
}
