import { Injectable } from '@angular/core';
import { ClientConstantType } from './client.constant';
import { ClientSetup } from './classes/client-setup.interface';
import { environment } from '@env/environment';

/**
 * Import all the cient setup files
 */
import { wastemanagement_ClientSetup } from './clients/wastemanagement/client-setup';
import { paepae_ClientSetup } from './clients/paepae/client-setup';
import { waikatotainui_ClientSetup } from './clients/waikatotainui/client-setup';
import { default_ClientSetup } from './clients/default/client-setup';

/**
 * Map all the available clients and their setup
 */
const availableClients: { [key: string]: { setup: ClientSetup; client: ClientConstantType } } = {
  wastemanagement: {
    setup: wastemanagement_ClientSetup,
    client: ClientConstantType.wastemanagement,
  },
  paepae: {
    setup: paepae_ClientSetup,
    client: ClientConstantType.paepae,
  },
  waikatotainui: {
    setup: waikatotainui_ClientSetup,
    client: ClientConstantType.waikatotainui,
  },
  default: {
    setup: default_ClientSetup,
    client: ClientConstantType.default,
  },
};

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private client: ClientConstantType;
  private clientSetup: ClientSetup;
  private originIdentifier: string;

  constructor() {
    let subdomain = environment.forceBrand || window.location.host.split(/\.|:/g)[0];
    const selectedClient = availableClients[subdomain] || availableClients.default;
    this.clientSetup = selectedClient.setup;
    this.originIdentifier = environment.forceBrand || subdomain;
    this.client = selectedClient.client;
  }

  getClient(): ClientConstantType {
    return this.client;
  }

  getClientOriginIdentifier(): string {
    return this.originIdentifier;
  }

  getClientSetup(): ClientSetup {
    return this.clientSetup;
  }
}
