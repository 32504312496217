<div class="dashboardPage">
  <div class="row">
    <div class="col-sm-7 flex-col">
      <legacy-app-widget-overview title="Example of Landfill Performance Summary"></legacy-app-widget-overview>
      <app-widget-maramataka-phase *ngIf="domain === 'overview'"></app-widget-maramataka-phase>
      <app-widget-monitoring-information *ngIf="domain === 'overview'"></app-widget-monitoring-information>
    </div>
    <div class="col-sm-5 flex-col">
      <legacy-app-widget-taiao-scorecard *ngIf="domain === 'overview'"></legacy-app-widget-taiao-scorecard>
      <legacy-app-widget-water-take *ngIf="domain === 'wai' || domain === 'overview'"></legacy-app-widget-water-take>
      <legacy-app-widget-oxygen
        *ngIf="domain === 'moana' || domain === 'whenua' || domain === 'hau'"
      ></legacy-app-widget-oxygen>
    </div>
  </div>
  <div class="row">
    <div class="col-sm flex-col">
      <legacy-app-widget-water-monitoring-ph
        *ngIf="domain === 'overview' || domain === 'wai'"
      ></legacy-app-widget-water-monitoring-ph>
      <legacy-app-widget-groundwater-wells
        *ngIf="domain === 'overview' || domain === 'wai'"
      ></legacy-app-widget-groundwater-wells>
      <legacy-app-widget-swa *ngIf="domain === 'overview' || domain === 'wai'"></legacy-app-widget-swa>
      <legacy-app-widget-swc *ngIf="domain === 'overview' || domain === 'wai'"></legacy-app-widget-swc>
      <app-widget-historicat-weather *ngIf="domain === 'overview'"></app-widget-historicat-weather>
      <legacy-app-widget-methane *ngIf="domain === 'overview'"></legacy-app-widget-methane>
    </div>
    <div class="col-sm flex-col">
      <legacy-app-widget-water-monitoring-conductivity
        *ngIf="domain === 'overview' || domain === 'wai'"
      ></legacy-app-widget-water-monitoring-conductivity>
      <legacy-app-widget-groundwater-protection
        *ngIf="domain === 'overview' || domain === 'wai'"
      ></legacy-app-widget-groundwater-protection>
      <legacy-app-widget-swb *ngIf="domain === 'overview' || domain === 'wai'"></legacy-app-widget-swb>
      <legacy-app-widget-swd *ngIf="domain === 'overview' || domain === 'wai'"></legacy-app-widget-swd>
      <app-widget-forcasted-weather *ngIf="domain === 'overview'"></app-widget-forcasted-weather>
      <legacy-app-widget-methane *ngIf="domain === 'overview'" [ngStyle]="{ opacity: 0 }"></legacy-app-widget-methane>
      <legacy-app-widget-indicators *ngIf="domain === 'wai'"></legacy-app-widget-indicators>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 flex-col">
      <app-widget-kokopu *ngIf="domain === 'wai'"></app-widget-kokopu>
    </div>
  </div>
</div>
