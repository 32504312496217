<app-widget-card-wrapper title="Wai - Surface Water" class="wai">
  <h5 class="card-title-light" style="color: #2a5fa5">Kate Creek Sediment Dam (SWA)</h5>
  <!-- <h5 class="card-title-medium" style="color: #446ea6">Kate Creek</h5> -->
  <div>
    <h2><span>All values are Lab Totals. Click each analyte to turn data on and off.</span></h2>
  </div>
  <div class="card-stretch">
    <div echarts [options]="surfaceWaterGrabOption"></div>
  </div>
</app-widget-card-wrapper>
